<template>
  <div id="new_user" class="col-lg-12 mt-4 adacc">
    <div class="col-lg-12">
      <h3>Edit Coupon</h3>
      <form class="" @submit.prevent="updateCoupon">
        <div class="mt-5">
          <div class="row mb-3">
            <div class="col-md-8">
              <!-- <label> Coupon Code *</label> -->
              <input
                type="text"
                v-model="details.coupon.code"
                class="input-field form-control"
                placeholder="Name"
                required
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-8">
              <!-- <label>Discount Value *</label> -->
              <input
                type="text"
                v-model="details.coupon.discount_value"
                class="input-field form-control"
                placeholder="Percentage"
                required
              />
              <!-- <span class="textsp">$</span> -->
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-8">
              <!-- <label>Coupon Limit</label> -->
              <input
                type="text"
                v-model="details.coupon.limit"
                class="input-field form-control"
                placeholder="Usage Allowance"
                required
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-8 col-md-8">
              <!-- <label>Start Time</label> -->
              <datetime
                type="datetime"
                :use12-hour="u12"
                zone="local"
                value-zone="local"
                placeholder="Expire Date/Time"
                input-class="input-field form-control"
                required
                v-model="details.coupon.expire_on"
              ></datetime>
            </div>
          </div>
          <div class="row mb-3 d-none">
            <div class="col-md-8 distype">
              <!-- <label>Discount Type</label> -->
              <v-select
                :options="types"
                :multiple="false"
                v-model="details.coupon.discount_type"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!details.coupon.discount_type"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </div>
          </div>
          <div class="my-4">
            <h4 class="renew d-inline">Active</h4>
            <label class="switch ml-4">
              <input
                type="checkbox"
                v-model="details.coupon.published"
                required
              />
              <span class="slider round"></span>
            </label>
          </div>

          <div class="row mb-3">
            <label class="col-lg-12 mt-4 pri cap mb-3">Applies To</label>
            <label class="col-lg-12 cap mb-3">Subscription Levels</label>
            <div class="col-md-8 mb-3">
              <div class="row">
                <div
                  class="col-lg-4 col-md-4 pr-0"
                  v-for="(ro, index) in plans"
                  :key="role + index"
                >
                  <label class="mt-0 reg">
                    <input
                      type="checkbox"
                      class="check"
                      name="plan"
                      :value="ro.id"
                      v-model="details.coupon.plan_ids"
                    />
                    {{ ro.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <router-link to="/admin/coupons"
            ><button class="form-btn mr-4 cancel">Cancel</button></router-link
          >

          <button id="saveUser" type="submit" class="form-btn">
            Update Coupon
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../../services/adminService";
import axios from "axios";
import router from "@/router";

export default {
  name: "EditCouponPage",
  data() {
    return {
      details: {
        coupon: {
          code: null,
          days_valid: null,
          discount_value: null,
          discount_type: null,
          plan_ids: [],
          limit: 0,
        },
      },
      plans: "",
      id: "",
      types: [],
      u12: true,
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  computed: {},
  mounted() {
    this.getPlans();
    this.getCoupon();
  },
  components: {},
  methods: {
    async getPlans() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.newCoupons();
        if (response.data.success == true) {
          this.plans = response.data.data.options.plans;
          this.types = response.data.data.options.discount_types;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getCoupon() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.editCoupon(this.id);
        if (response.data.success == true) {
          this.details.coupon = response.data.data.coupon;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async updateCoupon() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        var bt = document.getElementById("saveUser");
        const response = await AdminService.updateCoupon(this.id, this.details);
        if (response.data.success == true) {
          ////bt.classList.add("success");
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          // //bt.innerText = response.data.flash.notice
          setTimeout(function () {
            router.push({
              path: "/admin/coupons",
            });
          }, 500);
        } else {
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Update Coupon";
          }, 1500);
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
