<template>
  <div>
    <div class="table-list users-list mt-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0">
            <b>Episodes</b>
            <router-link
              v-if="can('episode:create') || can('admin')"
              to="/admin/sessions/new"
              ><button class="form-btn ml-4">Add New</button></router-link
            >
          </h4>
        </div>
        <div
          v-if="sessions.length > 0"
          class="col-lg-6 col-md-6 text-right rbtn"
        >
          <router-link :to="'/admin/programmes/' + sessions[0].program.id"
            ><button class="form-btn">
              {{ sessions[0].program.name }}
            </button></router-link
          >
        </div>
      </div>

      <table class="table boxtable">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th>Date</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <router-link
            class="link_row"
            :to="
              can('episode:edit') || can('admin')
                ? { name: 'EditSession', params: { id: us.id } }
                : '#'
            "
            v-for="(us, index) in sessions"
            :key="user + index"
          >
            <tr class="table-det spacer">
              <td>
                <span>{{ us.title }}</span>
              </td>

              <td>
                <span>{{ moment(us.start_at).format("LL") }} </span>
              </td>
              <td>
                <span v-if="us.published"
                  >Published
                  <font-awesome-icon
                    v-on:click.prevent="remItem(us.id)"
                    class="float-right"
                    :icon="['fal', 'times']"
                  /> </span
                ><span v-else
                  >Unpublished
                  <font-awesome-icon
                    v-if="can('episode:delete') || can('admin')"
                    v-on:click.prevent="remItem(us.id)"
                    class="float-right"
                    :icon="['fal', 'times']"
                  />
                </span>
              </td>
            </tr>
          </router-link>
        </tbody>
      </table>
      <ul class="pl-0 paginate-links" v-if="pages > 1">
        <li
          v-if="pages > 0"
          @click="getSessions(1)"
          class="left-arrow disabled"
        >
          <a>«</a>
        </li>

        <li
          @click="getSessions(p)"
          :class="p == current ? 'number active pg' : 'number pg'"
          v-for="(p, index) in pages"
          :key="pag + index"
        >
          <span v-if="Math.abs(p - current) < 4 || p == pages || p == 1">
            <a
              :class="{
                current: current === p,
                last: p == pages && Math.abs(p - current) > 4,
                first: p == 1 && Math.abs(p - current) > 4,
              }"
              >{{ p }}</a
            >
          </span>
        </li>
        <li
          v-if="pages > 0"
          @click="getSessions(pages)"
          class="right-arrow disabled"
        >
          <a>»</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";

export default {
  name: "SessionsPage",
  data() {
    return {
      paginate: ["orderedUsers"],
      lim: 10,
      sessions: [],
      current: 1,
      pages: 0,
      // orderedUsers:[]
    };
  },
  created() {},
  computed: {
    // orderedUsers: function () {
    //   return this._.orderBy(this.sessions, 'id')
    // }
  },
  mounted() {
    this.getSessions(this.current);
  },
  components: {},
  methods: {
    async getSessions(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getSessions(page);
        if (response.data.success == true) {
          this.sessions = response.data.data;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    remItem(litem) {
      if (confirm("Do you really want to delete?")) {
        this.remove(litem);
      }
    },
    async remove(it) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.deleteSession(it);
        if (response.data.success == true) {
          this.getSessions();
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
