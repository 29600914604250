var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"table-list users-list mt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6 d-flex"},[_c('h4',{staticClass:"mb-0"},[_c('b',[_vm._v("Roles")]),(_vm.can('role:create') || _vm.can('admin'))?_c('router-link',{attrs:{"to":"/admin/roles/new"}},[_c('button',{staticClass:"form-btn ml-4"},[_vm._v("Add New")])]):_vm._e()],1)])]),_c('div',{staticClass:"table-responsive-sm"},[(_vm.roles.length > 0)?_c('table',{staticClass:"table boxtable"},[_vm._m(0),_c('tbody',_vm._l((_vm.roles),function(ro,index){return _c('router-link',{key:_vm.role + index,staticClass:"link_row",attrs:{"to":_vm.can('role:edit') || _vm.can('admin')
                ? { name: 'EditRole', params: { id: ro.id } }
                : '#'}},[_c('tr',{staticClass:"table-det spacer"},[_c('td',[_c('span',[_vm._v(" "+_vm._s(ro.label)+" ")])]),_c('td',[_vm._l((ro.permissions.slice(0, 4)),function(per,index){return _c('span',{key:per + index,staticClass:"mr-2 plsp"},[_vm._v(_vm._s(per.permissable_model))])}),(ro.permissions.length > 5)?_c('span',{staticClass:"plsp"},[_vm._v(". . . .")]):_vm._e()],2),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"mr-2 plsp"},[(
                      (_vm.can('role:delete') || _vm.can('admin')) &&
                      ro.label != 'Admin'
                    )?_c('font-awesome-icon',{staticClass:"float-right",attrs:{"icon":['fal', 'times']},on:{"click":function($event){$event.preventDefault();return _vm.remItem(ro.id)}}}):_vm._e()],1)])])])}),1)]):_c('h5',{staticClass:"mx-auto text-center p-3"},[_vm._v("No Roles Available")])]),(_vm.pages > 1)?_c('ul',{staticClass:"pl-0 paginate-links"},[(_vm.pages > 0)?_c('li',{staticClass:"left-arrow disabled",on:{"click":function($event){return _vm.getRoles(1)}}},[_c('a',[_vm._v("«")])]):_vm._e(),_vm._l((_vm.pages),function(p,index){return _c('li',{key:_vm.pag + index,class:p == _vm.current ? 'number active pg' : 'number pg',on:{"click":function($event){return _vm.getRoles(p)}}},[(Math.abs(p - _vm.current) < 4 || p == _vm.pages || p == 1)?_c('span',[_c('a',{class:{
              current: _vm.current === p,
              last: p == _vm.pages && Math.abs(p - _vm.current) > 4,
              first: p == 1 && Math.abs(p - _vm.current) > 4,
            }},[_vm._v(_vm._s(p))])]):_vm._e()])}),(_vm.pages > 0)?_c('li',{staticClass:"right-arrow disabled",on:{"click":function($event){return _vm.getRoles(_vm.pages)}}},[_c('a',[_vm._v("»")])]):_vm._e()],2):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Permissions")])])])
}]

export { render, staticRenderFns }