import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import moment from 'moment-timezone'
import axios from 'axios'
import VueSession from 'vue-session'
import MainLayout from '@/views/layouts/main.vue'
import SessionLayout from '@/views/layouts/session.vue'
import VueTheMask from 'vue-the-mask'
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import 'bootstrap'
import VuePaginate from 'vue-paginate'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueDOB from 'vue-dob-picker'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import JsonCSV from 'vue-json-csv'
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import VuePlyr from 'vue-plyr'
import VueToast from 'vue-toast-notification';
import 'vue-plyr/dist/vue-plyr.css'
import VueCarousel from 'vue-carousel';
import $ from 'jquery' //import jQuery 
import Meta from "vue-meta"
import VueCookies from 'vue-cookies'
import 'vue-toast-notification/dist/theme-bootstrap.css';
import Buffer from 'buffer'

Vue.use(VueCookies)
Vue.filter('numFormat', numFormat(numeral));
Vue.component('downloadCsv', JsonCSV)
Vue.component('v-select', vSelect)
Vue.use(VuePaginate)
Vue.config.productionTip = false
library.add(fal)
Vue.use(VueTheMask)
Vue.use(VueCarousel);
Vue.component('mainl-layout', MainLayout);
Vue.component('session-layout', SessionLayout);
var options = {
    persist: true
}
Vue.use(VuePlyr, {
    plyr: {
        enabled: !/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    }
})

Vue.use($)
Vue.use(Meta);
// Vue.use(Amplify);
// Vue.use(AmplifyVue);
// Vue.use(VmPlayer);
// Vue.use(VmAudio);

Vue.use(Buffer)

Vue.use(VueSession, options)
Vue.use(BootstrapVue)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueLodash, { name: 'custom', lodash: lodash })
Vue.component('vue-dob-picker', VueDOB)
Vue.component('datetime', Datetime);

moment.suppressDeprecationWarnings = true;

Vue.use(moment)

export const EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
    $bus: {
        get() {
            return EventBus;
        },
    },
});

Vue.prototype.$http = axios

// axios.defaults.withCredentials = true

Vue.$cookies.config('1d', '/', '', true, 'None')

Vue.prototype.moment = moment

var userPermissions = JSON.parse(localStorage.getItem('permissions'))
EventBus.$on("permission", () => {
    // if (userPermissions != null && userPermissions != undefined) {
    userPermissions = JSON.parse(localStorage.getItem('permissions'))
        // }
})
Vue.mixin({
    methods: {
        can: (key) => userPermissions.includes(key)
    }
})

// EventBus.$on("uperm", () => {
//     const userPerms = JSON.parse(localStorage.getItem('uperm'))
//     Vue.mixin({
//         methods: {
//             can: (key) => userPerms.includes(key)
//         }
//     })
// })

// if ((sessionStorage.getItem('logStat')) == 'true') {
//     const userPermissions = JSON.parse(localStorage.getItem('permissions'))
//         // const userPerms = JSON.parse(localStorage.getItem('uperm'))

//     if (userPermissions != null && userPermissions != undefined) {

//         // Vue.mixin({
//         //     methods: {
//         //         can: (key) => userPermissions.includes(key),
//         //     }
//         // })
//     }
// }
require('./stylesheets/theme/main.scss')

Vue.use(VueToast);

new Vue({
    router,
    store: store,
    render: h => h(App),
    components: { App },
    methods: {}
}).$mount('#app')

Vue.config.silent = true;