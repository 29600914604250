<template>
  <div id="new_user" class="col-lg-12 mt-4 adacc">
    <div class="col-lg-12">
      <h3><b>New Background</b></h3>
      <form class="" @submit.prevent="createBackground">
        <div class="mt-4">
          <div class="row mb-3">
            <div class="col-md-8">
              <!-- <label>Programme Name </label> -->
              <input
                type="text"
                v-model="details.background.name"
                class="input-field form-control"
                placeholder="Name"
                required
              />
            </div>
          </div>

          <div class="row mt-3 mb-3">
            <div class="col-lg-8 col-md-8">
              <div class="uploadarea">
                <!-- <label>Thumbnail</label> -->
                <button type="button" class="form-btn m-0 imgbtn">
                  Choose Image
                </button>
                <input
                  id="primg"
                  type="file"
                  @change="getThumb"
                  ref="thumbupload"
                  accept="images*"
                  required="details.background.thumbnail.includes('base64') ? true : false"
                />
                <img
                  class="ml-5 av"
                  v-if="details.background.thumbnail"
                  :src="details.background.thumbnail"
                />
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-8">
              <!-- <label>Description</label> -->
              <textarea
                rows="4"
                v-model="details.background.description"
                placeholder="Description"
                class="input-field form-control mt-0"
                required
              ></textarea>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-8">
              <h4 class="renew d-inline">Published</h4>
              <label class="switch ml-4">
                <input type="checkbox" v-model="details.background.published" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>

          <router-link to="/admin/backgrounds"
            ><button class="form-btn mr-4 cancel">Cancel</button></router-link
          >

          <button id="saveUser" type="submit" class="form-btn">
            Add Background
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";
import router from "@/router";

export default {
  name: "NewBackgroundPage",
  data() {
    return {
      details: {
        background: {
          name: "",
          description: "",
          published: true,
          thumbnail: "",
        },
      },
    };
  },
  created() {},
  computed: {},
  mounted() {},
  components: {},
  methods: {
    async createBackground() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        var bt = document.getElementById("saveUser");
        const response = await AdminService.createBackgrounds(this.details);
        if (response.data.success == true) {
          ////bt.classList.add("success");
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          // //bt.innerText = response.data.flash.notice
          setTimeout(function () {
            router.push({
              path: "/admin/backgrounds",
            });
          }, 500);
        } else {
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Save Background";
          }, 1500);
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    getThumb(event) {
      const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 4) {
        alert("File size exceeds 4 MB");
        this.$refs.thumbupload.value = null;
      } else {
        const fr = new FileReader();
        fr.onloadend = function () {
          this.details.background.thumbnail = fr.result;
        }.bind(this);

        fr.readAsDataURL(event.target.files[0]);
      }
    },
  },
};
</script>
