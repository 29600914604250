<template>
  <div
    v-cloak
    id="main-layout"
    :class="
      $route.name == 'My Account' ||
      $route.name == 'Account' ||
      $route.name == 'Plans' ||
      $route.name == 'PlanShow'
        ? ''
        : 'wf-back'
    "
    :style="
      $route.name == 'My Account' ||
      $route.name == 'Account' ||
      $route.name == 'Plans' ||
      $route.name == 'PlanShow'
        ? ''
        : backgroundState
    "
  >
    <div
      v-if="salert"
      class="alert wal alert-dismissible fade show"
      :class="alert.type"
      role="alert"
      :style="alert.type ? 'display:block;position:fixed ' : 'display:none'"
    >
      <div id="alert">
        <p class="alert-heading m-0">
          <strong>{{ alert.head }}</strong>
          <span class="alert-text ml-2">{{ alert.text }}</span>
        </p>
      </div>
    </div>
    <div class="sess-det">
      <div v-if="flash" class="alert" :class="flash_type" role="alert">
        {{ flash_msg }}
      </div>

      <nav class="navbar navbar-expand-lg main-nav wh mb-0">
        <div class="navIcon">
          <router-link
            class="navbar-brand ml-0"
            to="/member/dashboard"
            @click.native="(page = ''), iconState(true)"
            ><img class="dash-logo" src="../../assets/irie-logo@2x.png"
          /></router-link>
        </div>
        <div class="navBtn">
          <button
            v-if="myProfile"
            class="navbar-toggler"
            type="button"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            data-toggle="collapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon">
              <i class="fas fa-bars fa-lg" id="navbarWhite"></i>
            </span>
          </button>
          <button
            v-else
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon">
              <i class="fas fa-bars fa-lg" id="navbarBlk"></i>
            </span>
          </button>
        </div>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="nav navbar-nav">
            <router-link
              class="routeLink"
              data-toggle=""
              data-target=""
              @click.native="iconState(true)"
              to="/member/dashboard"
              >Dashboard</router-link
            >
            <router-link
              class="routeLink"
              data-toggle=""
              data-target=""
              @click.native="iconState(true)"
              to="/member/programmes"
              >Browse</router-link
            >
            <router-link
              class="routeLink"
              data-toggle=""
              data-target=""
              @click.native="iconState(true)"
              to="/member/schedule"
              >Schedule</router-link
            >
            <router-link
              class="routeLink"
              data-toggle=""
              data-target=""
              @click.native="iconState(true)"
              to="/member/favourites"
              >Favourites</router-link
            >
            <router-link
              class="routeLink"
              data-toggle=""
              data-target=""
              @click.native="iconState(false)"
              to="/member/account"
              >My Profile</router-link
            >
          </div>
        </div>
      </nav>
      <div class="mainR">
        <router-view :window="window" :key="$route.fullPath" />
      </div>
      <input type="hidden" id="selq" value="" />
      <div class="qual" v-if="squal">
        <label v-for="(q, i) in qualities" :key="i">
          <input
            name="qual"
            type="radio"
            v-model="sel_qual.name"
            :value="q.name"
            @click="setQ()"
          />
          {{ q.name }}
        </label>
        <!-- <div v-for="(q, i) in qualities" :key="i"></div> -->
      </div>
      <div class="playy" v-if="player == 'true'">
        <div class="liveRadio">
          <vue-plyr ref="iplyr" :options="options" :key="playerkey">
            <audio
              preload="none"
              controls
              controlsList="nodownload"
              data-plyr-config='{ "settings":["quality"]}'
              class="aud"
            >
              <source :src="st" type="audio/mp3" />
            </audio>
          </vue-plyr>
        </div>
        <div class="pset">
          <font-awesome-icon
            @click="squal = !squal"
            class="settings"
            :icon="['fas', 'cog']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import custom from "../../javascript/custom";
import $ from "jquery";
import router from "@/router";
import UserService from "../../services/userService";
import axios from "axios";

export default {
  name: "MainPage",
  data() {
    return {
      myProfile: "",
      page: "",
      flash: false,
      flash_type: "",
      aqq: true,
      flash_msg: "",
      promo: false,
      id: "",
      screenWidth: 0,
      windowHeight: 0,
      windowWidth: 0,
      squal: false,
      sub: false,
      backgroundState: "background: #0a0a0a ;color: $white;",
      window: {
        width: 0,
        height: 0,
      },
      alert: {
        text: "",
        head: "",
        type: "",
      },
      player: null,
      options: {
        autopause: "true",
      },
      audio_q: [],
      qualities: [
        {
          name: "32 kbps",
          port: process.env.VUE_APP_32,
        },
        {
          name: "64 kbps",
          port: process.env.VUE_APP_64,
        },
        {
          name: "96 kbps",
          port: process.env.VUE_APP_96,
        },
        {
          name: "128 kbps",
          port: process.env.VUE_APP_128,
        },
        {
          name: "256 kbps",
          port: process.env.VUE_APP_256,
        },
      ],
      /* qualities: [                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             ],  */
      sel_qual: {
        name: "96 kbps",
        port: null,
        //port: "18975",
      },
      details: {
        quality: "",
      },

      salert: false,
      plan: null,
      count: 0,
      qu: process.env.VUE_APP_64,
      st: "",
      playerkey: 0,
    };
  },
  created() {
    this.attachBackground();
    // this.getProfile();
    this.player = this.$cookies.get("subscription");

    window.addEventListener("resize", this.checkScreenSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  computed: {
    centova() {
      return process.env.VUE_APP_CENTOVA;
      // return "http://52.205.111.133:"
    },
  },
  beforeMount() {
    // this.getProfile();
    if (
      window.location.href.indexOf("account") > -1 ||
      window.location.href.indexOf("plans") > -1
    ) {
      this.myProfile = false;
    } else {
      this.myProfile = true;
    }
    this.sub = this.$cookies.get("subscription");

    if (this.$cookies.get("subscription") == "false") {
      // this.$cookies.set("subscription", false);
      this.player = this.$cookies.get("subscription");
      router.push({
        path: "/member/plans",
      });
    } else {
      this.player = this.$cookies.get("subscription");
    }
    // this.plan = this.$cookies.get("user");
    // var auw = this.plan.current_audio_quality;
    // this.sel_qual.name = auw;

    // this.plan = this.plan.current_subscription;

    if (this.plan != null) {
      this.getAq();
    }
    //     this.$cookies.set("subscription", false);
    //     this.player = false;
    //     this.iconState(false);
    //     router.push({
    //         path: "/member/plans",
    //     });
    // } else {
    //     this.player = true;
    //     this.Aq;
    // }

    this.player = this.$cookies.get("subscription");

    if (window.location.href.indexOf("paypal") == -1) {
      if (this.plan == null && this.$cookies.get("subscription") == "false") {
        this.$cookies.set("subscription", false);
        this.player = this.$cookies.get("subscription");
        this.iconState(false);
        router.push({
          path: "/member/plans",
        });
      } else {
        this.player = this.$cookies.get("subscription");
        this.Aq;
      }
    }

    // this.getUQ()
  },
  mounted() {
    // console.log($("input[name=qual]").val());
    // $("#selq").val($("input[name=qual]"))
    this.checkScreenSize();
    this.windowHeight = $(window).height();
    this.windowWidth = $(window).width();
    // console.log(localStorage.getItem("Jam")=="false")
    this.player = this.$cookies.get("subscription");
    this.$bus.$on("flash", (data) => {
      this.alert.text = data.text;
      this.alert.head = data.head;
      this.alert.type = data.type;
      this.salert = true;
      setTimeout(
        function () {
          if (document.getElementById("alert") != null) {
            document.getElementById("alert").classList.remove("d-block");
          }
          this.removeAl();
        }.bind(this),
        5000
      );
    });

    this.$bus.$on("upgrade", () => {
      this.upgrade();
    });
    if (this.$cookies.get("getaq") == 1) {
      this.getUQ();
      this.$cookies.set("getaq", 2);
    }
    this.checkUserActive();
    this.getProfile();
  },

  Updated() {
    this.actSlide();
    this.player = this.$cookies.get("subscription");

    this.$bus.$on("subUp", () => {
      // this.plan = this.$cookies.get("user");
      // this.plan = this.plan.current_subscription;
      if (this.$cookies.get("subscription") == "false") {
        // this.$cookies.set("subscription", false);
        this.player = this.$cookies.get("subscription");
        router.push({
          path: "/member/plans",
        });
      } else {
        this.player = this.$cookies.get("subscription");
      }
    });

    if (window.location.href.indexOf("paypal") == -1) {
      // this.plan = this.$cookies.get("user");
      // this.plan = this.plan.current_subscription;
      if (this.$cookies.get("subscription") == "false") {
        // this.$cookies.set("subscription", false);
        this.player = this.$cookies.get("subscription");
        router.push({
          path: "/member/plans",
        });
      } else {
        this.player = this.$cookies.get("subscription");
      }
    }
  },
  components: {},
  watch: {
    $route: {
      deep: true,
      handler() {
        if (
          window.location.href.indexOf("account") > -1 ||
          window.location.href.indexOf("plans") > -1
        ) {
          this.myProfile = false;
        } else {
          this.myProfile = true;
        }
        // this.checkUserActive();
      },
    },
    "sel_qual.name"(newVal, oldVal) {
      // this.getQual();
      //   console.log(newVal, oldVal);
      if (this.$cookies.get("subscription") == "true") {
        if (this.sel_qual.name != null && this.sel_qual.name != "") {
          var x = this.sel_qual.name.split(" ")[0];

          this.qualities.forEach((el) => {
            var found = el.name.includes(x);
            if (found) {
              //console.log(el.name)
              this.sel_qual.port = el.port;
              this.qu = el.port;
            }
          });
          if (oldVal != newVal) {
            this.getQual(oldVal);
          }
        } else {
          this.sel_qual.name = this.qualities[1].name;
          this.sel_qual.port = this.qualities[1].port;
          this.qu = this.qualities[1].port;
        }
      }

      // ...
    },
  },

  methods: {
    setQ() {
      //   console.log($("input[name=qual]"));
      //   $("input[name=qual]").mouseup(function () {});
    },
    forceRender() {
      this.player = this.$cookies.get("subscription");
      // setTimeout(
      //     function() {
      //         this.player = true;
      //     }.bind(this),
      //     50
      // );
    },
    upgrade() {
      alert("Please upgrade your subscription");
    },
    setQuality() {
      //console.log(this.sel_qual.name)
      var found;
      for (var i = 0; i < this.audio_q.length; i++) {
        if (this.audio_q[i].name == this.sel_qual.name) {
          found = true;
          //console.log("true call")
          //this.details.quality = this.details.quality;
        }
      }
      if (!found) {
        this.details.quality = this.qualities[1].name;
        this.sel_qual.name = this.qualities[1].name;
        //console.log(this.details.quality)
      }
    },
    getAq() {
      // this.getProfile();
      // var getDetails = this.$cookies.get("user");
      var aq = this.plan.audio_quality;
      //var aq = this.$session.get("aq");

      //console.log(aq)
      var found = false;
      var memaq = [];
      if (aq != null && aq != undefined) {
        if (aq.length > 0) {
          aq.forEach((item) => {
            this.qualities.forEach((el) => {
              found = el.name === item;
              if (found) {
                memaq.push(el);
              }
            });
          });
        }
      }
      if (this.sel_qual.name != null && this.sel_qual.name != "") {
        // console.log(this.centova + this.qu + '/stream')
        var x = this.sel_qual.name.split(" ")[0];
        this.qualities.forEach((el) => {
          var found = el.name.includes(x);
          if (found) {
            this.sel_qual.port = el.port;
            this.qu = el.port;
          } else {
            this.sel_qual.port = this.qualities[1].port;
            this.qu = this.qualities[1].port;
          }
        });
      } else {
        this.sel_qual.name = memaq[0].name;
        //console.log("here")
      }

      this.audio_q = memaq.sort(this.customSort);
      this.setQuality();
      //console.log(memaq)
    },
    iconState(val) {
      if (val == false) {
        this.$session.set("navbarIcon", false);
      } else {
        this.$session.remove("navbarIcon");
      }
      this.myProfile = val;
    },
    getQual(ol) {
      //this.getAq();
      /* var alert = {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             text: "",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             head: "Stream quality updated.",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            type: "alert-success d-block",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           };
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           this.$bus.$emit("flash", alert);*/
      // this.squal = !this.squal;
      //console.log(this.sel_qual.name )
      this.details.quality = this.sel_qual.name;
      //         console.log(this.sel_qual.name)

      //console.log(this.details.quality)
      if (this.aqq) {
        this.updateUQ(ol);
        this.aqq = !this.aqq;
      }
      // console.log(this.qu);
      if (this.qu == null || this.qu == "") {
        this.qu = this.qualities[1].port;
      }
      this.st = this.centova + this.qu + "/stream";

      if (document.querySelector('button[aria-label="Pause"]')) {
        setTimeout(
          function () {
            // $("button.plyr__control.plyr__controls__item").click();

            var player = this.$refs.iplyr.player;
            var selected = document.getElementsByClassName("aud")[0];
            player.stop();
            selected.load();
            player.play();
          }.bind(this),
          500
        );
      }
      this.$refs.iplyr.player.stop();
      document.getElementsByClassName("aud")[0].load();
      // this.$refs.iplyr.player.play();
    },
    async getUQ() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getQuality();
        if (response.data.success == true) {
          // this.items = response.data.data;
          this.sel_qual.name = response.data.data.audio_quality;
          //console.log(response.data.data)
          this.getAq();
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getProfile() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile(
          this.$session.get("prof")
        );
        // if (response.data.success == true) {
        //     if (response.data.data.profile.disabled == true) {
        //         this.$session.clear();
        //         sessionStorage.clear();
        //         localStorage.clear();
        //         this.$session.destroy();
        //         router.push({
        //             path: "/",
        //         });
        //     }
        // }
        if (response.data.success == true) {
          this.plan = response.data.data.profile.current_subscription;
          this.getAq();
          //console.log(this.plan)
          // this.$cookies.set("user", response.data.data.profile);
          // var auw = this.plan.current_audio_quality;
          // this.sel_qual.name = auw;
          if (this.plan != null) {
            this.$cookies.set("subscription", true);
          }
          // else {
          //     this.$cookies.set("subscription", false);
          //     router.push({
          //         path: "/member/plans",
          //     });
          // }
          // this.user.profile.avatar = response.data.data.profile.avatar.large
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async updateUQ(ol) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      var qul = ol;
      try {
        //console.log(this.details)
        const response = await UserService.updateQuality(this.details);
        if (response.data.success == true) {
          //console.log(response.data)
          // this.plan = this.$cookies.get("user");
          this.plan.current_audio_quality = response.data.data.audio_quality;
          // this.$cookies.set("user", this.plan);
          // var alert = {
          //   text: "",
          //   head: "Stream quality updated.",
          //   type: "alert-success d-block",
          // };
          // this.$bus.$emit("flash", alert);
          this.squal = false;
          //this.forceRender()
        } else {
          //   this.sel_qual.name = qul;
          //   console.log(this.sel_qual.name);
          //   $(".qual").val(qul);
          //   console.log($(".qual").val());
          if (this.sel_qual.name != qul && qul != undefined) {
            this.plan.current_audio_quality = qul;
            var alert = {
              text: "",
              head: response.data.flash.error,
              type: "alert-success d-block",
            };
            this.$bus.$emit("flash", alert);
            this.squal = false;
            this.sel_qual.name = "";
          }
        }
      } catch (err) {
        //console.log(err);
      }
    },

    onCheck(event) {
      const radio = event.target;
      this.value = radio.value;
    },
    removeAl() {
      this.salert = false;
      delete this.alert.type;
    },

    actSlide() {
      const slider = document.querySelector(".erow");
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    },

    loadPage(pg, na) {
      this.page = pg;
      custom.updateNav(na);
      $(".navbar-toggler").click();
    },
    async attachBackground() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getCurrentBackground();
        if (response.data.success == true) {
          if (
            response.data.data.thumbnail != null &&
            response.data.data.thumbnail != "undefined"
          ) {
            if ($(window).width() <= 991) {
              this.backgroundState =
                "background: url(" +
                response.data.data.thumbnail +
                ");color: $white;background-size:100%;background-position: center center;background-attachment: fixed;background-size: cover;";
            } else {
              this.backgroundState =
                "background: url(" +
                response.data.data.thumbnail +
                ");color: $white;background-size:100%;position: center;background-attachment: fixed;background-size: cover;";
            }
          } else {
            this.backgroundState = "background: #0a0a0a ;color: $white;";
          }
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    checkScreenSize() {
      this.screenWidth = $(window).width();
      this.windowHeight = $(window).height();
      this.windowWidth = $(window).width();
      if (this.screenWidth <= 991) {
        var route = document.getElementsByClassName("routeLink");
        for (var x = 0; x < route.length; x++) {
          route[x].setAttribute("data-toggle", "collapse");
          route[x].setAttribute("data-target", "#navbarNavAltMarkup");
        }
        this.attachBackground();
      } else {
        route = document.getElementsByClassName("routeLink");
        for (x = 0; x < route.length; x++) {
          route[x].setAttribute("data-toggle", "");
          route[x].setAttribute("data-target", "");
        }
      }
    },
    customSort(a, b) {
      return (
        Number(a.name.match(/(\d+)/g)[0]) - Number(b.name.match(/(\d+)/g)[0])
      );
    },
    async checkUserActive() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile(
          this.$session.get("prof")
        );
        if (response.data.success == true) {
          //console.log(response.data)
          if (response.data.data.profile.disabled == true) {
            this.$session.clear();
            sessionStorage.clear();
            localStorage.clear();
            this.$session.destroy();
            router.push({
              path: "/",
            });
          }
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
  },
};
</script>
