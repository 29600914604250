import mainInstance from '@/services/api'

export default {
    getDash() {
        try {
            return mainInstance().get('admin/dashboard');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getCategories(data) {
        try {
            return mainInstance().get('admin/program-categories?page=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    newCategories() {
        try {
            return mainInstance().get('admin/program-categories/new');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    showCategory(data) {
        try {
            return mainInstance().get('admin/program-categories/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deleteCategory(data) {
        try {
            return mainInstance().delete('admin/program-categories/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createCategory(data) {
        try {
            return mainInstance().post('admin/program-categories', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    editCategory(data) {
        try {
            return mainInstance().get('admin/program-categories/' + data + '/edit');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateCategory(id, data) {
        try {
            return mainInstance().patch('admin/program-categories/' + id, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getBackgrounds(data) {
        try {
            return mainInstance().get('admin/backgrounds?page=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    newBackgrounds() {
        try {
            return mainInstance().get('admin/backgrounds/new');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    showBackgrounds(data) {
        try {
            return mainInstance().get('admin/backgrounds/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deleteBackgrounds(data) {
        try {
            return mainInstance().delete('admin/backgrounds/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createBackgrounds(data) {
        try {
            return mainInstance().post('admin/backgrounds', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    editBackgrounds(data) {
        try {
            return mainInstance().get('admin/backgrounds/' + data + '/edit');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateBackgrounds(id, data) {
        try {
            return mainInstance().patch('admin/backgrounds/' + id, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createSplashs(data) {
        try {
            return mainInstance().post('admin/splashes', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getSplashs(data) {
        try {
            return mainInstance().get('admin/splashes?page=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deletesplashs(data) {
        try {
            return mainInstance().delete('admin/splashes/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    editSplashs(data) {
        try {
            return mainInstance().get('admin/splashes/' + data + '/edit');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateSplashs(id, data) {
        try {
            return mainInstance().patch('admin/splashes/' + id, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getHosts(data) {
        try {
            return mainInstance().get('admin/hosts?page=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getScHosts() {
        try {
            return mainInstance().get('admin/hosts?per_page=300');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    showHost(data) {
        try {
            return mainInstance().get('admin/hosts/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deleteHost(data) {
        try {
            return mainInstance().delete('admin/hosts/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createHost(data) {
        try {
            return mainInstance().post('admin/hosts', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    editHost(data) {
        try {
            return mainInstance().get('admin/hosts/' + data + '/edit');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateHost(id, data) {
        try {
            return mainInstance().patch('admin/hosts/' + id, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getAds(data) {
        try {
            return mainInstance().get('admin/ads?page=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    newAds() {
        try {
            return mainInstance().get('admin/ads/new');
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    getSchedule() {
        try {
            return mainInstance().get('admin/schedules');
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    showSchedule(data) {
        try {
            return mainInstance().get('admin/schedules/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deleteSchedule(data) {
        try {
            return mainInstance().delete('admin/schedules/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deleteSchedulePro(sch, data) {
        try {
            return mainInstance().patch('admin/schedules/' + sch, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createSchedule(data) {
        try {
            return mainInstance().post('admin/schedules', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    editSchedule(data) {
        try {
            return mainInstance().get('admin/schedules/' + data + '/edit');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateSchedule(id, data) {
        try {
            return mainInstance().patch('admin/schedules/' + id, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateScheduleProgram(id, data) {
        try {
            return mainInstance().patch('admin/schedules/' + id + '/programmes/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    deleteScheduleProgram(id, data) {
        try {
            return mainInstance().delete('admin/schedules/' + id + '/programmes/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    showAd(data) {
        try {
            return mainInstance().get('admin/ads/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deleteAd(data) {
        try {
            return mainInstance().delete('admin/ads/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createAd(data) {
        try {
            return mainInstance().post('admin/ads', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    editAd(data) {
        try {
            return mainInstance().get('admin/ads/' + data + '/edit');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateAd(id, data) {
        try {
            return mainInstance().patch('admin/ads/' + id, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    filterAd() {
        try {
            return mainInstance().get('admin/ad_filter');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getFilterAD(pg, data) {
        try {
            return mainInstance().get('admin/ads?page=' + pg, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    filterAds(data) {
        try {
            return mainInstance().patch('admin/ad_filter', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getPrograms(data) {
        try {
            return mainInstance().get('admin/programs?page=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getScPrograms(data) {
        try {
            return mainInstance().get('admin/programs?per_page=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    newPrograms() {
        try {
            return mainInstance().get('admin/programs/new');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    showProgram(data) {
        try {
            return mainInstance().get('admin/programs/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    showProgramSession(data, pg) {
        try {
            return mainInstance().get('admin/programs/' + data + '/sessions' + '?per_page=10&page=' + pg);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deleteProgram(data) {
        try {
            return mainInstance().delete('admin/programs/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createProgram(data) {
        try {
            return mainInstance().post('admin/programs', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    editProgram(data) {
        try {
            return mainInstance().get('admin/programs/' + data + '/edit');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateProgram(id, data) {
        try {
            return mainInstance().patch('admin/programs/' + id, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getSeasons(data) {
        try {
            return mainInstance().get('admin/seasons?page=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    showSeason(data) {
        try {
            return mainInstance().get('admin/seasons/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deleteSeason(data) {
        try {
            return mainInstance().delete('admin/seasons/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createSeason(data) {
        try {
            return mainInstance().post('admin/seasons', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    editSeason(data) {
        try {
            return mainInstance().get('admin/seasons/' + data + '/edit');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateSeason(id, data) {
        try {
            return mainInstance().patch('admin/seasons/' + id, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    getSessions(data) {
        try {
            return mainInstance().get('admin/sessions?page=' + data + 'per_page=10');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    newSession() {
        try {
            return mainInstance().get('admin/sessions/new');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    showSession(data) {
        try {
            return mainInstance().get('admin/sessions/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deleteSession(data) {
        try {
            return mainInstance().delete('admin/sessions/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createSession(data) {
        try {
            return mainInstance().post('admin/sessions', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    editSession(data) {
        try {
            return mainInstance().get('admin/sessions/' + data + '/edit');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateSession(id, data) {
        try {
            return mainInstance().patch('admin/sessions/' + id, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getPlans(data) {
        try {
            return mainInstance().get('admin/plans?page=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getZPlans() {
        try {
            return mainInstance().get('plans?price=0');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    newPlans() {
        try {
            return mainInstance().get('admin/plans/new');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    showPlan(data) {
        try {
            return mainInstance().get('admin/plans/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deletePlan(data) {
        try {
            return mainInstance().delete('admin/plans/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createPlan(data) {
        try {
            return mainInstance().post('admin/plans', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    editPlan(data) {
        try {
            return mainInstance().get('admin/plans/' + data + '/edit');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updatePlan(id, data) {
        try {
            return mainInstance().patch('admin/plans/' + id, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getRoles(data) {
        try {
            return mainInstance().get('admin/roles?page=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    showRole(data) {
        try {
            return mainInstance().get('admin/roles/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deleteRole(data) {
        try {
            return mainInstance().delete('admin/roles/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deleteRolePer(data, per) {
        try {
            return mainInstance().delete('admin/roles/' + data + '/permissions/' + per);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createRole(data) {
        try {
            return mainInstance().post('admin/roles', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    editRole(data) {
        try {
            return mainInstance().get('admin/roles/' + data + '/edit');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateRole(id, data) {
        try {
            return mainInstance().patch('admin/roles/' + id, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getUsers(data, order, dir) {
        try {
            return mainInstance().get('admin/users?page=' + data + '&order_by=' + order + '&order_dir=' + dir);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    newUser() {
        try {
            return mainInstance().get('admin/users/new');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    newUserSub(uid, pid) {
        try {
            return mainInstance().patch('admin/users/' + uid + '/subscription/' + pid);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    disableUser(data) {
        try {
            return mainInstance().put('deactivate', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getFilUsers(data, filter, order, dir) {
        try {
            return mainInstance().get('admin/users?page=' + data + filter + '&order_by=' + order + '&order_dir=' + dir);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    showUser(data) {
        try {
            return mainInstance().get('admin/users/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getProfile() {
        try {
            return mainInstance().get('profile');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateProfile(data) {
        try {
            return mainInstance().put('profile', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deleteUser(data) {
        try {
            return mainInstance().delete('admin/users/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createUser(data) {
        try {
            return mainInstance().post('admin/users', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createAdminUser(data) {
        try {
            return mainInstance().post('admin/super', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    editUser(data) {
        try {
            return mainInstance().get('admin/users/' + data + '/edit');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateUser(id, data) {
        try {
            return mainInstance().patch('admin/users/' + id, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getUserCSV(data) {
        try {
            return mainInstance().get('admin/users.csv?' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    newCoupons() {
        try {
            return mainInstance().get('admin/coupons/new');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getCoupons(data) {
        try {
            return mainInstance().get('admin/coupons?page=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    showCoupon(data) {
        try {
            return mainInstance().get('admin/coupons/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    deleteCoupon(data) {
        try {
            return mainInstance().delete('admin/coupons/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createCoupon(data) {
        try {
            return mainInstance().post('admin/coupons', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    editCoupon(data) {
        try {
            return mainInstance().get('admin/coupons/' + data + '/edit');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateCoupon(id, data) {
        try {
            return mainInstance().patch('admin/coupons/' + id, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getTransactions(from, to, data) {
        try {
            return mainInstance().get('admin/payments?from=' + from + '&to=' + to + '&page=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    showTransaction(data) {
        try {
            return mainInstance().get('admin/payments/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    toggleGeofence(is_enabled = false) {
        try {
            return mainInstance().post('toggle-geofence', { is_enabled: is_enabled });
        } catch (error) {
            return error;
        }
    },
    fetchGeofence() {
        try {
            return mainInstance().get('geofence');
        } catch (error) {
            return error;
        }
    }

}