<template>
  <div id="new_user" class="col-lg-12 mt-4 adacc">
    <div class="col-lg-12">
      <h3><b>New AD</b></h3>
      <form class="" @submit.prevent="createAd">
        <div class="mt-4">
          <div class="row mb-3">
            <div class="col-md-8">
              <!-- <label>Programme Name </label> -->
              <input
                type="text"
                v-model="details.ad.name"
                class="input-field form-control"
                placeholder="Name"
                required
              />
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-lg-2 col-md-2">
              <label class="cap">Type</label>
            </div>
            <div class="col-lg-2 col-md-2">
              <input
                id="img"
                type="radio"
                v-model="details.ad.ad_type"
                name="progtype"
                class=""
                placeholder=""
                required
                value="image"
              />
              Image
              <!-- <label for="live">Live</label> -->
            </div>
            <div class="col-lg-2 col-md-2">
              <!-- <label>Payment Period Unit</label> -->
              <input
                id="vid"
                type="radio"
                v-model="details.ad.ad_type"
                name="progtype"
                class=""
                placeholder=""
                required
                value="video"
              />
              Video
              <!-- <label for="arch">Archive</label> -->
            </div>
          </div>

          <div class="row mt-3 mb-3">
            <div class="col-lg-8 col-md-8">
              <div class="uploadarea">
                <!-- <label>Thumbnail</label> -->
                <button
                  type="button"
                  class="form-btn m-0 imgbtn"
                  v-if="details.ad.ad_type == 'image'"
                >
                  Choose Image
                </button>
                <button v-else type="button" class="form-btn m-0 imgbtn">
                  Choose Video
                </button>
                <input
                  id="primg"
                  type="file"
                  @change="getThumb"
                  ref="thumbupload"
                  accept="images*"
                  required
                />
                <img
                  v-if="details.ad.ad_type == 'image' && details.ad.thumbnail"
                  class="ml-5 av"
                  :src="details.ad.thumbnail"
                />
                <video
                  class="ml-5 av vid"
                  v-else-if="
                    details.ad.ad_type == 'video' && details.ad.thumbnail
                  "
                  :src="details.ad.thumbnail"
                ></video>
              </div>
            </div>
            <p class="col-lg-8 mt-2">Minimum Dimensions 1240*250</p>
          </div>

          <div class="row mb-4">
            <div class="col-md-8">
              <!-- <label>Description</label> -->
              <input
                v-model="details.ad.url"
                placeholder="URL"
                class="input-field form-control mt-0"
                required
              />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-2 col-md-2">
              <label class="cap">Limit</label>
            </div>
            <div class="col-lg-2 col-md-2">
              <input
                id="imp"
                type="radio"
                v-model="details.ad.ad_limit"
                name="limtype"
                class=""
                placeholder=""
                required
                value="impression"
              />
              Impressions
              <!-- <label for="live">Live</label> -->
            </div>
            <div class="col-lg-2 col-md-2">
              <!-- <label>Payment Period Unit</label> -->
              <input
                id="exp"
                type="radio"
                v-model="details.ad.ad_limit"
                name="limtype"
                class=""
                placeholder=""
                required
                value="end-date"
              />
              Expiry Date
              <!-- <label for="arch">Archive</label> -->
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-8 col-md-8">
              <!-- <label>Start Time</label> -->
              <datetime
                type="datetime"
                :use12-hour="u12"
                zone="local"
                value-zone="local"
                placeholder="Start Date"
                input-class="input-field form-control"
                v-model="details.ad.start_at"
                required
              ></datetime>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-8 col-md-8">
              <!-- <label>Start Time</label> -->
              <datetime
                type="datetime"
                :use12-hour="u12"
                zone="local"
                value-zone="local"
                placeholder="Expiry Date"
                input-class="input-field form-control"
                v-model="details.ad.end_at"
                v-if="details.ad.ad_limit == 'end-date'"
                :required="details.ad.ad_limit == 'end-date' ? true : false"
              ></datetime>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-8 col-md-8">
              <!-- <label>Start Time</label> -->
              <input
                v-model="details.ad.impression_limit"
                placeholder="Impressions Limit"
                class="input-field form-control mt-0"
                v-if="details.ad.ad_limit == 'impression'"
                :required="details.ad.ad_limit == 'impression' ? true : false"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-8 col-md-8">
              <!-- <label>Start Time</label> -->

              <v-select
                :options="priorities"
                :multiple="false"
                v-model="details.ad.priority"
                placeholder="Priority"
                :reduce="(name) => name.id"
                label="name"
                class="required"
                required
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-8 col-md-8">
              <!-- <label>Start Time</label> -->
              <input
                v-model="details.ad.ordering"
                placeholder="Order"
                class="input-field form-control mt-0"
                required
              />
            </div>
          </div>

          <div class="my-3">
            <h4 class="renew d-inline">Published</h4>
            <label class="switch ml-4">
              <input type="checkbox" v-model="details.ad.published" />
              <span class="slider round"></span>
            </label>
          </div>

          <router-link to="/admin/ads"
            ><button class="form-btn mr-4 cancel">Cancel</button></router-link
          >

          <button id="saveUser" type="submit" class="form-btn">Add Ad</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";
import router from "@/router";

export default {
  name: "NewAdPage",
  data() {
    return {
      details: {
        ad: {
          name: "",
          published: true,
          priority: null,
          ad_type: "",
          thumbnail: "",
          impression_limit: null,
          ordering: null,
          start_at: "",
          end_at: "",
          ad_limit: "",
        },
      },
      plans: [],
      categories: [],
      instructors: [],
      sizes: [
        {
          name: "Small",
          size: "col-sm-3",
        },
        {
          name: "Medium",
          size: "col-sm-5",
        },
      ],
      u12: true,
      bx: 0,
      priorities: [
        {
          id: 1,
          name: "High",
        },
        {
          id: 2,
          name: "Medium",
        },
        {
          id: 3,
          name: "Low",
        },
      ],
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getTypes();
  },
  components: {},
  methods: {
    selBx(i) {
      this.bx = i;
    },
    async getTypes() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.newAds();
        if (response.data.success == true) {
          this.plans = response.data.data.options.plans;
          this.categories = response.data.data.options.program_categories;
          this.instructors = response.data.data.options.instructors;
          this.tags = response.data.data.options.tags;
          this.details.ad.ordering = response.data.data.program.ordering;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async createAd() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        var bt = document.getElementById("saveUser");
        const response = await AdminService.createAd(this.details);
        if (response.data.success == true) {
          ////bt.classList.add("success");
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          // //bt.innerText = response.data.flash.notice
          setTimeout(function () {
            router.push({
              path: "/admin/ads",
            });
          }, 500);
        } else {
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Save Programme";
          }, 1500);
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    getThumb(event) {
      const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 20) {
        alert("File size exceeds 20 MB");
        this.$refs.thumbupload.value = null;
      } else {
        const fr = new FileReader();
        fr.onloadend = function () {
          this.details.ad.thumbnail = fr.result;
        }.bind(this);

        fr.readAsDataURL(event.target.files[0]);
      }
    },
    getHead(event) {
      const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 20) {
        alert("File size exceeds 20 MB");
        this.$refs.headupload.value = null;
      } else {
        const fr = new FileReader();
        fr.onloadend = function () {
          this.details.ad.header_background = fr.result;
        }.bind(this);

        fr.readAsDataURL(event.target.files[0]);
      }
    },
  },
};
</script>
