import mainInstance from '@/services/api'

export default {
    getUser(data) {
        try {
            return mainInstance().post('login', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    logoutUser() {
        try {
            return mainInstance().delete('logout');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    forgotPassword(data) {
        try {
            return mainInstance().post('password', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    resetPassword(data) {
        try {
            return mainInstance().put('password', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    signupUser(data) {
        try {
            return mainInstance().post('signup', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getActivate(data) {
        try {
            return mainInstance().put('activate', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },

}