<template>
  <div>
    <div class="row my-5">
      <div class="col-sm-6">
        <h1>
          {{ dash.active_users }}
          <span class="dash-sub">Active Users from Jamaica</span>
        </h1>
        <h1>
          {{ dash.active_international_users }}
          <span class="dash-sub">Active International Users </span>
        </h1>
      </div>
      <div class="col-sm-5">
        <h1>
          {{ dash.recent_subscriptions }}
          <span class="dash-sub">New Subscriptions</span>
        </h1>
      </div>
    </div>
    <div class="row m48">
      <div class="col-sm-6">
        <div class="bkwh table-list">
          <h3>Popular Programmes</h3>
          <div class="table-responsive-sm">
            <table
              class="table boxtable"
              v-if="
                dash.top_programs.length != 0 &&
                dash.top_programs.length != undefined
              "
            >
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Favourites</th>
                </tr>
              </thead>
              <tbody>
                <router-link
                  class="link_row"
                  :to="{ name: 'ShowProgramme', params: { id: program.id } }"
                  v-for="(program, index) in dash.top_programs"
                  :key="program + index"
                >
                  <tr class="table-det spacer">
                    <td>
                      <span class="prName">{{ program.name }}</span>
                    </td>
                    <td>
                      <span>{{ program.bookmarks_count }}</span>
                    </td>

                    <td class="urltxt">
                      <span> </span>
                    </td>
                  </tr>
                </router-link>
              </tbody>
            </table>
            <div v-else>
              <h5 class="mx-auto text-center p-3">No Popular Programmes</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="table-list bkwh">
          <h3>New Users</h3>

          <table v-if="dash.new_users.length != 0" class="table boxtable">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Subscription</th>
                <th scope="col">Country</th>
              </tr>
            </thead>
            <tbody>
              <router-link
                class="link_row"
                :to="{ name: 'ShowProgramme', params: { id: user.id } }"
                v-for="(user, index) in dash.new_users"
                :key="user + index"
              >
                <tr class="table-det spacer">
                  <td>
                    <span>{{ user.name }}</span>
                  </td>

                  <td class="urltxt">
                    <span v-if="user.subscriptions[0]"
                      >{{ user.subscriptions[0].plan_name }}
                    </span>
                  </td>
                  <td>
                    <span>{{ user.country }}</span>
                  </td>
                </tr>
              </router-link>
            </tbody>
          </table>
          <div v-else>
            <h5 class="mx-auto text-center p-3">No New Users</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";

export default {
  name: "DashboardPage",
  data() {
    return {
      dash: {},
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getDash();
  },
  components: {},
  Updated() {
    if (JSON.parse(sessionStorage.getItem("flash")) != undefined) {
      this.$bus.$emit("flash", JSON.parse(sessionStorage.getItem("flash")));
      sessionStorage.removeItem("flash");
    }
  },
  methods: {
    async getDash() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getDash();
        if (response.data.success == true) {
          this.dash = response.data.data;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
        // this.$emit("myEvent")
      }
    },
  },
};
</script>
