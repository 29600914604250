<template>
  <div class="main-det" v-bind:style="{ background: activeBackground }">
    <div class="ScheduleOuterRow">
      <div class="ScheduleOuterColumn">
        <h2 class="mb-3 shedule">Schedule</h2>
        <div>
          <div class="mb-5 dayblk">
            <!-- <h5 class="day">Sunday</h5> -->
            <div
              class="mb-5"
              v-for="(wk, index) in orderedWeek"
              :key="'wk' + index"
            >
              <!-- <h5 class="day" v-html="groupByDate(ins.start_at)"></h5> -->
              <h5 class="day shedule">
                {{ wk.day }}
                <font-awesome-icon
                  class="float-right arr-icon"
                  @click="changeIcon(index)"
                  data-toggle="collapse"
                  :data-target="'#' + wk.day"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  :icon="['fas', 'angle-down']"
                />
              </h5>
              <div
                class="collapse daylySchedule"
                :id="wk.day"
                v-for="(ins, index) in wk.programs"
                :key="'day' + index"
              >
                <router-link
                  :to="{ name: 'SPrograms', params: { id: ins.program_id } }"
                >
                  <div class="row w-100 sess mb-3 ml-0">
                    <div class="col-lg-12 dayinfo daysess">
                      <div class="row m-0 info">
                        <div class="col-lg-3 p-0">
                          <p class="mb-0 time p-1">
                            {{ moment(ins.start_at).format("h:mma z") }} -
                            {{ moment(ins.end_at).format("h:mma z") }}
                          </p>
                        </div>
                        <div class="col-lg-4 p-0">
                          <p class="mb-0 p-1">
                            {{ ins.program_name }}
                          </p>
                        </div>
                        <div class="col-lg-5 p-0">
                          <p class="mb-0 p-1 presn" v-if="ins.host">
                            {{ ins.host.name }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="currentLive ScheduleOuterColumn">
        <h3 class="" @click="playPro()">Listen Live</h3>
        <div v-if="current_program.name != ''">
          <div
            @click="playPro()"
            class="wig-blk p-4"
            :style="liveCoverBackground"
          >
            <div class="ScheduleInnerRow">
              <div class="ScheduleInnerColumn">
                <h2>Now On</h2>
                <h3 class="mt-4 h3Div">{{ current_program.host }}</h3>
                <h3 class="h3Div">{{ current_program.name }}</h3>
                <h4>
                  {{ moment(current_program.startime).format("hh:mm a") }} -
                  {{ moment(current_program.endtime).format("hh:mm a") }}
                </h4>
              </div>
              <div class="imgContainer ScheduleInnerColumn">
                <!-- <img class="ava" :src="current_program.thumbnail" /> -->
              </div>
            </div>
          </div>
        </div>
        <div v-else class="">
          <h6
            v-bind:style="{ color: scheduleText }"
            class="text-center pb-5 pt-5"
          >
            No Episodes Available
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import custom from '../../javascript/custom'
import UserService from "../../../services/userService";
import axios from "axios";
import router from "@/router";
import moment from "moment";
import $ from "jquery";

axios.defaults.timeout = 40000;
export default {
  name: "SchedulePage",
  data() {
    return {
      current_program: {
        name: "",
        startime: "",
        endtime: "",
        thumbnail: "",
        host: "",
        activeBackground: "",
        header: {
          background: "",
        },
      },
      liveCoverBackground: "",
      scheduleText: "#EEEEEE !important",
      week: [],
      settings: {},
      down: false,
      // now: moment().tz(moment.tz.guess(true)),
      temp: {
        old_unique_date: null,
      },
      today: null,
      time: "",
      activeBackground: "",
    };
  },
  created() {
    //this.id = this.$cookies.get("user").id;
  },
  mounted() {
    // this.getListen();
    this.checkBackground();
    // this.plan = this.$cookies.get("user");
    // console.log(this.plan);
    // this.plan = this.plan.current_subscription;
    if (this.$cookies.get("subscription") == "false") {
      // this.$cookies.set("subscription", false);
      router.push({
        path: "/member/plans",
      });
    } else {
      this.getWeek();
    }

    this.getTime();
  },
  computed: {
    orderedWeek: function () {
      return this._.orderBy(this.week, "or");
    },
  },
  components: {},
  watch: {
    // watch the variable today and each time it changes call the method getliveprogram
    today() {
      this.getLiveProgram();
    },
  },
  methods: {
    getTime() {
      window.setInterval(
        function () {
          this.today = moment().format("ddd MM/DD/y hh:mm:ss");
          this.time = moment();
        }.bind(this),
        1000
      );
    },
    getLiveProgram() {
      var format = "hh:mm:ss";
      for (var x = 0; x < this.week.length; x++) {
        if (this.week[x].day == moment().format("dddd")) {
          for (var y = 0; y < this.week[x].programs.length; y++) {
            // var zone=moment.tz.guess(true)
            var time = moment();
            time = time._d;
            time = time.toString().split(" ")[4];
            var beforeTime = new Date(this.week[x].programs[y].start_at);
            var afterTime = new Date(this.week[x].programs[y].end_at);
            // time =moment(time._d,format).tz(zone).format(format)
            beforeTime = moment(beforeTime, format);
            beforeTime = beforeTime._i;
            beforeTime = beforeTime.toString().split(" ")[4];
            afterTime = moment(afterTime, format);
            afterTime = afterTime._i;
            afterTime = afterTime.toString().split(" ")[4];
            time = moment(time, format);
            afterTime = moment(afterTime, format);
            beforeTime = moment(beforeTime, format);

            if (time.isBetween(beforeTime, afterTime, "[]")) {
              this.current_program.startime = this.week[x].programs[y].start_at;
              this.current_program.endtime = this.week[x].programs[y].end_at;
              this.current_program.name = this.week[x].programs[y].program_name;
              this.current_program.host = this.week[x].programs[y].host.name;
              this.current_program.thumbnail =
                this.week[x].programs[y].host.thumbnail_urls.large;
              this.liveCoverBackground =
                "background-image: url(" +
                this.week[x].programs[y].host.thumbnail_urls.large +
                ");";
            } else if (
              time.isSame(afterTime, "hour") &&
              time.isSameOrBefore(afterTime, "minute", "[]")
            ) {
              this.current_program.startime = this.week[x].programs[y].start_at;
              this.current_program.endtime = this.week[x].programs[y].end_at;
              this.current_program.name = this.week[x].programs[y].program_name;
              this.current_program.host = this.week[x].programs[y].host.name;
              this.current_program.thumbnail =
                this.week[x].programs[y].host.thumbnail_urls.large;
              this.liveCoverBackground =
                "background-image: url(" +
                this.week[x].programs[y].host.header_background_urls.large +
                ")";
            } else {
              this.now = null;
            }
          }
        }
      }
    },
    playPro() {
      this.$bus.$emit("pplayer", true);

      if (
        $("button.plyr__control.plyr__controls__item").attr("data-plyr") ==
        "play"
      ) {
        $("button.plyr__control.plyr__controls__item").click();
      }
    },

    async getWeek() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.showSchedule();
        Array.prototype.insert = function (index, item) {
          this.splice(index, 0, item);
        };
        if (response.data.success == true) {
          var week = response.data.data;
          for (var dy = 0; dy < week.length; dy++) {
            if (week[dy].day == "Sunday") {
              week[dy].or = 1;
              // this.week.insert(0,week[dy])
            }
            if (week[dy].day == "Monday") {
              week[dy].or = 2;
              // this.week.insert(1,week[dy])
            }
            if (week[dy].day == "Tuesday") {
              week[dy].or = 3;
              // this.week.insert(2,week[dy])
            }
            if (week[dy].day == "Wednesday") {
              week[dy].or = 4;
              // this.week.insert(3,week[dy])
            }
            if (week[dy].day == "Thursday") {
              week[dy].or = 5;
              // this.week.insert(4,week[dy])
            }
            if (week[dy].day == "Friday") {
              week[dy].or = 6;
              // this.week.insert(5,week[dy])
            }
            if (week[dy].day == "Saturday") {
              week[dy].or = 7;
              // this.week.insert(6,week[dy])
            }
          }
          this.week = week;
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async checkBackground() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getCurrentBackground();
        if (response.data.success == true) {
          //this.background = response.data.data.thumbnail

          if (
            response.data.data.thumbnail != null &&
            response.data.data.thumbnail != "undefined"
          ) {
            this.activeBackground = "#000000b5";
            this.scheduleText = "#EEEEEE !important";
          } else {
            this.activeBackground = "";
            this.scheduleText = "#0a0a0a !important";
          }
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    changeIcon(index) {
      var icon = document.getElementsByClassName("arr-icon")[index];
      if (icon.classList[3] == "fa-angle-down") {
        icon.classList.remove("fa-angle-down");
        icon.classList.add("fa-angle-up");
      } else {
        icon.classList.remove("fa-angle-up");
        icon.classList.add("fa-angle-down");
      }
    },
  },
};
</script>
