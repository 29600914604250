var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-det-background mb-5"},[_c('div',{staticClass:"offset-md-3"},[_c('div',{staticClass:"current-payment"},[_c('div',{staticClass:"row"},[(_vm.background != null && _vm.background != 'undefined')?_c('div',{staticClass:"mx-auto text-center backgroundDiv"},[_c('h3',{staticClass:"text-center pb-3"},[_vm._v("Current Background")]),_c('div',{staticClass:"mb-4"},[(_vm.background)?_c('img',{staticClass:"ml-0 text-center av",attrs:{"src":_vm.background}}):_vm._e()]),(
              (_vm.sub == 'true' && _vm.can('regular_background:edit')) ||
              _vm.can('vip') ||
              _vm.can('custom_background:edit')
            )?_c('button',{staticClass:"form-btn mb-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.changeBackground()}}},[_vm._v(" Change Background ")]):_c('button',{staticClass:"form-btn mb-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.upgrade()}}},[_vm._v(" Change Background ")])]):_c('div',[_c('h5',{staticClass:"mx-auto text-center p-3"},[_vm._v("No Background Set.")]),(
              (_vm.sub == 'true' && _vm.can('regular_background:edit')) ||
              _vm.can('vip') ||
              _vm.can('custom_background:edit')
            )?_c('button',{staticClass:"form-btn mb-3 ml-3 cs",attrs:{"type":"button"},on:{"click":function($event){return _vm.changeBackground()}}},[_vm._v(" Set Background ")]):_c('button',{staticClass:"form-btn mb-3 ml-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.upgrade()}}},[_vm._v(" Set Background ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }