<template>
  <div>
    <div class="table-list users-list mt-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0">
            <b>Roles</b>
            <router-link
              v-if="can('role:create') || can('admin')"
              to="/admin/roles/new"
              ><button class="form-btn ml-4">Add New</button></router-link
            >
          </h4>
        </div>
      </div>
      <!-- <paginate
        name="orderedRoles"
        :list="orderedRoles"
        :per="lim"
        class="pl-0"
      > -->
      <div class="table-responsive-sm">
        <table class="table boxtable" v-if="roles.length > 0">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Permissions</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              class="link_row"
              :to="
                can('role:edit') || can('admin')
                  ? { name: 'EditRole', params: { id: ro.id } }
                  : '#'
              "
              v-for="(ro, index) in roles"
              :key="role + index"
            >
              <tr class="table-det spacer">
                <td>
                  <span>
                    {{ ro.label }}
                  </span>
                </td>
                <td>
                  <span
                    class="mr-2 plsp"
                    v-for="(per, index) in ro.permissions.slice(0, 4)"
                    :key="per + index"
                    >{{ per.permissable_model }}</span
                  >
                  <span class="plsp" v-if="ro.permissions.length > 5"
                    >. . . .</span
                  >
                </td>
                <td class="text-right">
                  <span class="mr-2 plsp">
                    <font-awesome-icon
                      v-if="
                        (can('role:delete') || can('admin')) &&
                        ro.label != 'Admin'
                      "
                      v-on:click.prevent="remItem(ro.id)"
                      class="float-right"
                      :icon="['fal', 'times']"
                  /></span>
                </td>
              </tr>
            </router-link>
          </tbody>
        </table>
        <h5 v-else class="mx-auto text-center p-3">No Roles Available</h5>
      </div>
      <ul class="pl-0 paginate-links" v-if="pages > 1">
        <li v-if="pages > 0" @click="getRoles(1)" class="left-arrow disabled">
          <a>«</a>
        </li>

        <li
          @click="getRoles(p)"
          :class="p == current ? 'number active pg' : 'number pg'"
          v-for="(p, index) in pages"
          :key="pag + index"
        >
          <span v-if="Math.abs(p - current) < 4 || p == pages || p == 1">
            <a
              :class="{
                current: current === p,
                last: p == pages && Math.abs(p - current) > 4,
                first: p == 1 && Math.abs(p - current) > 4,
              }"
              >{{ p }}</a
            >
          </span>
        </li>
        <li
          v-if="pages > 0"
          @click="getRoles(pages)"
          class="right-arrow disabled"
        >
          <a>»</a>
        </li>
      </ul>
      <!-- </paginate> -->
      <!-- <paginate-links class="pl-0" for="orderedRoles" :async="true" :limit="lim" :show-step-links="true"></paginate-links> -->
    </div>
  </div>
</template>

<script>
import AdminService from "../../../../services/adminService";
import axios from "axios";

export default {
  name: "RolesPage",
  data() {
    return {
      paginate: ["orderedRoles"],
      lim: 10,
      roles: [],
      current: 1,
      pages: 0,
    };
  },
  created() {},
  computed: {
    orderedRoles: function () {
      return this._.orderBy(this.roles, "id");
    },
  },
  mounted() {
    this.getRoles(this.current);
  },
  components: {},
  methods: {
    async getRoles(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getRoles(page);
        if (response.data.success == true) {
          this.roles = response.data.data;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    remItem(litem) {
      if (confirm("Do you really want to delete?")) {
        this.remove(litem);
      }
    },
    async remove(it) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.deleteRole(it);
        if (response.data.success == true) {
          this.getRoles();
          //bt.classList.add("success");
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
