import MainLayout from '@/views/layouts/main'

import AccountPage from '@/views/customer/account/myaccount'

import DashboardPage from '@/views/customer/home/dashboard'
import BackgroundPage from '@/views/customer/home/background'

import PlanPage from '@/views/customer/plan/all'
import PlanShowPage from '@/views/customer/plan/show'

import SavePage from '@/views/customer/saved/all'
import ProgramPage from '@/views/customer/programs/all'
import ShowProgramPage from '@/views/customer/programs/show'

import RedeemPage from '@/views/customer/coupons/redeem'
import WeekPage from '@/views/customer/schedule/week'


export default {
  path:'/member/',
  component:MainLayout,
  meta:{requiresAuth:true},
  redirect: '/member/dashboard' ,
  children: [
    {
      path: 'dashboard',
      name: 'Dashboardm',
      meta:{layout: 'main',title: 'Dasboard'},
      component: DashboardPage,
    },
    {
      path: 'background',
      name: 'Background',
      meta:{layout: 'main',title: 'Background'},
      component: BackgroundPage,
    },
  
    {
      path: 'account/:typ',
      name: 'My Account',
      meta:{layout: 'main',title: 'My Account'},
      component: AccountPage,
    },
    {
      path: 'account',
      name: 'Account',
      meta:{layout: 'main',title: 'My Account'},
      component: AccountPage,
    },
    {
      path: 'plans',
      name: 'Plans',
      meta:{layout: 'main',title: 'Plans'},
      component: PlanPage,
    },
    {
      path: 'plans/:id',
      name: 'PlanShow',
      meta:{layout: 'main',title: 'Plans'},
      component: PlanShowPage,
    },
    {
      path: 'redeem',
      name: 'RedeemCoupon',
      meta:{layout: 'main',title: 'Redeem'},
      component: RedeemPage,
    },
    {
      path: 'favourites',
      name: 'Favs',
      meta:{layout: 'main',title: 'My Saved'},
      component: SavePage,
    },

    {
      path: 'programmes',
      name: 'Programs',
      meta:{layout: 'main',title: 'Program'},
      component: ProgramPage,
    },
    {
      path: 'programmes/:id',
      name: 'SPrograms',
      meta:{layout: 'main',title: 'Program'},
      component: ShowProgramPage,
    },
   

    {
      path: 'schedule',
      name: 'WeeklySchedule',
      meta:{layout: 'main',title: 'Weekly'},
      component: WeekPage,
    }
          
  ]
}
