<template>
  <div id="new_user" class="col-lg-12 mt-4 adacc">
    <div class="col-lg-12">
      <h3><b>Edit Programme</b></h3>
      <form class="" @submit.prevent="updateProgram">
        <div class="mt-4">
          <div class="row mb-3">
            <div class="col-md-8">
              <!-- <label>Programme Name </label> -->
              <input
                type="text"
                v-model="details.program.name"
                class="input-field form-control"
                placeholder="Name"
                required
              />
            </div>
          </div>

          <div class="row mt-3 d-none">
            <div class="col-lg-2 col-md-2">
              <label class="cap">Type</label>
            </div>
            <div class="col-lg-2 col-md-2">
              <input
                id="live"
                type="radio"
                v-model="details.program.program_type"
                name="progtype"
                class=""
                placeholder=""
                required
                value="live"
              />
              Live
              <!-- <label for="live">Live</label> -->
            </div>
            <div class="col-lg-2 col-md-2">
              <!-- <label>Payment Period Unit</label> -->
              <input
                id="arch"
                type="radio"
                v-model="details.program.program_type"
                name="progtype"
                class=""
                placeholder=""
                required
                value="archived"
              />
              Archive
              <!-- <label for="arch">Archive</label> -->
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-8">
              <!-- <label> Programme Category </label> -->
              <v-select
                :options="categories"
                :multiple="false"
                required
                v-model="details.program.program_category_id"
                placeholder="Category"
                :reduce="(name) => name.id"
                label="name"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!details.program.program_category_id"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </div>
          </div>
          <div class="my-3">
            <h4 class="renew d-inline">Published</h4>
            <label class="switch ml-4">
              <input
                type="checkbox"
                v-model="details.program.published"
                required
              />
              <span class="slider round"></span>
            </label>
          </div>

          <div class="row mt-3 mb-3">
            <div class="col-lg-8 col-md-8">
              <div class="uploadarea">
                <!-- <label>Thumbnail</label> -->
                <button type="button" class="form-btn m-0 imgbtn">
                  Choose Image
                </button>
                <input
                  id="primg"
                  type="file"
                  @change="getThumb"
                  ref="thumbupload"
                  accept="images*"
                  :required="
                    details.program.thumbnail.includes('base64') ? true : false
                  "
                />
                <img
                  class="ml-5 av"
                  v-if="details.program.thumbnail"
                  :src="details.program.thumbnail"
                />
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-8">
              <!-- <label>Description</label> -->
              <textarea
                rows="4"
                v-model="details.program.description"
                placeholder="Description"
                class="input-field form-control mt-0"
                required
              ></textarea>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-8">
              <!-- <label>Description</label> -->
              <v-select
                :options="hosts"
                :multiple="false"
                v-model="details.program.host"
                :reduce="(name) => name.id"
                label="name"
                placeholder="Host"
                class="prog"
                required
              />
            </div>
          </div>

          <router-link :to="'/admin/programmes/${this.id}'"><button class="form-btn mr-4 cancel">Cancel</button></router-link>

          <button id="saveUser" type="submit" class="form-btn">
            Update Programme
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";
import router from "@/router";

export default {
  name: "EditProgramPage",
  data() {
    return {
      details: {
        program: {
          code: null,
          days_valid: null,
          discount_value: null,
          discount_type: null,
          limit: 0,
          header_background: "",
          thumbnail: "",
          program_type: "archived",
        },
      },
      plans: [],
      categories: [],
      hosts: [],
      tags: [],
      id: "",
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  computed: {},
  mounted() {
    this.getTypes();
    this.getProgram();
    this.getHosts();
  },
  components: {},
  methods: {
    async getHosts() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getScHosts();
        if (response.data.success == true) {
          this.hosts = response.data.data;
          //  console.log(this.disable)
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getTypes() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.newPrograms();
        if (response.data.success == true) {
          this.plans = response.data.data.options.plans;
          this.categories = response.data.data.options.program_categories;
          this.instructors = response.data.data.options.instructors;
          this.tags = response.data.data.options.tags;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getProgram() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      //  try{
      //   const response = await AdminService.editProgram(this.id);
      //   if(response.data.success == true){
      //    this.details.program=response.data.data.program
      //   }
      //   else{
      //     //console.log("error")

      //   }
      // } catch (err){
      //   //console.log(err)
      // }

      try {
        const response = await AdminService.editProgram(this.id);
        if (response.data.success == true) {
          this.details.program = response.data.data.program;

          this.details.program.thumbnail =
            response.data.data.program.thumbnail.large;
          this.details.program.header_background =
            response.data.data.program.header_background.large;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async updateProgram() {
      var alert = {};
      if (this.details.program.thumbnail.includes("data:image") == false) {
        delete this.details.program.thumbnail;
      }
      if (
        this.details.program.header_background.includes("data:image") == false
      ) {
        delete this.details.program.header_background;
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        var bt = document.getElementById("saveUser");
        const response = await AdminService.updateProgram(
          this.id,
          this.details
        );
        if (response.data.success == true) {
          ////bt.classList.add("success");
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          // //bt.innerText = response.data.flash.notice
          setTimeout(function () {
            router.push({
              path: "/admin/programmes",
            });
          }, 500);
        } else {
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Update Programme";
          }, 1500);
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    getThumb(event) {
      const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 4) {
        alert("File size exceeds 4 MB");
        this.$refs.thumbupload.value = null;
      } else {
        const fr = new FileReader();
        fr.onloadend = function () {
          this.details.program.thumbnail = fr.result;
        }.bind(this);

        fr.readAsDataURL(event.target.files[0]);
      }
    },
    getHead(event) {
      const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 4) {
        alert("File size exceeds 4 MB");
        this.$refs.headupload.value = null;
      } else {
        const fr = new FileReader();
        fr.onloadend = function () {
          this.details.program.header_background = fr.result;
        }.bind(this);

        fr.readAsDataURL(event.target.files[0]);
      }
    },
  },
};
</script>
