<template>
  <div>
    <div class="table-list users-list mt-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0">
            <b>Users</b>
            <router-link
              v-if="can('user:create') || can('admin')"
              to="/admin/users/new"
              ><button class="form-btn ml-4">Add New</button></router-link
            >
          </h4>
        </div>

        <div class="col-lg-6 col-md-6 text-right rbtn">
          <!-- <a @click="getCSV()"><button class="form-btn">Export CSV</button></a>   -->
          <router-link to="/admin/roles" v-if="can('role:read') || can('admin')"
            ><button class="form-btn">Manage Roles</button></router-link
          >
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-3 filselect col-md-3 d-none">
          <v-select
            :options="roles"
            placeholder="Roles"
            :multiple="true"
            v-model="roles_sel"
            :reduce="(label) => label.id"
            label="label"
          />
        </div>
        <div class="col-lg-2 filselect col-md-3">
          <v-select
            :options="status"
            placeholder="Status"
            v-model="stat_sel"
            :reduce="(label) => label.value"
            label="name"
          />
        </div>
        <div class="col-lg-2 filselect col-md-3">
          <v-select
            :options="roles"
            placeholder="Role"
            :multiple="true"
            v-model="roles_sel"
            :reduce="(label) => label.id"
            label="label"
          />
        </div>
        <div class="col-lg-3 filinp col-md-3">
          <input type="text" placeholder="Name" v-model="sename" />
        </div>
        <div class="col-lg-3 filinp col-md-3">
          <input type="email" placeholder="Email" v-model="seemail" required />
        </div>

        <div class="col-lg-2 col-md-2">
          <button @click="getFilUsers(1)" class="form-btn mt-1">Search</button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2 filselect col-md-3">
          <v-select
            :options="sortstate"
            placeholder="Sort by"
            v-model="sortBy"
            :reduce="(label) => label.value"
            label="name"
            @input="sortUsers"
          />
        </div>
        <div class="col-lg-2 filselect col-md-3">
          <v-select
            :options="sortorder"
            placeholder="Order by"
            v-model="orderBy"
            :reduce="(label) => label.value"
            label="name"
            @input="sortUsers"
          />
        </div>
      </div>

      <div class="table-responsive-sm table-responsive-md">
        <table v-if="users.length > 0" class="table boxtable">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
              <th scope="col">Country</th>
              <th scope="col">Plan</th>
              <th scope="col">Sign Up Date</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              class="link_row"
              :to="
                can('user:edit') || can('admin')
                  ? { name: 'EditUser', params: { id: us.id } }
                  : '#'
              "
              v-for="(us, index) in users"
              :key="user + index"
            >
              <tr class="table-det spacer">
                <td>
                  <span>{{ us.firstname }} {{ us.lastname }}</span>
                </td>
                <td>
                  <span>{{ us.email }}</span>
                </td>
                <td>
                  <span
                    class="mr-2"
                    v-for="(rl, index) in us.roles"
                    :key="role + index"
                    >{{ rl.label }}</span
                  ><span v-if="us.roles.length == 0">Member</span>
                </td>
                <td>
                  <span>{{ us.country }}</span>
                </td>
                <td>
                  <span class="mr-2" v-if="us.plan">{{ us.plan.name }}</span>
                </td>
                <td>
                  <span>{{ moment(us.created_at).format("LL") }} </span>
                </td>
                <td>
                  <span>
                    <font-awesome-icon
                      v-if="(id != us.id && can('user:delete')) || can('admin')"
                      v-on:click.prevent="remItem(us.id)"
                      :icon="['fal', 'times']"
                  /></span>
                </td>
              </tr>
            </router-link>
          </tbody>
        </table>
        <h5 v-else class="mx-auto text-center p-3">No Users Available</h5>
      </div>
      <ul class="pl-0 paginate-links" v-if="pages > 1">
        <li
          v-if="pages > 0"
          @click="getFilUsers(1)"
          class="left-arrow disabled"
        >
          <a>«</a>
        </li>

        <li
          @click="getFilUsers(p)"
          :class="p == current ? 'number active pg' : 'number pg'"
          v-for="(p, index) in pages"
          :key="pag + index"
        >
          <span v-if="Math.abs(p - current) < 4 || p == pages || p == 1">
            <a
              :class="{
                current: current === p,
                last: p == pages && Math.abs(p - current) > 4,
                first: p == 1 && Math.abs(p - current) > 4,
              }"
              >{{ p }}</a
            >
          </span>
        </li>
        <li
          v-if="pages > 0"
          @click="getFilUsers(pages)"
          class="right-arrow disabled"
        >
          <a>»</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";
import { saveAs } from "file-saver";

export default {
  name: "UsersPage",
  data() {
    return {
      paginate: ["orderedUsers"],
      lim: 10,
      users: [],
      id: "",
      current: 1,
      pages: 0,
      roles: [],
      plans: [],
      roles_sel: [],
      plans_sel: [],
      stat_sel: "",
      sename: "",
      seemail: "",
      status: [
        {
          id: 0,
          name: "Active",
          value: "active",
        },
        {
          id: 1,
          name: "Disabled",
          value: "disabled",
        },
        {
          id: 2,
          name: "InActive",
          value: "inactive",
        },
      ],
      sortorder: [
        {
          id: 0,
          name: "Ascending",
          value: "ASC",
        },
        {
          id: 1,
          name: "Descending",
          value: "DESC",
        },
      ],
      sortstate: [
        {
          id: 0,
          name: "Name",
          value: "lastname",
        },
        {
          id: 1,
          name: "Country",
          value: "country",
        },
        {
          id: 2,
          name: "Plan",
          value: "plan",
        },
        {
          id: 3,
          name: "Date",
          value: "created_at",
        },
      ],
      sortBy: "",
      orderBy: "ASC",
      numOfcount: 1,
    };
  },
  created() {
    //this.id=this.$cookies.get("user").id
  },
  computed: {
    /*    sortedHeader() {
                                                                                                                let user = this.users;
                                                                                                                return user.sort(this.headerSort);
                                                                                                            }, */
    orderedUsers: function () {
      return this._.orderBy(this.users, "id");
    },
  },
  mounted() {
    this.getFilUsers(this.current);
    this.getRoles();
    // this.getPlans()
  },
  updated() {
    // this.getFilUsers(this.current)
  },
  components: {},
  methods: {
    async getCSV() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        var fil = "";
        for (var i = 0; i < this.roles_sel.length; i++) {
          fil += "&role_ids[]=" + this.roles_sel[i];
        }
        for (var x = 0; x < this.plans_sel.length; x++) {
          fil += "&plan_id=" + this.plans_sel[x];
        }
        fil += "&name=" + this.sename;
        fil += "&email=" + this.seemail;

        fil = fil.replace("&", "");

        const response = await AdminService.getUserCSV(fil);
        var users = response.data;

        var file = new File([users], {
          type: "text/plain;charset=utf-8",
        });
        // FileSaver.saveAs(file);
        saveAs(file, "Users.csv");
      } catch (err) {
        //console.log(err)
      }
    },
    async getRoles(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getRoles(page);
        if (response.data.success == true) {
          this.roles = response.data.data;
          this.roles[2] = {
            id: [],
            label: "Subscriber",
          };
          //      this.pages=   response.data.pagination.total_pages
          // this.current = response.data.pagination.page
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getPlans(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getPlans(page);
        if (response.data.success == true) {
          this.plans = response.data.data;
          // this.pages=   response.data.pagination.total_pages
          // this.current = response.data.pagination.page
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getFilUsers(page) {
      //console.log(page)
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      var fil = "";
      for (var i = 0; i < this.roles_sel.length; i++) {
        fil += "&role_ids[]=" + this.roles_sel[i];
      }
      for (var x = 0; x < this.plans_sel.length; x++) {
        fil += "&plan_id=" + this.plans_sel[x];
      }
      fil += "&active_status=" + this.stat_sel;
      fil += "&name=" + this.sename;
      fil += "&email=" + this.seemail;
      try {
        const response = await AdminService.getFilUsers(
          page,
          fil,
          this.sortBy,
          this.orderBy
        );
        if (response.data.success == true) {
          //console.log(response.data.data)
          this.users = response.data.data;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getUsers(page) {
      //console.log(page)
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getUsers(
          page,
          this.sortBy,
          this.orderBy
        );
        if (response.data.success == true) {
          //this.users = response.data.data;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;

          for (var j = 0; j < response.data.data.length; j++) {
            this.users.push(response.data.data[j]);
            this.counter++;
          }

          if (this.numOfcount != response.data.pagination.total_pages) {
            if (response.data.pagination.total_pages > 1) {
              this.numOfcount++;
              this.getUsers(this.numOfcount);
            }
          }
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    remItem(litem) {
      if (confirm("Do you really want to delete?")) {
        this.remove(litem);
      }
    },
    async remove(it) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.deleteUser(it);
        if (response.data.success == true) {
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          this.getFilUsers(this.current);
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },

    async sortUsers() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getUsers(
          this.current,
          this.sortBy,
          this.orderBy
        );
        if (response.data.success == true) {
          //this.users = response.data.data;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
          this.users = response.data.data;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
