<template>
  <div class="main-det-prShow" v-bind:style="{ background: activeBackground }">
    <div class="mt-0 mb-5 progep browse">
      <div class="bkwh table-list dark mt-0">
        <div class="row mb-4">
          <div class="col-sm-5">
            <img
              class="proimg"
              v-if="details.program.thumbnail"
              :src="details.program.thumbnail"
            />
          </div>
          <div class="col-sm-7 favDetails">
            <p class="cap pri" v-if="details.program.bookmarked == false">
              Add to Favourites
              <font-awesome-icon
                v-if="can('favourite:edit') || can('vip')"
                class="float-right"
                v-on:click.prevent="addBook(details.program.id)"
                :icon="['far', 'heart']"
              />
              <font-awesome-icon
                v-else
                class="float-right"
                v-on:click="upgrade()"
                :icon="['far', 'heart']"
              />
            </p>
            <p class="cap pri" v-else>
              Remove from Favourites
              <font-awesome-icon
                v-if="can('favourite:edit') || can('vip')"
                class="float-right"
                v-on:click.prevent="removeBook(details.program.id)"
                :icon="['fas', 'heart']"
              />
              <font-awesome-icon
                v-else
                class="float-right"
                v-on:click="upgrade()"
                :icon="['fas', 'heart']"
              />
            </p>
            <h3 class="pName">{{ details.program.name }}</h3>
            <p class="proho mb-4" v-if="details.program.host">
              {{ details.program.host.name }}
            </p>

            <div class="row mt-3">
              <div class="col-sm-6">
                <h6 class="d-inline mr-5">{{ episodes.length }} Episodes</h6>
              </div>
              <div class="col-sm-6">
                <span v-if="details.program.category">{{
                  details.program.category.name
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive-sm browse">
          <table v-if="episodes.length > 0" class="table boxtable">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Host</th>
                <th scope="col">Description</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sess, index) in episodes" :key="sess + index">
                <td>
                  <span>{{ sess.title }}</span>
                </td>
                <td>
                  <span>{{ sess.host }}</span>
                </td>
                <td>
                  <span>{{ sess.description }}</span>
                </td>
                <td class="pb-0 epplyr" mv-if="sess.audio_file_url">
                  <div class="wrapper">
                    <vue-plyr
                      :emit="['pause', 'playing']"
                      @pause="onPause"
                      @playing="onPlay"
                    >
                      <audio class="epAudio" controls>
                        <source :src="sess.audio_file_url" type="audio/mp3" />
                      </audio>
                    </vue-plyr>

                    <!-- <div v-else>
                      <span v-on:click="Noep()" style="display: inline">
                        <i
                          style="color: white; margin: 0px"
                          class="fas fa-play"
                        ></i>
                      </span>
                    </div> -->
                  </div>
                  <!--<button type="button" class="playEpisode btn-link " @click="playEp(index)" ><i class="playIcon fas fa-play "></i> </button>-->
                </td>
              </tr>
            </tbody>
          </table>
          <h6 v-else class="text-center pb-5 pt-5">No Episodes Available</h6>
        </div>
        <ul class="pl-0 paginate-links prog" v-if="pages > 1">
          <li
            v-if="pages > 0"
            @click="getSession(1)"
            class="left-arrow disabled"
          >
            <a>«</a>
          </li>

          <li
            @click="getSession(p)"
            :class="p == current ? 'number active pg' : 'number pg'"
            v-for="(p, index) in pages"
            :key="pag + index"
          >
            <span v-if="Math.abs(p - current) < 4 || p == pages || p == 1">
              <a
                :class="{
                  current: current === p,
                  last: p == pages && Math.abs(p - current) > 4,
                  first: p == 1 && Math.abs(p - current) > 4,
                }"
                >{{ p }}</a
              >
            </span>
          </li>
          <li
            v-if="pages > 0"
            @click="getSession(pages)"
            class="right-arrow disabled"
          >
            <a>»</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../../../services/userService";
import axios from "axios";
import router from "@/router";
// import $ from "jquery";

export default {
  name: "ShowProgramPage",
  data() {
    return {
      details: {
        program: {
          code: null,
          days_valid: null,
          discount_value: null,
          discount_type: null,
          plan_ids: [],
          limit: 0,
          tag_ids: [],
          header_background: "",
          thumbnail: "",
        },
      },
      episodes: {},
      activeBackground: "",
      id: "",
      paginate: ["episodes"],
      lim: 10,
      current: 1,
      pages: 0,
      options: {
        autopause: "true",
      },
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  mounted() {
    this.checkBackground();
    // this.plan = this.$cookies.get("user");
    // this.plan = this.plan.current_subscription;
    if (this.$cookies.get("subscription") == "false") {
      // this.$cookies.set("subscription", false);
      router.push({
        path: "/member/plans",
      });
    } else {
      this.getProgram();
      this.getSession(this.current);
    }
  },
  components: {},
  methods: {
    upgrade() {
      this.$bus.$emit("upgrade");
    },
    onPlay(data) {
      console.log("Video is playing", data);
    },
    onPause(data) {
      console.log("Video is paused", data);
    },
    Noep() {
      this.$bus.$emit("upgrade");
    },
    // playEp(ep) {
    //     var audio = new Audio(ep);
    //     audio.play();

    //     if ($(this).hasClass("fa-play")) {
    //         $(this).removeClass("fa-play");
    //         $(this).addClass("fa-pause");
    //         audio.play();
    //     } else {
    //         $(this).removeClass("fa-pause");
    //         $(this).addClass("fa-play");
    //         audio.pause();
    //     }

    //     audio.onended = function() {
    //         $(".play-pause-button").removeClass("fa-pause");
    //         $(".play-pause-button").addClass("fa-play");
    //     };
    // },

    async getProgram() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");

      try {
        const response = await UserService.showProgram(this.id);
        if (response.data.success == true) {
          //console.log(response.data.data)
          this.details.program = response.data.data.program;

          this.details.program.thumbnail =
            response.data.data.program.thumbnail_urls.large;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getSession(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getSession(this.id, page);

        if (response.data.success == true) {
          // alert("hi")

          this.episodes = response.data.data;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
          this.player = this.$refs.plyr.player;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async addBook(bid) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.saveProgram(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getProgram();
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          //console.log("error");
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async removeBook(bid) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.removeProgram(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getProgram();
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          //console.log("error");
          alert = {
            text: response.data.errors[0],
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async checkBackground() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getCurrentBackground();
        if (response.data.success == true) {
          //this.background = response.data.data.thumbnail
          if (
            response.data.data.thumbnail != null &&
            response.data.data.thumbnail != "undefined"
          ) {
            this.activeBackground = "#000000b5";
          } else {
            this.activeBackground = "";
          }
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
