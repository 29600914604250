<template>
  <div>
    <div class="table-list users-list mt-4 mb-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0">
            <b>Plans</b>
            <router-link
              v-if="can('plan:create') || can('admin')"
              to="/admin/plans/new"
              ><button class="form-btn ml-4">Add New</button></router-link
            >
          </h4>
        </div>
      </div>

      <div class="table-responsive-sm">
        <table class="table boxtable" v-if="plans.length > 0">
          <thead>
            <tr>
              <th scope="col">Plan</th>
              <th scope="col"># of Users</th>
              <th scope="col">Cost</th>
              <!-- <th scope="col">Trial Subscribers</th> -->
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              class="link_row"
              :to="
                can('plan:edit') || can('admin')
                  ? { name: 'EditPlans', params: { id: plan.id } }
                  : '#'
              "
              v-for="(plan, index) in plans"
              :key="plan + index"
            >
              <tr class="table-det spacer">
                <td>
                  <span>{{ plan.name }}</span>
                </td>
                <td>
                  <span>{{ plan.active_subscribers_count }}</span>
                </td>
                <td>
                  <span>${{ plan.price }}</span>
                </td>
                <!-- <td>{{plan.trial_subscribers_count}}</td> -->
                <td>
                  <span v-if="plan.published == true">Active </span>
                  <span v-else>Inactive </span>
                </td>
                <td>
                  <span>
                    <font-awesome-icon
                      v-if="
                        (can('plan:delete') || can('admin')) && plan.id != 48
                      "
                      v-on:click.prevent="remItem(plan.id)"
                      :icon="['fal', 'times']"
                  /></span>
                </td>
              </tr>
            </router-link>
          </tbody>
        </table>

        <h5 v-else class="mx-auto text-center p-3">No Plans Available</h5>
      </div>
      <ul class="pl-0 paginate-links" v-if="pages > 1">
        <li v-if="pages > 0" @click="getPlans(1)" class="left-arrow disabled">
          <a>«</a>
        </li>

        <li
          @click="getPlans(p)"
          :class="p == current ? 'number active pg' : 'number pg'"
          v-for="(p, index) in pages"
          :key="pag + index"
        >
          <span v-if="Math.abs(p - current) < 4 || p == pages || p == 1">
            <a
              :class="{
                current: current === p,
                last: p == pages && Math.abs(p - current) > 4,
                first: p == 1 && Math.abs(p - current) > 4,
              }"
              >{{ p }}</a
            >
          </span>
        </li>
        <li
          v-if="pages > 0"
          @click="getPlans(pages)"
          class="right-arrow disabled"
        >
          <a>»</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";

export default {
  name: "PlansPage",
  data() {
    return {
      paginate: ["plans"],
      lim: 10,
      plans: [],
      current: 1,
      pages: 0,
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getPlans(this.current);
  },
  components: {},
  methods: {
    async getPlans(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getPlans(page);
        if (response.data.success == true) {
          this.plans = response.data.data;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
        this.$emit("myEvent");
      }
    },
    remItem(litem) {
      if (confirm("Do you really want to delete?")) {
        this.remove(litem);
      }
    },
    async remove(it) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.deletePlan(it);
        if (response.data.success == true) {
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          this.getPlans();
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
