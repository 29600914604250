<template>
  <div class="main-det" :class="activeBackground">
    <h3 v-if="prog.length > 0" class="cap allP">All Programmes</h3>
    <div v-if="prog.length > 0" class="workouts header pb-5">
      <div class="pRow">
        <router-link
          class="pColumn htod"
          :to="{ name: 'SPrograms', params: { id: ins.id } }"
          v-for="(ins, index) in prog"
          :key="'category' + index"
        >
          <div class="pCard">
            <div class="workout">
              <img
                class="m-0 card-img-top"
                :src="
                  ins.thumbnail_urls != null ? ins.thumbnail_urls.large : ''
                "
              />
              <!--<i class="fas fa-play-circle"></i>-->
              <div class="detailsrow pr-details">
                <div class="detailsColumn1">
                  <p class="pn">{{ ins.name }}</p>
                  <p class="ph" v-if="ins.host">{{ ins.host.name }}</p>
                </div>
                <div class="detailsColumn2">
                  <p class="pHeartIcon" v-if="ins.bookmarked == false">
                    <font-awesome-icon
                      v-if="can('favourite:edit') || can('vip')"
                      v-on:click.prevent="addVideo(ins.id)"
                      :icon="['far', 'heart']"
                      size="lg"
                    />
                    <font-awesome-icon
                      v-else
                      v-on:click.prevent="upgrade()"
                      :icon="['far', 'heart']"
                      size="lg"
                    />
                  </p>
                  <p class="pHeartIcon" v-else>
                    <font-awesome-icon
                      v-if="can('favourite:edit') || can('vip')"
                      v-on:click.prevent="removeVideo(ins.id)"
                      :icon="['fas', 'heart']"
                      size="lg"
                    />
                    <font-awesome-icon
                      v-else
                      v-on:click.prevent="upgrade()"
                      :icon="['fas', 'heart']"
                      size="lg"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <ul class="pl-0 paginate-links prog" v-if="pages > 1">
      <li v-if="pages > 0" @click="getProg(1)" class="left-arrow disabled">
        <a>«</a>
      </li>

      <li
        @click="getProg(p)"
        :class="p == current ? 'number active pg' : 'number pg'"
        v-for="(p, index) in pages"
        :key="pag + index"
      >
        <span v-if="Math.abs(p - current) < 4 || p == pages || p == 1">
          <a
            :class="{
              current: current === p,
              last: p == pages && Math.abs(p - current) > 4,
              first: p == 1 && Math.abs(p - current) > 4,
            }"
            >{{ p }}</a
          >
        </span>
      </li>
      <li v-if="pages > 0" @click="getProg(pages)" class="right-arrow disabled">
        <a>»</a>
      </li>
    </ul>
  </div>
</template>

<script>
// import custom from '../../javascript/custom'
import UserService from "../../../services/userService";
import axios from "axios";
import router from "@/router";

export default {
  name: "ProgramPage",
  data() {
    return {
      page: "",
      sB: 0,
      items: [],
      presenter: {},
      id: "",
      plan: {},
      mid: "",
      prog: [],
      ads: [],
      timer: 0,
      width: 100,
      activeBackground: "",
      paginate: ["episodes"],
      lim: 20,
      current: 1,
      pages: 0,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.play();
    //this.mid = this.$cookies.get("user").id;
  },
  mounted() {
    // this.getProfile();
    this.checkBackground();
    // this.plan = this.$cookies.get("user");
    // this.plan = this.plan.current_subscription;
    if (this.$cookies.get("subscription") == "false") {
      // this.$cookies.set("subscription", false);
      router.push({
        path: "/member/plans",
      });
    } else {
      this.getProg(this.current);
    }
  },
  computed: {},
  components: {},
  methods: {
    nextSlide: function () {
      this.current++;
      if (this.current >= this.ads.length) this.current = 0;
      this.resetPlay();
    },
    prevSlide: function () {
      this.current--;
      if (this.current < 0) this.current = this.ads.length - 1;
      this.resetPlay();
    },
    selectSlide: function (i) {
      this.current = i;
      this.resetPlay();
    },
    resetPlay: function () {
      clearInterval(this.timer);
      this.play();
    },
    play: function () {
      let app = this;
      this.timer = setInterval(function () {
        app.nextSlide();
      }, 7000);
    },
    upgrade() {
      this.$bus.$emit("upgrade");
    },

    async getProg(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getPrograms(page);
        if (response.data.success == true) {
          // console.log("prog")
          this.prog = response.data.data;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
          // console.log(this.prog)
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },

    async addBook(bid) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.saveProgram(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getProg();
          // console.log("add")
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          //console.log("error");
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async removeBook(bid) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.removeProgram(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getProg();
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          //console.log("error");
          alert = {
            text: response.data.errors[0],
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async checkBackground() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getCurrentBackground();
        if (response.data.success == true) {
          //this.background = response.data.data.thumbnail
          // console.log(response.data.data.thumbnail)
          if (
            response.data.data.thumbnail != null &&
            response.data.data.thumbnail != "undefined"
          ) {
            this.activeBackground = " otherBackground";
          } else {
            this.activeBackground = "";
          }
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
