<template>
  <div id="sess-layout" class="">
    <div
      v-if="salert"
      class="alert wal alert-dismissible fade show"
      :class="alert.type"
      role="alert"
      :style="alert.type ? 'display:block;position:fixed ' : 'display:none'"
    >
      <div id="alert">
        <p class="alert-heading m-0">
          <strong>{{ alert.head }}</strong>
          <span class="alert-text ml-2">{{ alert.text }}</span>
        </p>
      </div>
    </div>
    <div class="sesspg">
      <div class="sesslay">
        <nav class="navbar navbar-expand-lg main-nav">
          <div class="navIcon">
            <router-link
              class="navbar-brand ml-0"
              to="/"
              @click.native="page = ''"
              ><img class="dash-logo" src="../../assets/irie-logo@2x.png"
            /></router-link>
          </div>
          <div class="navBtn">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"
                ><i class="fas fa-bars fa-lg"></i
              ></span>
            </button>
          </div>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div class="navbar-nav nav">
              <router-link :to="{ name: 'Login' }">Login</router-link>
              <router-link :to="{ name: 'Signup' }">Sign Up</router-link>
            </div>
          </div>
        </nav>
        <div class="sess-det">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "SessionPage",
        data() {
            return {
                alert: {
                    text: "",
                    head: "",
                    type: "",
                },
                window: {
                    width: 0,
                    height: 0,
                },
                salert: false,
            };
        },
        created() {},
        computed: {},
        mounted() {
            this.$bus.$on("flash", (data) => {
                this.alert.text = data.text;
                if (
                    this.alert.text != "" ||
                    (this.alert.text[0] != "" && !Array.isArray(this.alert.text[0]))
                ) {
                    // console.log( this.alert.text)
                    this.alert.text = this.alert.text[0];
                    // console.log( this.alert.text)
                } else if (this.alert.text == []) {
                    this.alert.text = "";
                } else if (Array.isArray(this.alert.text[0])) {
                    this.alert.text = this.alert.text[0][0];
                    // console.log( 'b',this.alert.text)
                }
                this.alert.head = data.head;
                this.alert.type = data.type;
                this.salert = true;
                setTimeout(
                    function() {
                        if (document.getElementById("alert") != null) {
                            document.getElementById("alert").classList.remove("d-block");
                        }
                        this.removeAl();
                    }.bind(this),
                    5000
                );
            });
        },
        components: {},
        methods: {
            removeAl() {
                this.salert = false;
                delete this.alert.type;
            },
        },
    };
</script>