<template>
  <div>
    <div class="offset-md-3">
      <div class="subscription-plan mb-5">
        <div v-if="plan != null">
          <div class="col-lg-12 p-0 mb-2">
            <div class="row">
              <div class="col-lg-5">
                <h3 class="mb-0">Current Plan</h3>
              </div>
              <div class="col-lg-7 text-right">
                <p v-if="plan.auto_renew == true" class="mb-0">
                  Renews: {{ moment(plan.expire_at).format("LL") }}
                </p>
                <p v-else class="mb-0">
                  Expires: {{ moment(plan.expire_at).format("LL") }}
                </p>
              </div>
            </div>
          </div>
          <div class="plan col-lg-12">
            <div class="row">
              <div class="col-lg-6">
                <h3 class="mb-2 plnam">{{ plan.plan_name }}</h3>
                <h4>
                  ${{ plan.price
                  }}<span
                    >/
                    <span v-if="plan.subscription_period_amount > 1">{{
                      plan.subscription_period_amount
                    }}</span>
                    {{ plan.subscription_period_unit }}</span
                  >
                </h4>
              </div>
              <div class="col-lg-6">
                <p>{{ plan.plan_description }}</p>
              </div>
            </div>
          </div>

          <div v-if="plan && plan.price > 0" class="subb d-none">
            <div class="row ml-0">
              <div class="col-lg-4 col-md-4 col-sm-4 pl-0">
                <button type="button" class="form-btn m-0">
                  Manage Billing
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="viewPlans">
          <h2><b>Choose A Plan</b></h2>
          <router-link to="/member/plans">
            <button class="vplans">view plans</button>
          </router-link>
        </div>
        <div class="mb-3 mt-5" v-if="plan != null">
          <h4 class="renew d-inline">Auto Renew</h4>
          <label class="switch ml-3">
            <input
              type="checkbox"
              v-model="plan.auto_renew"
              @change="renewPlan()"
            />
            <span class="slider round"></span>
          </label>
        </div>

        <div class="sub-actions" v-if="plan != null">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
              <p
                :class="
                  plan && plan.auto_renew != true && plan != null
                    ? 'splan'
                    : 'splan rplan'
                "
                @click="setPay(plan.plan_id)"
              >
                Renew Plan
              </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <p class="cplan">
                <router-link to="/member/plans">Change Plan</router-link>
              </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <p
                :class="
                  plan && plan.active != false && plan != null
                    ? 'xplan'
                    : 'xplan rplan'
                "
                @click="plan ? (cancel = true) : (cancel = false)"
              >
                Cancel Plan
              </p>
            </div>
          </div>
        </div>
        <div
          class="mt-5"
          v-if="cancel == true && plan && plan.active != false && plan != null"
        >
          <h3 class="text-center">
            Are you sure you want to cancel this plan?
          </h3>
          <div class="row mt-3">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <button class="cbtn-y" @click="removeSub()">Yes</button>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <button class="cbtn-n" @click="cancel = false">No</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import custom from "../../../javascript/custom";
import router from "@/router";
import UserService from "../../../services/userService";
import axios from "axios";
import $ from "jquery";

export default {
  name: "SubscriptionsPage",
  data() {
    return {
      plan: null,
      cancel: false,
      autorenew: true,
      hasplan: false,
      merch: "",
    };
  },
  created() {
    // this.id = this.$cookies.get("user").id;
  },
  computed: {},
  mounted() {
    // this.plan = this.$cookies.get("user");
    // this.plan = this.plan.current_subscription;
    this.getProfile();
  },
  components: {},
  methods: {
    async renewPlan() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.updateSubAuto(this.plan);
        if (response.data.success == true) {
          this.getProfile();
          this.plan.auto_renew = response.data.data.subscription.auto_renew;

          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          //console.log("error")
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    setPay(pid) {
      // this.$bus.$emit("renew", true);
      router.push({
        path: "/member/plans/" + pid,
      });
    },
    async removeSub() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.removeSubscription();
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getProfile();
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          $(".cbtn-n").click();
          this.cancel = false;
        } else {
          //console.log("error")
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
          $(".cbtn-n").click();
          this.cancel = false;
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getProfile() {
      // var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      // alert = {
      //     text: "",
      //     head: al,
      //     type: "alert-success d-block",
      // };
      this.$bus.$emit("flash", alert);
      try {
        const response = await UserService.getProfile(this.user);
        if (response.data.success == true) {
          this.plan = response.data.data.profile.current_subscription;
          // this.$cookies.set("user", response.data.data.profile);
          var pp = response.data.data.profile.current_subscription;
          if (pp != null) {
            this.$cookies.set("subscription", true);
          } else {
            this.$cookies.set("subscription", false);
          }
          this.$bus.$emit("subUp");
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
