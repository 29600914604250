<template>
  <div id="new_user" class="col-lg-12 mt-4 adacc">
    <div class="col-lg-12">
      <h3><b>Edit User</b></h3>
      <form class="" @submit.prevent="createUser">
        <div class="mt-5">
          <div class="row mb-3">
            <div class="col-lg-8 col-md-8">
              <!-- <label>Role</label> -->
              <v-select
                :options="roles"
                placeholder="Role"
                :multiple="true"
                v-model="details.user.roles"
                :reduce="(name) => name.id"
                label="label"
                :selectable="() => details.user.roles.length < 1"
              />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-4 col-md-8">
              <!-- <label>First Name *</label> -->
              <input
                type="text"
                v-model="details.user.firstname"
                class="input-field form-control"
                placeholder="First Name"
                required
              />
            </div>
            <div class="col-lg-4 col-md-8">
              <!-- <label>Last Name *</label> -->
              <input
                type="text"
                v-model="details.user.lastname"
                class="input-field form-control"
                placeholder="Last Name"
                required
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-4">
              <!-- <label>Email Address *</label> -->
              <input
                type="email"
                v-model="details.user.email"
                class="input-field form-control"
                placeholder="Email Address"
                required
              />
            </div>
            <div class="col-lg-4">
              <!-- <label>Email Address *</label> -->
              <input
                type="tel"
                v-model="details.user.phone"
                class="input-field form-control"
                v-mask="'#(###)###-####'"
                masked="true"
                placeholder="Phone Number"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-8 col-md-8">
              <!-- <label>Role</label> -->
              <v-select
                :options="coun"
                placeholder="Country"
                :multiple="false"
                v-model="details.user.country"
                :reduce="(name) => name.code"
                label="name"
              />
            </div>
          </div>

          <div v-if="plans && details.user.roles.length == 0" class="row mb-5">
            <div class="col-sm-8">
              <div class="gbx">
                <p v-if="plans.length == 0">No Available Plans</p>
                <div class="row">
                  <div
                    class="col-sm-4 pl_box"
                    v-for="(plan, index) in plans"
                    :key="plan + index"
                  >
                    <div
                      v-if="details.user.plan"
                      :class="[
                        (details.user.plan.id === plan.id && pl == '') ||
                        plan.id === pl
                          ? 'plbx'
                          : '',
                      ]"
                      @click="selBx(index, plan.id)"
                    >
                      <h3>{{ plan.name }}</h3>
                      <p class="pri">${{ plan.price }}<span>/month</span></p>
                    </div>
                    <div
                      v-else
                      :class="[index === bx ? 'plbx' : '']"
                      @click="selBx(index, plan.id)"
                    >
                      <h3>{{ plan.name }}</h3>
                      <p class="pri">${{ plan.price }}<span>/month</span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="my-3">
            <h4 class="renew d-inline">Disabled</h4>
            <label class="switch ml-4">
              <input type="checkbox" v-model="details.user.disabled" />
              <span class="slider round"></span>
            </label>
          </div>
          <label
            @click="resetpass = !resetpass"
            class="col-lg-12 pl-0 cap pri rest"
            >Reset Password</label
          >
          <br />
          <div v-if="resetpass" class="row mt-3">
            <p class="mt-2 passp col-lg-8 col-md-8">
              Your password must contain at least 8 characters, including a
              minimum of one lowercase letter, an uppercase letter, a unique
              character and a number.
            </p>

            <div class="col-lg-8 col-md-8">
              <label>New Password</label>
              <input
                :type="passwordFieldType"
                v-on:keyup="validate"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&()*+,-./:;<=>@[\]^_`{|}~\x22\x27])[A-Za-z\d!#$%&()*+,-./:;<=>@[\]^_`{|}~\x22\x27]{8,}$"
                title="Your password must contain at least 8 characters, including a minimum of one lowercase letter, an uppercase letter, a unique character and a number."
                v-on:keydown="validate"
                v-model="details.user.password"
                class="input-field form-control"
                placeholder=""
                minlength="8"
              />
              <span type="password" @click="switchVisibility"
                ><i class="far fa-eye"></i
              ></span>
            </div>
            <div class="col-lg-8 col-md-8">
              <label>Confirm New Password</label>
              <input
                :type="confirmpasswordFieldType"
                v-on:keyup="validate"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&()*+,-./:;<=>@[\]^_`{|}~\x22\x27])[A-Za-z\d!#$%&()*+,-./:;<=>@[\]^_`{|}~\x22\x27]{8,}$"
                title="Your password must contain at least 8 characters, including a minimum of one lowercase letter, an uppercase letter, a unique character and a number."
                v-on:keydown="validate"
                v-model="details.user.password_confirmation"
                class="input-field form-control"
                placeholder=""
                minlength="8"
              />
              <span type="password" @click="switchVisibilityconfirm"
                ><i class="far fa-eye"></i
              ></span>
            </div>
            <span class="col-lg-12 mb-3 form-helper nomatch">
              Password must match the one typed before.
            </span>
          </div>
          <!-- <div class="row">
          <div class="col-lg-8 col-md-12">
             <label>Date of Birth</label>
          <vue-dob-picker  select-class="dob_select" class="input-field form-control mt-0" :placeholders="['Day', 'Month', 'Year']" v-model="details.user.date_of_birth" show-labels="false" month-format="long"></vue-dob-picker>
          </div>
         
        </div> -->
          <router-link to="/admin/users"
            ><button class="form-btn mr-4 cancel">Cancel</button></router-link
          >

          <button id="saveUser" type="submit" class="form-btn">
            Update User
          </button>
        </div>
      </form>
    </div>
    <!-- Modal -->
  </div>
</template>

<script>
import custom from "../../../javascript/custom";
import AdminService from "../../../services/adminService";
import axios from "axios";
import router from "@/router";
import countries from "../../../javascript/countries";

export default {
  name: "EditUserPage",
  data() {
    return {
      details: {
        user: {
          password: "",
          email: "",
          phone: "",
          role_ids: [],
          firstname: "",
          lastname: "",
          date_of_birth: "",
          active: false,
          password_confirmation: "",
          avatar: "",
          ordering: "",
          roles: [],
          country: "",
        },
      },
      req: true,
      confirm_password: "",
      passwordFieldType: "password",
      confirmpasswordFieldType: "password",
      roles: [],
      resetpass: false,
      plans: [],
      current: 1,
      bx: 0,
      pl: "",
      id: "",
      coun: countries,
      disable: {
        user: {
          login: "",
        },
      },
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.id = this.$route.params.id;
    this.getRoles();
    this.getUser();
    this.getPlans(this.current);
  },
  components: {},
  methods: {
    selBx(i, p) {
      // console.log(i, p, this.bx);
      if (this.bx == i) {
        this.bx = null;
        this.pl = "";
        // console.log(this.bx, this.pl);
      } else {
        this.bx = i;
        this.pl = p;
        // console.log(this.bx, this.pl);
      }
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      if (this.passwordFieldType == "text") {
        custom.showPassword(0);
      } else {
        custom.hidePassword(0);
      }
    },
    switchVisibilityconfirm() {
      this.confirmpasswordFieldType =
        this.confirmpasswordFieldType === "password" ? "text" : "password";
      if (this.confirmpasswordFieldType == "text") {
        custom.showPassword(1);
      } else {
        custom.hidePassword(1);
      }
    },
    validate() {
      if (this.details.user.password != "") {
        if (
          this.details.user.password != this.details.user.password_confirmation
        ) {
          custom.validatePassword(false);
        } else {
          custom.validatePassword(true);
        }
      }
    },
    async getPlans(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getZPlans(page);
        if (response.data.success == true) {
          this.plans = response.data.data.records;
          this.current = response.data.pagination.page;
          if (this.plans.length > 0) {
            this.bx = 0;
          }
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },

    async getRoles() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getRoles();
        if (response.data.success == true) {
          this.roles = response.data.data;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getUser() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.showUser(this.id);
        if (response.data.success == true) {
          this.details.user = response.data.data.user;
          //  console.log(this.disable)
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async createUser() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      if (this.details.user.phone != null && this.details.user.phone != "") {
        this.details.user.phone = this.details.user.phone.replace(
          /[- )(]/g,
          ""
        );
      }
      if (this.pl != "") {
        try {
          const response1 = await AdminService.newUserSub(this.id, this.pl);
          if (response1.data.success == true) {
            alert = {
              text: "",
              head: response1.data.flash.notice,
              type: "alert-success d-block",
            };
            this.$bus.$emit("flash", alert);
            setTimeout(function () {
              router.push({
                path: "/admin/users",
              });
            }, 500);
            //bt.innerText = response.data.flash.notice
            // setTimeout(function () {
            //   // this.saveUser();
            // }, 500);
            // this.getUser();
          } else {
            //console.log("error")
          }
        } catch (err) {
          //console.log(err)
          // console.log(this.disable)
        }
      } else if (this.details.user.disabled == true) {
        try {
          const response2 = await AdminService.disableUser(this.disable);
          if (response2.data.success == true) {
            // this.getUser();
          }
        } catch (err) {
          //console.log(err)
          // console.log(this.disable)
        }
      }
      try {
        var bt = document.getElementById("saveUser");

        const response = await AdminService.updateUser(this.id, this.details);

        if (response.data.success == true) {
          //bt.classList.add("success");
          //console.log(response.data)
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);

          setTimeout(function () {
            router.push({
              path: "/admin/users",
            });
          }, 500);
          //bt.innerText = response.data.flash.notice

          // if (this.pl != "") {
          //   try {
          //     const response = await AdminService.newUserSub(
          //       response.data.data.user.id,
          //       this.pl
          //     );
          //     if (response.data.success == true) {
          //       alert = {
          //         text: "",
          //         head: response.data.flash.notice,
          //         type: "alert-success d-block",
          //       };
          //       this.$bus.$emit("flash", alert);
          //       //bt.innerText = response.data.flash.notice
          //       setTimeout(function () {
          //         router.push({ path: "/admin/users" });
          //       }, 500);
          //       // this.getUser();
          //     } else {
          //       //console.log("error")
          //     }
          //   } catch (err) {
          //     //console.log(err)
          //     // console.log(this.disable)
          //   }
          // } else {
          //   router.push({ path: "/admin/users" });
          // }
        } else {
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Update User";
          }, 1500);
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
