<template>
  <div class="offset-md-3">
    <div class="billing-list table-list">
      <div v-if="langs.length > 0">
        <!-- <paginate
        name="languages"
        :list="langs"
        :per="lim"
        class="pl-0"
      > -->
        <div class="table-responsive-sm">
          <table class="table boxtable">
            <thead>
              <tr>
                <th scope="col">Plan</th>
                <th scope="col">Date</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="table-det spacer"
                v-for="(lang, index) in langs"
                :key="bill + index"
              >
                <td class="plna">
                  <span class="plnd">{{ lang.plan_name }}</span>
                </td>
                <td>
                  <span class="plnd">{{
                    moment(lang.paid_at).format("LL")
                  }}</span>
                </td>
                <td>
                  <span>${{ lang.amount | numFormat }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- </paginate> -->
        <!-- <paginate-links class="pl-0" for="languages" :async="true" :limit="lim" :show-step-links="true"></paginate-links> -->
        <ul class="pl-0 paginate-links" v-if="pages > 1">
          <li
            v-if="pages > 0"
            @click="getPayments(1)"
            class="left-arrow disabled"
          >
            <a>«</a>
          </li>

          <li
            @click="getPayments(p)"
            :class="p == current ? 'number active pg' : 'number pg'"
            v-for="(p, index) in pages"
            :key="pag + index"
          >
            <span v-if="Math.abs(p - current) < 4 || p == pages || p == 1">
              <a
                :class="{
                  current: current === p,
                  last: p == pages && Math.abs(p - current) > 4,
                  first: p == 1 && Math.abs(p - current) > 4,
                }"
                >{{ p }}</a
              >
            </span>
          </li>
          <li
            v-if="pages > 0"
            @click="getPayments(pages)"
            class="right-arrow disabled"
          >
            <a>»</a>
          </li>
        </ul>
      </div>
      <div v-else class="">
        <h6 id="noPayment" class="text-center pb-5 pt-5">No payment details</h6>
      </div>
    </div>
  </div>
</template>

<script>
// import custom from '../../javascript/custom'
import UserService from "../../../services/userService";
import axios from "axios";

export default {
  name: "BillingPage",
  data() {
    return {
      langs: [],
      arr: [],
      lim: 6,
      paginate: ["languages"],
      current: 1,
      pages: 0,
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getPayments(this.current);
  },
  components: {},
  methods: {
    async getPayments(pg) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getSubPay(pg);
        if (response.data.success == true) {
          this.langs = response.data.data.records;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;

          //   this.arr=response.data.data.records

          //   this.arr.sort( ( a, b) => {
          //     return new Date(a.paid_at) - new Date(b.paid_at);
          // });
          // this.langs = this.arr
          //
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
