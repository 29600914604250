<template>
  <div>
    <div class="log-form login">
      <form @submit.prevent="loginUser">
        <div class="form-det">
          <h2>Login</h2>
          <input
            type="email"
            v-model="auth.session.login"
            class="input-field form-control"
            placeholder="Email"
            required
          />
          <input
            :type="passwordFieldType"
            v-model="auth.session.password"
            class="input-field form-control"
            placeholder="Password"
            required
          />
          <span type="password" @click="switchVisibility"
            ><i class="far fa-eye"></i
          ></span>
          <router-link
            to="/forgot-password"
            class="col-sm-12 text-right p-0 small-link"
            >Forgot Password?</router-link
          >
          <button id="logBtn" type="submit" :disabled="disab" class="form-btn">
            Log In
          </button>
          <p class="text-center mb-0">
            Don't have an account?
            <router-link to="/signup" class="link"
              ><u><strong>Sign up</strong></u></router-link
            >
          </p>
          <ul class="irie-Info mt-2">
            <li>
              <span class="mr-3"
                ><a target="_blank" href="https://www.iriefm.net/terms-of-use/"
                  >Terms & Conditions</a
                ></span
              >
              <span
                ><a
                  target="_blank"
                  href="https://www.iriefm.net/privacy-policy/"
                  >Privacy Policy</a
                ></span
              >
            </li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import custom from "../../javascript/custom";
import AuthService from "../../services/authService";
import router from "@/router";
import UserService from "../../services/userService";
import axios from "axios";
// import $ from "jquery";

export default {
  name: "LoginPage",
  data() {
    return {
      auth: {
        session: {
          password: "",
          login: "",
        },
      },
      disab: false,
      passwordFieldType: "password",
    };
  },
  metaInfo() {
    return {
      title: "Irie FM",
      meta: [
        {
          vmid: "Irie FM",
          name: "Irie FM",
          content: "Irie FM Live",
        },
      ],
    };
  },
  mounted() {},
  created() {
    var sess = localStorage.getItem("vue-session-key");
    if (sess && sess != "{}" && sess != undefined) {
      var user = JSON.parse(sess);
      user = user.user;
      // console.log(user)
      // console.log(user)
      if (user && user.roles == null) {
        router.push({
          path: "/member/dashboard",
        });
      } else if (user && user.roles.length > 0) {
        for (var i = 0; i < user.roles.length; i++) {
          router.push({
            path: "/admin/dashboard",
          });
        }
      }
    } else {
      this.$session.clear();
      sessionStorage.clear();
      localStorage.clear();
      this.$session.destroy();
      if (this.$route.name != "Login") {
        router.push({
          name: "Login",
        });
      }
    }
  },

  computed: {},
  components: {},
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      if (this.passwordFieldType == "text") {
        custom.showPassword(0);
      } else {
        custom.hidePassword(0);
      }
    },
    async loginUser() {
      var alert = {};
      try {
        const response = await AuthService.getUser(this.auth);
        var bt = document.getElementById("logBtn");
        if (response.data.success == true) {
          this.$session.start();
          // this.$cookies.set("tk", response.data.data.record.authToken);
          // this.http.headers.common["Authorization"] =
          //   "Bearer " + response.data.data.record.authToken;

          var aq = response.data.data.record.audio_quality;

          this.$session.set("token", response.data.data.record.authToken);
          sessionStorage.setItem("token", response.data.data.record.authToken);
          sessionStorage.setItem("logStat", true);
          this.$session.set("aq", aq);
          // console.log(aq)

          this.$session.set("loggedIn", true);
          this.$session.set("prof", response.data.data.record.id);
          // var mem_coun = response.data.data.record.country;
          // console.log(this.$cookies.get("tk"));
          this.$cookies.set("role", response.data.data.record.role_symbols[0]);
          this.$cookies.set("getaq", 1);
          // sessionStorage.setItem(
          //     "user",
          //     JSON.stringify(response.data.data.record.role_symbols)
          // );
          // alert("jji");
          // console.log("hi");
          if (response.data.data.record.role_symbols.length != 0) {
            this.getProfile();
            this.getPermiss(
              response.data.data.record.role[0].permissions,
              response.data.flash.notice
            );
          } else {
            // {
            //     // alert(
            //     //     "Please log out of an exisiting device or browser session to continue"
            //     // );
            // } else
            // console.log("hi");
            if (response.data.data.record.permissions == null) {
              // console.log("hi");
              this.getProfile();
              router.push({
                path: "/member/plans",
              });
            } else {
              this.getProfile();
              this.getPermission(
                response.data.data.record.permissions,
                response.data.flash.notice
              );
            }
          }
        } else {
          this.$session.set("loggedIn", false);
          bt.classList.add("fail");
          bt.innerText = "Please Try Again";
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Log In";
          }, 1500);
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    getPermission(data, flash) {
      // console.log(flash.notice)
      var user_per = data;
      var per = [];
      var count = 1;
      // console.log(user_per);

      for (var i = 0; i < user_per.length; i++) {
        if (user_per[i].can_read == true) {
          per.push(user_per[i].permissable_model.toLowerCase() + ":read");
        }
        if (user_per[i].can_create == true) {
          per.push(user_per[i].permissable_model.toLowerCase() + ":create");
        }
        if (user_per[i].can_edit == true) {
          per.push(user_per[i].permissable_model.toLowerCase() + ":edit");
        }
        if (user_per[i].can_delete == true) {
          per.push(user_per[i].permissable_model.toLowerCase() + ":delete");
        }
        count += 1;
      }

      if (user_per != null && user_per.length != 0) {
        // console.log(per, count, user_per);
        if (count > user_per.length) {
          // localStorage.setItem("uperm", JSON.stringify(per));
          // // console.log(per);
          // this.$bus.$emit("uperm");
          // localStorage.setItem("permissions", null);
          localStorage.setItem("permissions", JSON.stringify(per));
          this.$bus.$emit("permission");
        }
      } else {
        per.push("vip");
        // localStorage.setItem("permissions", null);
        localStorage.setItem("permissions", JSON.stringify(per));
        this.$bus.$emit("permission");
        // console.log(per, count, user_per);
      }
      setTimeout(
        function () {
          var alert = {
            text: "",
            head: flash,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          router.push({
            path: "/member/dashboard",
          });
        }.bind(this),
        1000
      );
    },
    getPermiss(data, flash) {
      // console.log(flash.notice)
      var user_per = data;
      var per = [];
      var count = 1;
      var user = this.$cookies.get("role");
      // console.log(user[0] != 'admin')
      if (user != "admin") {
        per.push("dashboard");
        count += 1;
        per.push("profile");
        count += 1;

        for (var i = 0; i < user_per.length; i++) {
          if (user_per[i].can_read == true) {
            per.push(user_per[i].permissable_model.toLowerCase() + ":read");
          }
          if (user_per[i].can_create == true) {
            per.push(user_per[i].permissable_model.toLowerCase() + ":create");
          }
          if (user_per[i].can_edit == true) {
            per.push(user_per[i].permissable_model.toLowerCase() + ":edit");
          }
          if (user_per[i].can_delete == true) {
            per.push(user_per[i].permissable_model.toLowerCase() + ":delete");
          }
          count += 1;
        }
      } else {
        per.push("admin");
        count += 1;
      }

      if (per != null && per != []) {
        // console.log(per,count,flash)
        if (count > user_per.length + 2 || user == "admin") {
          localStorage.setItem("permissions", JSON.stringify(per));
          this.$bus.$emit("permission");
          setTimeout(
            function () {
              var alert = {
                text: "",
                head: flash,
                type: "alert-success d-block",
              };
              this.$bus.$emit("flash", alert);
              router.push({
                path: "/admin/dashboard",
              });
            }.bind(this),
            500
          );
        }
      }
    },
    async getProfile() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile(this.id);
        if (response.data.success == true) {
          // this.$cookies.set("user", response.data.data.profile.current_subscription);
          // console.log(this.$cookies.get("user"));
          var pl = response.data.data.profile.current_subscription;
          if (pl != null) {
            this.$cookies.set("subscription", true);
          } else {
            this.$cookies.set("subscription", false);
          }

          if (response.data.data.profile.current_subscription.price > 0) {
            this.$cookies.set("free", false);
          } else {
            this.$cookies.set("free", true);
          }
        }
      } catch (err) {
        console.log("Error");
      }
    },
  },
};
</script>
