<template>
  <!--<div class="main-det plsh">-->
  <div class="plsh">
    <div class="row justify-content-center couponBlock">
      <div class="col-xl-6 col-lg-7 col-md-6">
        <h4 class="mb-5 check_sign"><b>Checkout</b></h4>

        <div class="row">
          <div class="col-lg-3 col-xl-3">
            <h5 class="cap-planName">
              <b>{{ plan.name }}</b>
            </h5>
          </div>
          <div class="col-lg-7 col-xl-6 plan_div">
            <p class="pdet" v-if="plan.trial_period_amount > 0">
              Get A Free Trial for {{ plan.trial_period_amount }}&nbsp;
              <span v-if="plan.trial_period_amount > 1">days</span>
              <span v-else> day</span>
              <!-- {{
            plan.trial_period_unit
          }} -->
            </p>
            <h5 class="mb-4 subunit" v-if="myco != null">
              <b
                >{{ myco
                }}<span
                  >/<span v-if="plan.subscription_period_amount > 1">{{
                    plan.subscription_period_amount
                  }}</span>
                  <span>{{ plan.subscription_period_unit }}</span></span
                ></b
              >
            </h5>
            <h5 class="plnDetails">
              <span v-if="plan.trial_period_amount > 0">Then </span
              >{{ plan.currency }}${{ plan.price
              }}<span>
                / <span v-if="plan.trial_period_amount > 1">day</span>
                <span v-else> day</span>
                <!-- <span>{{ plan.subscription_period_unit }}</span> --></span
              >
            </h5>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-10">
            <b><p class="included">What's Included</p></b>
            <p class="plan_des">
              {{ plan.description }}
            </p>
          </div>
        </div>

        <div class="my-3 mt-3 auto_renew">
          <h4 class="renew d-inline">Auto Renew</h4>
          <label class="switch ml-4">
            <input type="checkbox" v-model="mysub.subscription.renew_notify" />
            <span class="slider round"></span>
          </label>
        </div>
        <div v-if="pay != 'paypal'" class="mt-4 coupon_op">
          <div class="couponBlock">
            <div class="col-sm-12 pl-0 couponInput">
              <input
                type="text"
                v-model="code"
                placeholder="Coupon Code"
                class="form-control coup"
              />
              <button class="form-btn m-0 coupbtn" @click="getPlans()">
                Apply
              </button>
            </div>
          </div>
          <p class="col-12 mt-3 pl-0">
            <b>{{ message }}</b>
          </p>
        </div>
        <div class="row mt-5">
          <div class="col-lg-8">
            <ul class="irie-Info">
              <li><b>IRIE FM</b></li>
              <li class="address">54 Main street, Ocho Rios, St Ann</li>
              <li>
                <a href="mailto:support@iriefm.net"> Support@iriefm.net </a>
              </li>
              <li><a href="tel:18769172232">(876)-917-2232 </a></li>
            </ul>
          </div>
        </div>
        <div class="row mt-4 mb-5">
          <div class="col-lg-8">
            <ul class="irie-Info">
              <li>
                <span
                  ><a href="https://www.iriefm.net/terms-of-use/"
                    >Terms & Conditions</a
                  ></span
                >
              </li>
              <!-- <li>
                <span><a href="">Delivery Policy</a></span>
              </li>
              <li>
                <span><a href="">Security Policy</a></span>
              </li> -->
              <li>
                <span
                  ><a
                    target="_blank"
                    href="https://www.iriefm.net/privacy-policy/"
                    >Privacy Policy</a
                  ></span
                >
              </li>
              <!-- <li>
                <span><a target="_blank" href="">Refunds Returns</a></span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-2 pt-2 pb-2"></div>-->
      <div class="col-xl-6 col-lg-5 col-md-6">
        <div class="paymentSec">
          <h5 class="pay_title"><b>Payment Details</b></h5>

          <div class="tab row">
            <!--<button v-if="cards.length>0" class="tablinks" @click="navMenu($event, 'savedcard')" ><i class="fal fa-credit-card mr-3"></i>Saved Cards</button>-->
            <div class="col-md-6 col-6 paymentCol">
              <button
                class="tablinks"
                @click="navMenu($event, 'card')"
                id="defaultOpen"
              >
                <i class="fas fa-credit-card mr-3"></i>Credit Card
              </button>
            </div>
            <div class="col-md-6 col-6 paymentCol" v-if="plan.price != 0">
              <button class="tablinks" @click="navMenu($event, 'paypal')">
                <i class="paypalIcon fab fa-paypal mr-3"></i>Paypal
              </button>
            </div>
          </div>

          <div id="card" class="tabcontent">
            <div class="optionPayment">
              <div
                class="pwth"
                v-if="cards.length > 0"
                @click="pay = 'saveCard'"
              >
                <p class="cap">
                  <i class="fal fa-credit-card mr-3"></i>Pay with saved card
                </p>
                <div id="savedcard">
                  <div v-if="pay == 'saveCard'">
                    <form class="savedPaymentForm" @submit.prevent="payment">
                      <div class="col-lg-12 pt-3 saved">
                        <div
                          v-if="cards != null && cards != undefined"
                          class="col-lg-12 col-md-12 col-sm-12 carddet p-0"
                        >
                          <div
                            class="col-12 p-0"
                            v-for="(card, index) in cards"
                            :key="'card' + index"
                          >
                            <input
                              class="mb-1 check"
                              type="radio"
                              name="card"
                              v-model="pcard"
                              :value="card.id"
                            />
                            **** **** **** {{ card.last4 }}
                          </div>
                          <table
                            class="table-responsive-sm payment_details mt-3"
                          >
                            <tr>
                              <td class="payment_td">Subtotal</td>
                              <td class="payment_td">
                                <p class="prices">
                                  {{ plan.currency }}${{ plan.price }}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td class="payment_td"><b>Total</b></td>
                              <td class="payment_td">
                                <p class="plnDetails prices">
                                  {{ plan.currency }}${{ plan.price }}
                                </p>
                              </td>
                            </tr>
                          </table>
                        </div>

                        <button
                          id="payButtons"
                          type="submit"
                          class="pay_form-btn w-100 payButton"
                          v-if="plan.trial_period_amount > 0"
                        >
                          Get Free Trial
                        </button>
                        <button
                          id="payButtons"
                          type="submit"
                          class="pay_form-btn w-100 payButton"
                          v-else
                        >
                          Pay {{ plan.currency }}${{ plan.price }}</button
                        ><br />
                        <button
                          @click="cancelForm()"
                          class="can-form-btn w-100 payButton"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                    <h6 class="securePayments mt-4">
                      <span
                        ><i class="fa fa-lock" aria-hidden="true"></i>&nbsp;
                        <b class="secure">Secure Payments</b></span
                      >
                    </h6>
                    <div
                      class="row d-flex justify-content-center payment_fonts mt-4"
                    >
                      <!--<div class="col  paymen_single_fonts"> <font-awesome-icon icon="fa-brands fa-cc-visa" /></div>-->
                      <div
                        class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 paymen_single_fonts"
                      >
                        <img
                          class="fac-icon-visa"
                          src="../../../assets/cib-visa.png"
                        />
                      </div>
                      <div
                        class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 paymen_single_fonts"
                      >
                        <i class="fab fa-cc-mastercard"></i>
                      </div>
                      <div
                        class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 paymen_single_fonts"
                      >
                        <img
                          class="fac-icon-fac"
                          src="../../../assets/fac.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pwth" @click="pay = 'newCard'">
                <p class="cap">
                  <i class="fas fa-credit-card mr-3"></i>Pay with new card
                </p>
                <div class="newCard" v-if="pay == 'newCard'">
                  <form
                    class="savedPaymentForm"
                    id="paymentform"
                    @submit.prevent="payment"
                  >
                    <!-- <input type="text" class="input-field form-control" v-model="mysub.subscription.payment_source_attributes.name" placeholder="Card Holder Name" required /> -->
                    <div class="form-group car_num_input">
                      <input
                        type="text"
                        id="card-number"
                        class="input-field form-control"
                        v-model="
                          mysub.subscription.payment_source_attributes.number
                        "
                        placeholder="Card Holder Number"
                        maxlength="16"
                        minlength="13"
                        pattern="[0-9]+"
                        title="Please enter card number must be no less than 13 digits"
                        required
                      /><i class="fal fa-credit-card cnum"></i>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-6 input-field1">
                        <input
                          type="text"
                          pattern="[0-9]+"
                          id="card-ex"
                          class="input-field form-control"
                          v-model="
                            mysub.subscription.payment_source_attributes
                              .expiry_date
                          "
                          placeholder="MMYY"
                          title="Please enter expiry month and year in the format MMYY"
                          maxlength="4"
                          minlength="4"
                          required
                        />
                        <i class="far fa-calendar"></i>
                      </div>
                      <div class="form-group col-md-6 cvv_input">
                        <input
                          type="text"
                          id="security-code"
                          pattern="[0-9]+"
                          v-model="
                            mysub.subscription.payment_source_attributes.cvv
                          "
                          class="input-field form-control"
                          maxlength="3"
                          minlength="3"
                          title="Please enter cvv"
                          placeholder="CVV"
                          required
                        /><i class="fal fa-lock"></i>
                      </div>
                    </div>
                    <div>
                      <table class="table-responsive-sm payment_details">
                        <tr>
                          <td class="payment_td">Subtotal</td>
                          <td class="payment_td">
                            <p class="prices">
                              {{ plan.currency }}${{ plan.price }}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td class="payment_td"><b>Total</b></td>
                          <td class="payment_td">
                            <p class="plnDetails prices">
                              {{ plan.currency }}${{ plan.price }}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <button
                      id="payButton"
                      type="submit"
                      class="form-btn w-100 payButton"
                      v-if="plan.trial_period_amount > 0"
                    >
                      Get Free Trial
                    </button>
                    <button
                      id="payButton"
                      type="submit"
                      class="form-btn w-100 payButton"
                      v-else
                    >
                      Pay {{ plan.currency }}${{ plan.price }}</button
                    ><br />
                    <button @click="cancelForm()" class="can-form-btn w-100">
                      Cancel
                    </button>
                  </form>
                  <h6 class="securePayments">
                    <span
                      ><i class="fa fa-lock" aria-hidden="true"></i>&nbsp;
                      <b class="secure">Secure Payments</b></span
                    >
                  </h6>
                  <div
                    class="row d-flex justify-content-center payment_fonts mt-4"
                  >
                    <!--<div class="col  paymen_single_fonts"> <font-awesome-icon icon="fa-brands fa-cc-visa" /></div>-->
                    <div
                      class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 paymen_single_fonts"
                    >
                      <img
                        class="fac-icon-visa"
                        src="../../../assets/cib-visa.png"
                      />
                    </div>
                    <div
                      class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 paymen_single_fonts"
                    >
                      <i class="fab fa-cc-mastercard"></i>
                    </div>
                    <div
                      class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 paymen_single_fonts"
                    >
                      <img class="fac-icon-fac" src="../../../assets/fac.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="paypal"
            class="tabcontent pwth-payPal"
            v-if="plan.price != 0"
          >
            <div class="paypal_btn" v-if="pay == 'paypal'">
              <form @submit.prevent="paypal">
                <button
                  id="palButton"
                  type="submit"
                  class="pay_form-btn w-100 mt-4"
                  v-if="plan.trial_period_amount > 0"
                >
                  Get Free Trial
                </button>
                <button
                  id="palButton"
                  type="submit"
                  class="pay_form-btn w-100"
                  v-else
                >
                  Pay {{ plan.currency }}${{ plan.price }}
                </button>
                <button
                  @click="cancelForm()"
                  class="can-form-btn w-100payButton savedPaymentForm"
                >
                  Cancel
                </button>
                <h6 class="securePayments mt-4">
                  <span
                    ><i class="fa fa-lock" aria-hidden="true"></i>&nbsp;
                    <b class="secure">Secure Payments</b></span
                  >
                </h6>
              </form>
              <div class="row d-flex justify-content-center payment_fonts mt-4">
                <!--<div class="col  paymen_single_fonts"> <font-awesome-icon icon="fa-brands fa-cc-visa" /></div>-->
                <div
                  class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 paymen_single_fonts"
                >
                  <img
                    class="fac-icon-visa"
                    src="../../../assets/cib-visa.png"
                  />
                </div>
                <div
                  class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 paymen_single_fonts"
                >
                  <i class="fab fa-cc-mastercard"></i>
                </div>
                <div
                  class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 paymen_single_fonts"
                >
                  <img class="fac-icon-fac" src="../../../assets/fac.png" />
                </div>
              </div>
            </div>
          </div>
          <button
            v-if="pay != 'paypal' && pay != 'saveCard' && pay != 'newCard'"
            @click="cancelForm()"
            class="can-form-btn w-100 payButton mt-4"
          >
            Cancel
          </button>
        </div>
        <!--<div class="optionPayment">
          <div class="pwth"  v-if="cards.length>0" @click="getPayStatus('savedcard')">
            <p class="cap">
              <i class="fal fa-credit-card mr-3"></i>Pay with saved card
            </p>
   
          </div>
          <div class="pwth" @click="getPayStatus('card')">
            <p class="cap">
              <i class="fas fa-credit-card mr-3"></i>Pay with new card
            </p>
  
          </div>
          <div class="pwth" @click="getPayStatus('paypal')">
            <p class="cap"><i class="paypalIcon fab fa-paypal mr-3"></i>Pay with paypal</p>
  
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
// import custom from '../../javascript/custom'
import UserService from "../../../services/userService";
import axios from "axios";
import moment from "moment";
import router from "@/router";
import $ from "jquery"; //import jQuery

export default {
  name: "PlanShowPage",
  data() {
    return {
      plan: [],
      pcard: null,
      cards: [],
      id: "",
      pay: "",
      user: "",
      u12: true,
      message: null,
      myco: null,
      code: "",
      currentClass: "plsh",
      mindate: moment().format("yyyy"),
      mysub: {
        subscription: {
          plan_id: this.$route.params.id,
          coupon_code: "",
          renew_notify: true,
          payment_source_attributes: {
            number: "",
            cvv: "",
            expiry_date: "",
          },
        },
      },

      // minmonth: moment().format("dddd").tz(moment.tz.guess(true)),
    };
  },
  created() {
    this.id = this.$route.params.id;

    this.getPlan();
  },
  computed: {},
  watch: {
    // code: 'getPlans',
  },
  mounted() {
    document.getElementById("defaultOpen").click();
    this.getCard();
    this.user = this.$session.get("prof");
    this.$bus.$on("code", (data) => {
      this.code = data;
      if (this.code != null || this.code != undefined) {
        this.getPlans();
      }
    });
    // console.log(this.user)
  },
  components: {},
  methods: {
    async getPlans() {
      if (this.code != "" && this.code != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + this.$session.get("token");
        try {
          const response = await UserService.getCouponPlans(this.code);
          if (response.data.success == true) {
            let plans = response.data.data.records[0].plans;
            plans.forEach((item) => {
              if (item.id == this.id) {
                this.message =
                  "Coupon Applied. This coupon is valued at " +
                  response.data.data.records[0].discount_value +
                  "% off.";
                this.myco = "This plan will now cost $" + item.discounted_price;
              } else {
                this.message = "Coupon is not eligible for this plan";
              }
            });
          } else {
            //console.log("error")
          }
        } catch (err) {
          // //console.log(err)
        }
      }
    },
    async getCard() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getCard();
        if (response.data.success == true) {
          this.cards = response.data.data.records;
          // if(this.card==null){
          //   this.showPay()
          // }
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getPlan() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getPlan(this.id);
        if (response.data.success == true) {
          this.plan = response.data.data.plan;
          //console.log(this.plan)
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async paypal() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      var bt = $(".palButton");
      // bt.classList.add("disab");
      // bt.disabled=true;
      $(bt).addClass("disab");
      $(bt).attr("disabled", true);
      // this.$(".payButton").
      delete this.mysub.subscription.coupon_code;
      var plan = {
        plan_id: this.mysub.subscription.plan_id,
      };
      try {
        const response = await UserService.newPaypalSubscription(plan);
        // console.log(response)
        if (response.data.success == true) {
          // this.$session.set("ba_token", response.data.data.transaction.links.approve.href.split("?")[1]);
          this.$session.set(
            "subid",
            response.data.data.transaction.links.edit.href.split(
              "subscriptions/"
            )[1]
          );
          var url = response.data.data.transaction.links.approve.href;
          window.open(url, "_self");
          //  jj
        } else {
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
          // bt.disabled=false;
          $(bt).removeClass("disab");
          $(bt).attr("disabled", false);
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async payment() {
      var alert = {};
      if (this.code != "" && this.code != null) {
        this.mysub.subscription.coupon_code = this.code;
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      var bt = $(".payButton");
      // bt.classList.add("disab");
      // bt.disabled=true;
      $(bt).addClass("disab");
      $(bt).attr("disabled", true);
      // this.$(".payButton").
      if (this.pcard != null && this.pay == "saveCard") {
        delete this.mysub.subscription.payment_source_attributes;
        this.mysub.subscription.payment_source_id = this.pcard;
      }
      if (this.pay == "paypal") {
        delete this.mysub.subscription.coupon_code;
      }
      try {
        const response = await UserService.newSubscription(this.mysub);
        //console.log(response.data)
        if (response.data.success == true) {
          this.getProfile(response);
        } else {
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
          // bt.disabled=false;
          $(bt).removeClass("disab");
          $(bt).attr("disabled", false);
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getProfile(ar) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile(this.user);
        if (response.data.success == true) {
          // this.user.profile = (response.data.data.profile)
          // this.user.profile.avatar = response.data.data.profile.avatar.large
          // this.$cookies.set("user", response.data.data.profile);
          var pp = response.data.data.profile.current_subscription;
          if (pp != null) {
            this.$cookies.set("subscription", true);
          } else {
            this.$cookies.set("subscription", false);
          }
          var alert = {
            text: "Thanks for subscribing.",
            head: ar.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          this.$bus.$emit("subUp");
          setTimeout(
            function () {
              sessionStorage.setItem("backgroundChange", "yes");
              router.push({
                path: "/member/dashboard",
              });
            }.bind(ar),
            900
          );
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    /*     getPayStatus(state){
                                                                                                                                        this.pay = state
                                                                                                                                    var selected = document.getElementsByClassName(this.currentClass)[0]
                                                                                                                                    selected.classList.remove(this.currentClass); 
                                                                                                                                    selected.classList.add("plsh-"+state);
                                                                                                                                    this.currentClass = "plsh-"+state
                                                                                                                                    }, */
    navMenu(evt, tab) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(tab).style.display = "block";
      evt.currentTarget.className += " active";

      this.pay = tab;
      var selected = document.getElementsByClassName(this.currentClass)[0];
      selected.classList.remove(this.currentClass);
      selected.classList.add("plsh-" + tab);
      this.currentClass = "plsh-" + tab;
    },
    cancelForm() {
      //document.getElementById("paymentform").reset();
      this.pay = "";
      router.push({
        path: "/member/plans",
      });
    },
  },
};
</script>
