<template>
  <div id="new_user" class="col-lg-12 mt-4 adacc">
    <div class="col-lg-12">
      <h3><b>New Host</b></h3>
      <form class="" @submit.prevent="createUser">
        <div class="mt-5">
          <div class="row mb-3">
            <div class="col-lg-8 col-md-8">
              <!-- <label>First Name *</label> -->
              <input
                type="text"
                v-model="details.host.name"
                class="input-field form-control"
                placeholder="Name"
                required
              />
            </div>
          </div>
          <div class="row mt-3 mb-3">
            <div class="col-lg-8 col-md-8">
              <div class="uploadarea">
                <!-- <label>Thumbnail</label> -->
                <button type="button" class="form-btn m-0 imgbtn">
                  Choose Avatar
                </button>
                <input
                  id="primg"
                  type="file"
                  @change="getThumb"
                  ref="thumbupload"
                  accept="images*"
                  required="details.host.thumbnail.includes('base64') ? true : false"
                />
                <img
                  class="ml-5 av"
                  v-if="details.host.thumbnail"
                  :src="details.host.thumbnail"
                />
              </div>
            </div>
          </div>
          <div class="row mt-3 mb-3">
            <div class="col-lg-8 col-md-8">
              <div class="uploadarea">
                <!-- <label>Thumbnail</label> -->
                <button type="button" class="form-btn m-0 imgbtn mxbtn">
                  Choose Background
                </button>
                <input
                  id="primg"
                  type="file"
                  @change="getHead"
                  ref="headupload"
                  accept="images*"
                  required="details.host.header_background.includes('base64') ? true : false"
                />
                <img
                  class="ml-5 av"
                  v-if="details.host.header_background"
                  :src="details.host.header_background"
                />
              </div>
            </div>
          </div>

          <router-link to="/admin/hosts"
            ><button class="form-btn mr-4 cancel">Cancel</button></router-link
          >

          <button id="saveUser" type="submit" class="form-btn">Add Host</button>
        </div>
      </form>
    </div>
    <!-- Modal -->
  </div>
</template>

<script>
// import custom from "../../../javascript/custom";
import AdminService from "../../../services/adminService";
import axios from "axios";
import router from "@/router";

export default {
  name: "NewHostPage",
  data() {
    return {
      details: {
        host: {
          name: "",
          thumbnail: "",
          header_background: "",
        },
      },
      req: true,
      current: 1,
    };
  },
  created() {},
  computed: {},
  mounted() {},
  components: {},
  methods: {
    async createUser() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        var bt = document.getElementById("saveUser");
        const response = await AdminService.createHost(this.details);
        if (response.data.success == true) {
          //bt.classList.add("success");
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          //bt.innerText = response.data.flash.notice
          router.push({
            path: "/admin/hosts",
          });
        } else {
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Save User";
          }, 1500);
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    getThumb(event) {
      const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 4) {
        alert("File size exceeds 4 MB");
        this.$refs.thumbupload.value = null;
      } else {
        const fr = new FileReader();
        fr.onloadend = function () {
          this.details.host.thumbnail = fr.result;
        }.bind(this);

        fr.readAsDataURL(event.target.files[0]);
      }
    },

    getHead(event) {
      const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 4) {
        alert("File size exceeds 4 MB");
        this.$refs.headupload.value = null;
      } else {
        const fr = new FileReader();
        fr.onloadend = function () {
          this.details.host.header_background = fr.result;
        }.bind(this);

        fr.readAsDataURL(event.target.files[0]);
      }
    },
  },
};
</script>
