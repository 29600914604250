<template>
  <div class="main-det" v-bind:style="{ background: activeBackground }">
    <div class="workouts mb-5 mt-0">
      <div class="col-sm-12 p-0">
        <div v-if="scheduleState > 0" class="cur_live_prog">
          <div @click="playPro()" class="user_profile_cap">
            <div
              class="user_profile_cover"
              :style="current_program.header.background"
            >
              <div class="innerCover">
                <div class="user_profile_headline text-center">
                  <!-- <img :src="current_program.thumbnail" alt="img" /> -->
                  <h4>
                    {{ current_program.name }} <br />
                    <span>{{ current_program.host }}</span>
                  </h4>
                  <!-- <h6>{{ current_program.host }}</h6> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="upNext1 mt-5">
          <div v-if="pass > 0" class="row m-0">
            <h6 class="upNexth6">Up Next</h6>
            <router-link
              v-if="numOfProg1 == true"
              class="smr1 upNexth6"
              to="/member/schedule"
              >See More</router-link
            >
            <button
              :disabled="schedule.length > 0 && sP == 0"
              class="btn-link btn prev upNexth6"
              @click="prevWork(1)"
            >
              <i class="fas fa-chevron-left"></i>
            </button>
            <button
              :disabled="schedule.length > 0 && schedule.length <= sP + 1"
              class="btn-link btn upNexth6"
              @click="nextWork(1)"
            >
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="upNext2" v-if="schedule.length > 0">
        <carousel
          :perPageCustom="[
            [560, 3],
            [769, 4],
            [1025, 5],
            [1400, 6],
            [375, 2],
            [100, 1],
          ]"
          :navigationEnabled="true"
          :paginationEnabled="false"
          navigationNextLabel="<i class='fas fa-chevron-right'></i>"
          navigationPrevLabel="<i class='fas fa-chevron-left'></i>"
        >
          <slide
            v-for="(ins, index) in schedule.slice(sP)"
            :key="'category' + index"
          >
            <router-link
              class="col-lg-0 pl-0"
              :to="{ name: 'SPrograms', params: { id: ins.program_id } }"
            >
              <div class="programImg">
                <img
                  :src="
                    ins.host.thumbnail_urls != null
                      ? ins.host.thumbnail_urls.large
                      : ''
                  "
                  class="img-thumb"
                />
              </div>
              <div class="dpr-details">
                <!-- <p >{{ins.name}}</p> -->
                <p class="prn">{{ ins.program_name }}</p>
                <p class="ph mb-1" v-if="ins.host">{{ ins.host.name }}</p>
                <p class="">
                  {{ moment(ins.start_at).format("h:mma z") }} -
                  {{ moment(ins.end_at).format("h:mma z") }}
                </p>
                {{ ins.index }}
              </div>
            </router-link>
          </slide>
        </carousel>
      </div>

      <div class="ads">
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div
              :class="i == 0 ? 'active carousel-item' : 'carousel-item'"
              v-for="(slide, i) in ads"
              :key="i + 'ad'"
              :href="
                slide.url.includes('http') ? slide.url : 'http://' + slide.url
              "
              @click="adImp(slide.id, slide.url)"
              target="_blank"
            >
              <div
                v-if="slide.ad_type == 'image'"
                class="adsImg"
                :style="'background-image: url(' + slide.thumbnail + ')'"
              ></div>
              <div v-else>
                <video
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  class="d-block w-100"
                  :src="slide.thumbnail"
                ></video>
              </div>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
      <br />
      <div class="workouts" v-if="prog.length > 0">
        <div class="col-sm-12 p-0">
          <div class="row m-0">
            <h5 class="record">Recorded Programming</h5>
            <router-link
              v-if="numOfProg2 == true"
              class="smr2"
              to="/member/programmes"
              >See More</router-link
            >
            <button
              :disabled="prog.length > 0 && sW == 0"
              class="btn-link btn prev nextBtn"
              @click="prevWork(2)"
            >
              <i class="fas fa-chevron-left"></i>
            </button>
            <button
              :disabled="prog.length > 0 && prog.length <= sW + 1"
              class="btn-link btn nextBtn"
              @click="nextWork(2)"
            >
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
        <carousel
          :perPageCustom="[
            [560, 3],
            [769, 4],
            [1025, 5],
            [1400, 6],
            [375, 2],
            [100, 1],
          ]"
          :navigationEnabled="true"
          :paginationEnabled="false"
          navigationNextLabel="<i class='fas fa-angle-right nav-arrow-icon'></i>"
          navigationPrevLabel="<i class='fas fa-angle-left nav-arrow-icon' ></i>"
        >
          <slide
            v-for="(ins, index) in prog.slice(sW)"
            :key="'category' + index"
          >
            <router-link
              class="col-lg-0 pl-0"
              :to="{ name: 'SPrograms', params: { id: ins.id } }"
            >
              <div class="programImg">
                <img
                  class="img-thumb"
                  :src="
                    ins.thumbnail_urls != null ? ins.thumbnail_urls.large : ''
                  "
                />
              </div>
              <!-- <p >{{ins.name}}</p> -->
              <div class="dpr-details">
                <p class="prn">
                  {{ ins.name }}
                </p>
                <p class="heartIcon" v-if="ins.bookmarked == false">
                  <font-awesome-icon
                    v-if="can('favourite:edit') || can('vip')"
                    v-on:click.prevent="addVideo(ins.id)"
                    :icon="['far', 'heart']"
                    size="lg"
                  />
                  <font-awesome-icon
                    v-else
                    v-on:click.prevent="upgrade()"
                    :icon="['far', 'heart']"
                    size="lg"
                  />
                </p>
                <p class="heartIcon" v-else>
                  <font-awesome-icon
                    v-if="can('favourite:edit') || can('vip')"
                    v-on:click.prevent="removeVideo(ins.id)"
                    :icon="['fas', 'heart']"
                    size="lg"
                  />
                  <font-awesome-icon
                    v-else
                    v-on:click.prevent="upgrade()"
                    :icon="['fas', 'heart']"
                    size="lg"
                  />
                </p>
                <p class="ph" v-if="ins.host">{{ ins.host.name }}</p>
              </div>
            </router-link>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
// import custom from '../../javascript/custom'
import UserService from "../../../services/userService";
import axios from "axios";
import router from "@/router";
import moment from "moment";
import $ from "jquery";

export default {
  name: "DashboardPage",
  data() {
    return {
      current_program: {
        name: "",
        startime: "",
        endtime: "",
        thumbnail: "",
        host: "",
        header: {
          background: "",
        },
      },
      page: "",
      sB: 0,
      items: [],
      presenter: {},
      id: "",
      plan: {},
      mid: "",
      prog: [],
      ads: [],
      schedule: [],
      week: [],
      current: 0,
      timer: 0,
      width: 100,
      sW: 0,
      sP: 0,
      today: null,
      time: "",
      pass: 0,
      scheduleState: 0,
      currentbackground: "",
      perPage: 0,
      activeBackground: "",
      screenWidth: 0,
      windowHeight: 0,
      windowWidth: 0,
      numOfProg1: "false",
      numOfProg2: "false",
    };
  },
  created() {
    if (window.location.href.indexOf("paypal") > -1) {
      this.getSub();
    }
    this.id = this.$route.params.id;
    //this.mid = this.$cookies.get("user").id;
    // console.log(localStorage.getItem("Jam"))
    window.addEventListener("resize", this.checkScreenSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  beforeMount() {
    if (sessionStorage.getItem("backgroundChange") == "yes") {
      sessionStorage.removeItem("backgroundChange");
      router.go(0);
    }
  },
  mounted() {
    //console.log(document.referrer)

    if (window.location.href.indexOf("paypal") > -1) {
      this.getSub();
      // console.log("paypal")
    } else {
      // this.plan = this.$cookies.get("user");
      // this.plan = this.plan.current_subscription;
      if (this.$cookies.get("subscription") == "false") {
        // this.$cookies.set("subscription", false);
        router.push({
          path: "/member/plans",
        });
      } else {
        this.getProg();
        this.getAds();
        this.play();
        this.getSchedule();
        this.windowHeight = $(window).height();
        this.windowWidth = $(window).width();
        this.getProfile();
      }
    }
    this.checkBackground();

    // if (
    //   window.location.href.indexOf("paypal") < -1
    // ){
    //   this.plan = this.$cookies.get("user");
    //     this.plan = this.plan.current_subscription;
    //     if (this.plan == null) {
    //       this.$cookies.set("subscription", false);
    //       router.push({ path: "/member/plans" });
    //     } else {
    //       this.getProg();
    //       this.getAds();
    //       this.play();
    //       this.getSchedule();
    //     }

    // }

    this.getTime();
  },
  computed: {},
  components: {},
  watch: {
    // watch the variable today and each time it changes call the method getliveprogram
    today() {
      this.getLiveProgram();
    },
  },
  methods: {
    upgrade() {
      this.$bus.$emit("upgrade");
    },
    async getSub() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      var sub = this.$session.get("subid");
      try {
        const response = await UserService.approvePaypalSubscription(sub);
        if (response.data.success == true) {
          this.getProfile();
          this.$session.remove("subid");
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    nextWork(val) {
      if (val == 1) {
        this.sP += 1;
      } else {
        this.sW += 1;
      }
    },
    prevWork(val) {
      if (val == 1) {
        this.sP -= 1;
      } else {
        this.sW -= 1;
      }
    },
    nextSlide: function () {
      this.current++;
      if (this.current >= this.ads.length) this.current = 0;
      this.resetPlay();
    },
    prevSlide: function () {
      this.current--;
      if (this.current < 0) this.current = this.ads.length - 1;
      this.resetPlay();
    },
    selectSlide: function (i) {
      this.current = i;
      this.resetPlay();
    },
    resetPlay: function () {
      clearInterval(this.timer);
      this.play();
    },
    play: function () {
      let app = this;
      this.timer = setInterval(function () {
        app.nextSlide();
      }, 7000);
    },

    async getProg() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getFullPrograms();
        if (response.data.success == true) {
          // console.log("prog")
          this.prog = response.data.data;
          this.checkScreenSize();
          // console.log(this.prog)
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },

    async getSchedule() {
      var format = "hh:mm:ss";
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.showSchedule();
        if (response.data.success == true) {
          this.schedule = [];
          // console.log("prog")
          this.week = response.data.data;
          var schedule = response.data.data;
          for (var x = 0; x < schedule.length; x++) {
            if (schedule[x].day == moment().format("dddd")) {
              for (var y = 0; y < this.week[x].programs.length; y++) {
                // var zone=moment.tz.guess(true)
                var time = moment();
                time = time._d;
                time = time.toString().split(" ")[4];

                var beforeTime = new Date(this.week[x].programs[y].start_at);
                var afterTime = new Date(this.week[x].programs[y].end_at);
                // time =moment(time._d,format).tz(zone).format(format)
                beforeTime = moment(beforeTime, format);
                beforeTime = beforeTime._i;
                beforeTime = beforeTime.toString().split(" ")[4];
                afterTime = moment(afterTime, format);
                afterTime = afterTime._i;
                afterTime = afterTime.toString().split(" ")[4];
                time = moment(time, format);
                afterTime = moment(afterTime, format);
                beforeTime = moment(beforeTime, format);
                // console.log(time, beforeTime, afterTime);

                if (time.isBefore(beforeTime)) {
                  this.schedule.push(this.week[x].programs[y]);
                  this.pass++;
                } else {
                  //console.log()
                }
              }
            }
            // console.log(this.schedule);
          }
          // console.log(this.prog)
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    getTime() {
      window.setInterval(
        function () {
          this.today = moment().format("ddd MM/DD/y hh:mm:ss");
          this.time = moment();
        }.bind(this),
        1000
      );
    },

    getLiveProgram() {
      var format = "hh:mm:ss";
      for (var x = 0; x < this.week.length; x++) {
        if (this.week[x].day == moment().format("dddd")) {
          for (var y = 0; y < this.week[x].programs.length; y++) {
            // var zone=moment.tz.guess(true)
            var time = moment();
            time = time._d;
            time = time.toString().split(" ")[4];
            var beforeTime = new Date(this.week[x].programs[y].start_at);
            var afterTime = new Date(this.week[x].programs[y].end_at);
            // time =moment(time._d,format).tz(zone).format(format)
            beforeTime = moment(beforeTime, format);
            beforeTime = beforeTime._i;
            beforeTime = beforeTime.toString().split(" ")[4];
            afterTime = moment(afterTime, format);
            afterTime = afterTime._i;
            afterTime = afterTime.toString().split(" ")[4];
            time = moment(time, format);
            afterTime = moment(afterTime, format);
            beforeTime = moment(beforeTime, format);

            // time = moment(time).local()
            // beforeTime = moment(beforeTime).tz(zone)
            // afterTime = moment(afterTime).tz(zone)
            if (time.isBetween(beforeTime, afterTime, "[]")) {
              this.current_program.startime = this.week[x].programs[y].start_at;
              this.current_program.endtime = this.week[x].programs[y].end_at;
              this.current_program.name = this.week[x].programs[y].program_name;
              this.current_program.host = this.week[x].programs[y].host.name;
              this.current_program.thumbnail =
                this.week[x].programs[y].host.thumbnail_urls.large;
              this.current_program.header.background =
                "background-image: url(" +
                this.week[x].programs[y].host.header_background_urls.large +
                ");";
              this.scheduleState++;
            } else if (
              time.isSame(afterTime, "hour") &&
              time.isSameOrBefore(afterTime, "minute", "[]")
            ) {
              this.current_program.startime = this.week[x].programs[y].start_at;
              this.current_program.endtime = this.week[x].programs[y].end_at;
              this.current_program.name = this.week[x].programs[y].program_name;
              this.current_program.host = this.week[x].programs[y].host.name;
              this.current_program.thumbnail =
                this.week[x].programs[y].host.thumbnail_urls.large;
              this.current_program.header.background =
                "background-image: url(" +
                this.week[x].programs[y].host.header_background_urls.large +
                ");";
              this.scheduleState++;
            } else {
              //this.now= null
              //this.schedule.push(this.week[x]);
            }
            // console.log(time.isBetween(beforeTime, afterTime,'hour'))
          }
        }
      }
      // console.log(time,beforeTime,afterTime);
    },

    async adImp(ad, url) {
      window.open(url);

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.saveAdImp(ad);
        if (response.data.success == true) {
          // console.log("prog")
          // this.prog = response.data.data;
          // console.log(this.prog)
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async getAds() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getAds();
        if (response.data.success == true) {
          this.ads = response.data.data;
          // console.log(this.ads)
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },

    async getProfile() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile(this.id);
        if (response.data.success == true) {
          this.plan = response.data.data.profile.current_subscription;
          // this.$cookies.set("user", response.data.data.profile);
          this.$bus.$emit("subUp");
          if (this.plan != null) {
            this.getProg();
            this.getAds();
            this.play();
            this.getSchedule();
            this.windowHeight = $(window).height();
            this.windowWidth = $(window).width();
            this.$cookies.set("subscription", true);
            var user_per =
              response.data.data.profile.current_subscription.permissions;
            var per = [];
            var count = 1;
            // console.log(user_per);

            for (var i = 0; i < user_per.length; i++) {
              if (user_per[i].can_read == true) {
                per.push(user_per[i].permissable_model.toLowerCase() + ":read");
              }
              if (user_per[i].can_create == true) {
                per.push(
                  user_per[i].permissable_model.toLowerCase() + ":create"
                );
              }
              if (user_per[i].can_edit == true) {
                per.push(user_per[i].permissable_model.toLowerCase() + ":edit");
              }
              if (user_per[i].can_delete == true) {
                per.push(
                  user_per[i].permissable_model.toLowerCase() + ":delete"
                );
              }
              count += 1;
            }

            if (user_per != null && user_per.length != 0) {
              // console.log(per, count, user_per);
              if (count > user_per.length) {
                // localStorage.setItem("uperm", JSON.stringify(per));
                // // console.log(per);
                // this.$bus.$emit("uperm");
                // localStorage.setItem("permissions", null);
                localStorage.setItem("permissions", JSON.stringify(per));
                this.$bus.$emit("permission");
              }
            } else {
              per.push("vip");
              // localStorage.setItem("permissions", null);
              localStorage.setItem("permissions", JSON.stringify(per));
              this.$bus.$emit("permission");
              // console.log(per, count, user_per);
            }
          }
          // else {
          //     this.$cookies.set("subscription", false);
          //     router.push({
          //         path: "/member/plans",
          //     });
          // }
          // this.user.profile.avatar = response.data.data.profile.avatar.large
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async addBook(bid) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.saveProgram(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getProg();
          // console.log("add")
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          //console.log("error");
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async removeBook(bid) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.removeProgram(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getProg();
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          //console.log("error");
          alert = {
            text: response.data.errors[0],
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err);
      }
    },
    playPro() {
      this.$bus.$emit("pplayer", true);
      if (
        $("button.plyr__control.plyr__controls__item").attr("data-plyr") ==
        "play"
      ) {
        $("button.plyr__control.plyr__controls__item").click();
      }
    },
    async checkBackground() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getCurrentBackground();
        if (response.data.success == true) {
          //this.background = response.data.data.thumbnail
          if (
            response.data.data.thumbnail != null &&
            response.data.data.thumbnail != "undefined"
          ) {
            this.activeBackground = "#000000b5";
          } else {
            this.activeBackground = "";
          }
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    checkScreenSize() {
      /*axios.get('http://localhost:3000/api/v1/splash').then(response =>{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    console.log(response.data)
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 }).catch(e => {   
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   console.log(e) 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  })*/
      this.screenWidth = $(window).width();
      this.windowHeight = $(window).height();
      this.windowWidth = $(window).width();

      if (this.screenWidth >= 100 && this.screenWidth <= 374) {
        if (this.prog.length >= 1) {
          this.numOfProg2 = true;
        } else {
          this.numOfProg2 = false;
        }

        if (this.pass > 1) {
          this.numOfProg1 = true;
        } else {
          this.numOfProg1 = false;
        }
      }
      if (this.screenWidth >= 375 && this.screenWidth <= 559) {
        if (this.prog.length >= 2) {
          this.numOfProg2 = true;
        } else {
          this.numOfProg2 = false;
        }
        if (this.pass >= 2) {
          this.numOfProg1 = true;
        } else {
          this.numOfProg1 = false;
        }
      }
      if (this.screenWidth >= 560 && this.screenWidth <= 768) {
        if (this.prog.length >= 3) {
          this.numOfProg2 = true;
        } else {
          this.numOfProg2 = false;
        }
        if (this.pass > 3) {
          this.numOfProg1 = true;
        } else {
          this.numOfProg1 = false;
        }
      }
      if (this.screenWidth >= 769 && this.screenWidth <= 1024) {
        if (this.prog.length >= 4) {
          this.numOfProg2 = true;
        } else {
          this.numOfProg2 = false;
        }
        if (this.pass > 4) {
          this.numOfProg1 = true;
        } else {
          this.numOfProg1 = false;
        }
      }
      if (this.screenWidth >= 1025 && this.screenWidth <= 1399) {
        if (this.prog.length >= 5) {
          this.numOfProg2 = true;
        } else {
          this.numOfProg2 = false;
        }
        if (this.pass > 5) {
          this.numOfProg1 = true;
        } else {
          this.numOfProg1 = false;
        }
      }
      if (this.screenWidth >= 1400) {
        if (this.prog.length >= 6) {
          this.numOfProg2 = true;
        } else {
          this.numOfProg2 = false;
        }
        if (this.pass > 6) {
          this.numOfProg1 = true;
        } else {
          this.numOfProg1 = false;
        }
      }
    },
  },
};
</script>
