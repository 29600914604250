<template>
  <div
    class="mb-5"
    id="saved"
    v-bind:style="{ background: activeBackground, height: screenHeight }"
  >
    <div class="row">
      <div class="col-lg-8">
        <h2 class="favh2">My Favourites</h2>
      </div>
      <div class="col-lg text-right searchIcon">
        <input
          v-model="keywords"
          type="text"
          placeholder="Search Favourites"
          class="search"
        />
      </div>
    </div>

    <div class="mt-4" v-if="programs.length == 0">
      <h6 class="text-center p-5">You have no favourites.</h6>
    </div>
    <div v-else class="workouts header pb-5">
      <div class="pRow">
        <router-link
          class="pColumn htod"
          :to="{ name: 'SPrograms', params: { id: ins.id } }"
          v-for="(ins, index) in programs"
          :key="'category' + index"
        >
          <div class="pCard">
            <div class="workout">
              <img
                class="m-0 card-img-top"
                :src="
                  ins.thumbnail_urls != null ? ins.thumbnail_urls.large : ''
                "
              />
              <!--<i class="fas fa-play-circle"></i>-->
              <div class="detailsrow pr-details">
                <div class="detailsColumn1">
                  <p class="pn">{{ ins.name }}</p>
                </div>
                <div class="detailsColumn2">
                  <p class="savedpHeartIcon" v-if="ins.bookmarked == false">
                    <font-awesome-icon
                      v-if="!free"
                      v-on:click.prevent="addVideo(ins.id)"
                      :icon="['far', 'heart']"
                      size="lg"
                    />
                    <font-awesome-icon
                      v-else
                      v-on:click.prevent="upgrade()"
                      :icon="['far', 'heart']"
                      size="lg"
                    />
                  </p>
                  <p class="savedpHeartIcon" v-else>
                    <font-awesome-icon
                      v-if="!free"
                      v-on:click.prevent="removeVideo(ins.id)"
                      :icon="['fas', 'heart']"
                      size="lg"
                    />
                    <font-awesome-icon
                      v-else
                      v-on:click.prevent="upgrade()"
                      :icon="['fas', 'heart']"
                      size="lg"
                    />
                  </p>
                </div>
                <!-- <p class="ph">{{ins.host.name}}</p> -->
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// import custom from '../../javascript/custom'
import UserService from "../../../services/userService";
import axios from "axios";
import router from "@/router";
import $ from "jquery";

export default {
  name: "SavedPage",
  // props: ['window'],
  data() {
    return {
      page: "",
      sB: 0,
      sR: 0,
      sT: 0,
      screenWidth: 0,
      windowHeight: 0,
      windowWidth: 0,
      screenHeight: "100%",
      items: [],
      programs: [],
      user: {},
      keywords: "",
      plan: {},
      id: "",
      prog: [],
      paginate: ["programs"],
      current: 1,
      pages: 0,
      activeBackground: "",
      free: this.$cookies.get("subscription"),
    };
  },
  created() {
    //this.id = this.$cookies.get("user").id;
    window.addEventListener("resize", this.checkScreenSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  mounted() {
    this.windowHeight = $(window).height();
    this.windowWidth = $(window).width();
    this.checkBackground();
    // this.plan = this.$cookies.get("user");
    // this.plan = this.plan.current_subscription;
    if (this.$cookies.get("subscription") == "false") {
      // this.$cookies.set("subscription", false);
      router.push({
        path: "/member/plans",
      });
    } else {
      this.getSaved();
    }

    // else{
    //   this.getSaved();
    // }
  },
  computed: {},
  components: {},
  watch: {
    keywords: "searchSaved",
  },
  methods: {
    upgrade() {
      this.$bus.$emit("upgrade");
    },
    async getSaved() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getSaved();
        if (response.data.success == true) {
          // this.items = response.data.data;
          this.programs = response.data.data.programs;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
          this.checkScreenSize();
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },

    async searchSaved() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.searchSaved(this.keywords);
        if (response.data.success == true) {
          this.programs = response.data.data.programs;
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async addVideo(bid) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.saveProgram(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getSaved();
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async removeVideo(bid) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.removeProgram(bid);
        if (response.data.success == true) {
          // this.books = (response.data.data)
          this.getSaved();
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          //console.log("error");
          alert = {
            text: response.data.errors[0],
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async checkBackground() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getCurrentBackground();
        if (response.data.success == true) {
          //this.background = response.data.data.thumbnail
          // console.log(response.data.data.thumbnail)
          if (
            response.data.data.thumbnail != null &&
            response.data.data.thumbnail != "undefined"
          ) {
            this.activeBackground = "#000000b5";
          } else {
            this.activeBackground = "";
          }
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    checkScreenSize() {
      // console.log()
      this.screenWidth = $(window).width();
      this.windowHeight = $(window).height();
      this.windowWidth = $(window).width();
      if (this.screenWidth <= 500) {
        if (this.programs.length == 0) {
          this.screenHeight = this.windowHeight + "px";
        }
      } else {
        // console.log()
      }
    },
  },
};
</script>
