var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-0",attrs:{"id":"account"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3"},[_c('h2',[_vm._v("My Account")]),_c('div',{staticClass:"sidenav mt-4"},[_c('nav',{staticClass:"nav flex-column"},[_c('router-link',{class:this.$route.name == 'Account'
                ? 'nav-link pl-0 active'
                : 'nav-link pl-0',attrs:{"to":{ name: 'Account' }},nativeOn:{"click":function($event){return _vm.loadPage('profile', 0)}}},[_vm._v("User Profile")]),_c('router-link',{class:this.$route.params.typ == 'background'
                ? 'nav-link pl-0 active'
                : 'nav-link pl-0 ',attrs:{"to":{ name: 'My Account', params: { typ: 'background' } }},nativeOn:{"click":function($event){return _vm.loadPage('background', 1)}}},[_vm._v("Background")]),_c('router-link',{class:this.$route.params.typ == 'payment'
                ? 'nav-link pl-0 active'
                : 'nav-link pl-0 ',attrs:{"to":{ name: 'My Account', params: { typ: 'payment' } }},nativeOn:{"click":function($event){return _vm.loadPage('payment', 2)}}},[_vm._v("Payment Details")]),_c('router-link',{class:this.$route.params.typ == 'subscription'
                ? 'nav-link pl-0 active'
                : 'nav-link pl-0 ',attrs:{"to":{ name: 'My Account', params: { typ: 'subscription' } }},nativeOn:{"click":function($event){return _vm.loadPage('subscriptions', 3)}}},[_vm._v("Subscription")]),_c('router-link',{class:this.$route.params.typ == 'billing'
                ? 'nav-link pl-0 active'
                : 'nav-link pl-0 ',attrs:{"to":{ name: 'My Account', params: { typ: 'billing' } }},nativeOn:{"click":function($event){return _vm.loadPage('billing', 4)}}},[_vm._v("Billing History")]),_c('a',{staticClass:"nav-link pl-0",on:{"click":function($event){return _vm.logout()}}},[_vm._v("Log Out")])],1)])]),_c('div',{staticClass:"col-lg-9"},[(this.$route.params.typ == 'subscription')?_c('Subscriptions'):(this.$route.params.typ == 'payment')?_c('Payment'):(this.$route.params.typ == 'billing')?_c('Billing'):(this.$route.params.typ == 'background')?_c('Background'):_c('Profile')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }