<template>
  <div id="account" class="pt-0">
    <div class="row">
      <div class="col-lg-3">
        <h2>My Account</h2>
        <div class="sidenav mt-4">
          <nav class="nav flex-column">
            <router-link
              :to="{ name: 'Account' }"
              :class="
                this.$route.name == 'Account'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0'
              "
              @click.native="loadPage('profile', 0)"
              >User Profile</router-link
            >
            <router-link
              :to="{ name: 'My Account', params: { typ: 'background' } }"
              :class="
                this.$route.params.typ == 'background'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('background', 1)"
              >Background</router-link
            >
            <router-link
              :to="{ name: 'My Account', params: { typ: 'payment' } }"
              :class="
                this.$route.params.typ == 'payment'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('payment', 2)"
              >Payment Details</router-link
            >
            <router-link
              :to="{ name: 'My Account', params: { typ: 'subscription' } }"
              :class="
                this.$route.params.typ == 'subscription'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('subscriptions', 3)"
              >Subscription</router-link
            >
            <router-link
              :to="{ name: 'My Account', params: { typ: 'billing' } }"
              :class="
                this.$route.params.typ == 'billing'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('billing', 4)"
              >Billing History</router-link
            >
            <a class="nav-link pl-0" @click="logout()">Log Out</a>
          </nav>
        </div>
      </div>
      <div class="col-lg-9">
        <Subscriptions
          v-if="this.$route.params.typ == 'subscription'"
        ></Subscriptions>
        <Payment v-else-if="this.$route.params.typ == 'payment'"></Payment>
        <Billing v-else-if="this.$route.params.typ == 'billing'"></Billing>
        <Background
          v-else-if="this.$route.params.typ == 'background'"
        ></Background>
        <Profile v-else></Profile>
      </div>
    </div>
  </div>
</template>

<script>
// import custom from '../../../javascript/custom'
import Profile from "./profile";
import Payment from "./payment";
import Billing from "./billing";
import Subscriptions from "./subscriptions";
import router from "@/router";
import Background from "./background";
import AuthService from "../../../services/authService";
import axios from "axios";

export default {
  name: "AccountPage",
  data() {
    return {
      page: "profile",
      plan: "",
    };
  },
  created() {
    // this.plan =  localStorage.getItem("Jam")
  },
  computed: {},
  mounted() {
    // this.plan = localStorage.getItem("Jam");
  },
  components: {
    Profile,
    Subscriptions,
    Payment,
    Billing,
    Background,
  },
  methods: {
    loadPage(pg) {
      this.page = pg;
      // custom.updateAccNav(na)
    },
    // logout() {
    //     this.$session.clear();
    //     sessionStorage.clear();
    //     localStorage.clear();
    //     this.$session.destroy();
    //     router.push({
    //         path: "/",
    //     });
    // },
    async logout() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      var alert = {};
      try {
        const response = await AuthService.logoutUser();
        if (response.data.success == true) {
          //bt.classList.add("success");
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };

          //bt.innerText = response.data.flash.notice
          this.$session.clear();
          sessionStorage.clear();
          localStorage.clear();
          this.$session.destroy();

          this.$cookies.remove("tk");
          this.$cookies.remove("user");
          this.$cookies.remove("role");
          this.$cookies.remove("subscription");
          //     .keys()
          //     .forEach((cookie) => this.$cookies.remove(cookie));

          router.push({
            path: "/",
          });
          this.$bus.$emit("flash", alert);

          setTimeout(function () {}.bind(this), 900);
        } else {
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
