<template>
  <div>
    <div class="table-list users-list mt-4 mb-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0">
            <b>Schedule</b>
            <router-link
              to="/admin/schedules/new"
              v-if="can('schedule:create') || can('admin')"
              ><button class="form-btn ml-4">Add New</button></router-link
            >
          </h4>
        </div>
      </div>
      <div class="table-responsive-sm">
        <table v-if="schedule.length > 0" class="table boxtable">
          <thead>
            <tr>
              <th scope="col">Day</th>
              <th scope="col"># Programme(s)</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              class="link_row"
              :to="
                can('schedule:edit') || can('admin')
                  ? { name: 'Edischedule', params: { id: schedule.id } }
                  : '#'
              "
              v-for="(schedule, index) in schedule"
              :key="schedule + index"
            >
              <tr class="table-det spacer">
                <td>
                  <span>{{ schedule.day }}</span>
                </td>
                <td>
                  <span>{{ schedule.programs.length }}</span>
                </td>
                <td>
                  <span>
                    <font-awesome-icon
                      v-if="can('schedule:delete') || can('admin')"
                      v-on:click.prevent="remItem(schedule.id)"
                      class="float-right"
                      :icon="['fal', 'times']"
                  /></span>
                </td>
              </tr>
            </router-link>
          </tbody>
        </table>
        <h5 v-else class="mx-auto text-center p-3">No schedules Available</h5>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";

export default {
  name: "SchedulePage",
  data() {
    return {
      paginate: ["items"],
      lim: 10,
      schedule: [],
      current: 1,
      pages: 0,
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getSchedule(this.current);
  },
  components: {},
  methods: {
    async getSchedule(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getSchedule(page);
        if (response.data.success == true) {
          this.schedule = response.data.data;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    remItem(litem) {
      if (confirm("Do you really want to delete?")) {
        this.remove(litem);
      }
    },
    async remove(it) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.deleteSchedule(it);
        if (response.data.success == true) {
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          this.getSchedule();
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
