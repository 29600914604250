<template>
  <div id="main-layout">
    <div
      v-if="salert"
      class="alert wal alert-dismissible fade show"
      :class="alert.type"
      role="alert"
      :style="alert.type ? 'display:block;position:fixed ' : 'display:none'"
    >
      <div id="alert">
        <p class="alert-heading m-0">
          <strong>{{ alert.head }}</strong>
          <span class="alert-text ml-2">{{ alert.text }}</span>
        </p>
      </div>
    </div>
    <div class="sess-det">
      <div v-if="flash" class="alert" :class="flash_type" role="alert">
        {{ flash_msg }}
      </div>

      <nav class="navbar navbar-expand-lg main-nav wh mb-0">
        <router-link
          class="navbar-brand ml-0"
          to="/admin/dashboard"
          @click.native="page = ''"
          ><img class="dash-logo" src="../../assets/irie-logo@2x.png"
        /></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"
            ><i class="fas fa-bars fa-lg"></i
          ></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav nav">
            <router-link
              class="routeLink"
              data-toggle=""
              data-target=""
              v-if="can('programme:read') || can('admin')"
              to="/admin/programmes"
              >Programmes</router-link
            >
            <router-link
              class="routeLink"
              v-if="can('background:read') || can('admin')"
              to="/admin/backgrounds"
              >Backgrounds</router-link
            >
            <router-link
              class="routeLink"
              v-if="can('splash:read') || can('admin')"
              to="/admin/splashs"
              >Splash</router-link
            >
            <router-link
              class="routeLink"
              data-toggle=""
              data-target=""
              v-if="can('plan:read') || can('admin')"
              to="/admin/plans"
              >Plans</router-link
            >
            <router-link
              class="routeLink"
              data-toggle=""
              data-target=""
              v-if="can('payment:read') || can('admin')"
              to="/admin/payments"
              >Payments</router-link
            >
            <router-link
              class="routeLink"
              data-toggle=""
              data-target=""
              v-if="can('user:read') || can('admin')"
              to="/admin/users"
              >Users</router-link
            >
            <router-link
              class="routeLink"
              data-toggle=""
              data-target=""
              v-if="can('coupon:read') || can('admin')"
              to="/admin/coupons"
              >Coupons</router-link
            >
            <router-link
              class="routeLink"
              data-toggle=""
              data-target=""
              v-if="can('schedule:read') || can('admin')"
              to="/admin/schedules"
              >Schedule</router-link
            >
            <router-link
              class="routeLink"
              data-toggle=""
              data-target=""
              v-if="can('ad:read') || can('admin')"
              to="/admin/ads"
              >ADs</router-link
            >
            <router-link
              class="routeLink"
              data-toggle=""
              data-target=""
              to="/admin/profile"
              >My Profile</router-link
            >
          </div>
        </div>
      </nav>
      <div class="mainR admin">
        <router-view :window="window" @myEvent="logout" />
      </div>
    </div>
  </div>
</template>

<script>
    import custom from "../../javascript/custom";
    import $ from "jquery";
    import router from "@/router";

    export default {
        name: "AdminPage",
        data() {
            return {
                page: "",
                flash: false,
                flash_type: "",
                flash_msg: "",
                promo: false,
                id: "",
                window: {
                    width: 0,
                    height: 0,
                },
                alert: {
                    text: "",
                    head: "",
                    type: "",
                },

                salert: false,
                screenWidth: 0,
                windowHeight: 0,
                windowWidth: 0,
            };
        },
        created() {
            // this.id = this.$cookies.get("user");
            // this.id = this.id.id
            window.addEventListener("resize", this.checkScreenSize);
        },
        destroyed() {
            window.removeEventListener("resize", this.checkScreenSize);
        },
        computed: {},
        beforeUpdate() {},
        updated() {},
        mounted() {
            this.checkScreenSize();
            this.windowHeight = $(window).height();
            this.windowWidth = $(window).width();
            // this.getProfile();
            this.$bus.$on("cusup", () => {
                // this.getProfile();
            });
            this.$bus.$on("flash", (data) => {
                this.alert.text = data.text;
                // console.log(   this.alert.text)
                if (
                    (this.alert.text != [] && this.alert.text != undefined) ||
                    this.alert.text != "" ||
                    (this.alert.text[0] != "" && !Array.isArray(this.alert.text[0]))
                ) {
                    // console.log( this.alert.text)
                    // this.alert.text = this.alert.text[0];
                    // console.log( this.alert.text)
                } else if (this.alert.text == []) {
                    this.alert.text = "";
                } else if (Array.isArray(this.alert.text[0])) {
                    this.alert.text = this.alert.text[0][0];
                    // console.log( 'b',this.alert.text)
                }
                this.alert.head = data.head;

                this.alert.type = data.type;
                this.salert = true;
                setTimeout(
                    function() {
                        if (document.getElementById("alert") != null) {
                            document.getElementById("alert").classList.remove("d-block");
                        }
                        this.removeAl();
                    }.bind(this),
                    5000
                );
            });
        },

        Updated() {
            this.actSlide();
        },
        components: {},

        methods: {
            removeAl() {
                this.salert = false;
                delete this.alert.type;
            },
            logout() {
                this.$session.clear();
                sessionStorage.clear();
                localStorage.clear();
                this.$session.destroy();
                router.push({
                    path: "/",
                });
            },

            actSlide() {
                const slider = document.querySelector(".erow");
                let isDown = false;
                let startX;
                let scrollLeft;

                slider.addEventListener("mousedown", (e) => {
                    isDown = true;
                    slider.classList.add("active");
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener("mouseleave", () => {
                    isDown = false;
                    slider.classList.remove("active");
                });
                slider.addEventListener("mouseup", () => {
                    isDown = false;
                    slider.classList.remove("active");
                });
                slider.addEventListener("mousemove", (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 3; //scroll-fast
                    slider.scrollLeft = scrollLeft - walk;
                    // console.log(walk);
                });
            },

            loadPage(pg, na) {
                this.page = pg;
                custom.updateNav(na);
                $(".navbar-toggler").click();
            },
            checkScreenSize() {
                // console.log()
                this.screenWidth = $(window).width();
                this.windowHeight = $(window).height();
                this.windowWidth = $(window).width();
                if (this.screenWidth <= 991) {
                    var route = document.getElementsByClassName("routeLink");
                    for (var x = 0; x < route.length; x++) {
                        route[x].setAttribute("data-toggle", "collapse");
                        route[x].setAttribute("data-target", "#navbarNavAltMarkup");
                    }
                    //  console.log(route[1])
                } else {
                    route = document.getElementsByClassName("routeLink");
                    for (x = 0; x < route.length; x++) {
                        route[x].setAttribute("data-toggle", "");
                        route[x].setAttribute("data-target", "");
                    }
                }
            },
        },
    };
</script>