<template>
  <div id="new_user" class="col-lg-12 mt-4 adacc">
    <div class="col-lg-12">
      <h3><b>My Profile</b> <a class="log" @click="logout()">Logout</a></h3>
      <form class="" @submit.prevent="createUser">
        <div class="mt-5">
          <div class="row mb-3">
            <div class="col-lg-4 col-md-8">
              <input
                type="text"
                v-model="details.profile.firstname"
                class="input-field form-control"
                placeholder="First Name"
                required
              />
            </div>
            <div class="col-lg-4 col-md-8">
              <input
                type="text"
                v-model="details.profile.lastname"
                class="input-field form-control"
                placeholder="Last Name"
                required
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-8">
              <input
                type="email"
                v-model="details.profile.email"
                class="input-field form-control"
                placeholder="Email Address"
                required
              />
            </div>
          </div>

          <label
            @click="resetpass = !resetpass"
            class="col-lg-12 pl-0 cap pri rest"
            >Reset Password</label
          >
          <div v-if="resetpass" class="row mt-3">
            <p class="mt-2 passp col-lg-8 col-md-8">
              Your password must contain at least 8 characters, including a
              minimum of one lowercase letter, an uppercase letter, a unique
              character and a number.
            </p>

            <div class="col-lg-8 col-md-8">
              <label>New Password</label>
              <input
                :type="passwordFieldType"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&()*+,-./:;<=>@[\]^_`{|}~\x22\x27])[A-Za-z\d!#$%&()*+,-./:;<=>@[\]^_`{|}~\x22\x27]{8,}$"
                title="Your password must contain at least 8 characters, including a minimum of one lowercase letter, an uppercase letter, a unique character and a number."
                v-on:keyup="validate"
                v-on:keydown="validate"
                v-model="details.profile.password"
                class="input-field form-control"
                placeholder=""
                minlength="8"
              />
              <span type="password" @click="switchVisibility"
                ><i class="far fa-eye"></i
              ></span>
            </div>
            <div class="col-lg-8 col-md-8">
              <label>Confirm New Password</label>
              <input
                :type="confirmpasswordFieldType"
                v-on:keyup="validate"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&()*+,-./:;<=>@[\]^_`{|}~\x22\x27])[A-Za-z\d!#$%&()*+,-./:;<=>@[\]^_`{|}~\x22\x27]{8,}$"
                title="Your password must contain at least 8 characters, including a minimum of one lowercase letter, an uppercase letter, a unique character and a number."
                v-on:keydown="validate"
                v-model="details.profile.password_confirmation"
                class="input-field form-control"
                placeholder=""
                minlength="8"
              />
              <span type="password" @click="switchVisibilityconfirm"
                ><i class="far fa-eye"></i
              ></span>
            </div>
            <span class="col-lg-12 mb-3 form-helper nomatch">
              Password must match the one typed before.
            </span>
          </div>

          <button id="saveUser" type="submit" class="form-btn">Update</button>
        </div>
      </form>
      <h4><b>Geofencing</b></h4>
      <hr>
      <p>Block access to streams for unlicensed countries outside of Jamaica.</p>
      <form class="" @submit.prevent="toggleGeofence">
        <div class="mt-4">
          <div class="row">
            <div class="col-lg-8">
              <label for="geofencing-toggle">
                <input
                type="checkbox"
                id="geofencing-toggle"
                name="geofencing-toggle"
                v-model="isGeofenceEnabled"
              />
                <span style="font-weight: 500;"> Enable Geofencing</span>
              </label>
            </div>
          </div>
          <button type="submit" class="form-btn">
            <span v-if="!this.isLoading">Save</span>
            <img v-else src="@/assets/loader.svg" alt="loader">
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import custom from "../../../javascript/custom";
import AdminService from "../../../services/adminService";
import axios from "axios";
// import router from "@/router";

export default {
  name: "ProfilePage",
  data() {
    return {
      details: {
        profile: {
          password: "",
          email: "",
          firstname: "",
          lastname: "",
          password_confirmation: "",
        },
      },
      confirm_password: "",
      passwordFieldType: "password",
      confirmpasswordFieldType: "password",
      roles: [],
      id: "",
      resetpass: false,
      avatar: "",
      isGeofenceEnabled: false,
      isLoading: false,
      plans: [],
      current: 1,
      bx: 0,
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.id = this.$session.get("prof");
    this.getRoles();
    this.getUser();
    this.isGeofenceEnabled = this.fetchGeofence();
  },
  components: {},
  methods: {
    selBx(i) {
      this.bx = i;
    },
    logout() {
      this.$emit("myEvent");
    },

    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      if (this.passwordFieldType == "text") {
        custom.showPassword(0);
      } else {
        custom.hidePassword(0);
      }
    },
    switchVisibilityconfirm() {
      this.confirmpasswordFieldType =
        this.confirmpasswordFieldType === "password" ? "text" : "password";
      if (this.confirmpasswordFieldType == "text") {
        custom.showPassword(1);
      } else {
        custom.hidePassword(1);
      }
    },
    validate() {
      if (this.details.profile.password != "") {
        if (
          this.details.profile.password !=
          this.details.profile.password_confirmation
        ) {
          custom.validatePassword(false);
        } else {
          custom.validatePassword(true);
        }
      }
    },
    async getRoles() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getRoles();
        if (response.data.success == true) {
          this.roles = response.data.data;
        } else {
          //
        }
      } catch (err) {
        //
      }
    },
    async createUser() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        var bt = document.getElementById("saveUser");

        const response = await AdminService.updateProfile(this.details);
        if (response.data.success == true) {
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
        } else {
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Update User";
          }, 1500);
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        // err
      }
    },
    async getUser() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getProfile();
        if (response.data.success == true) {
          this.details.profile = response.data.data.profile;
          //  console.log(this.disable)
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    getThumb(event) {
      const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 4) {
        alert("File size exceeds 4 MB");
        this.$refs.thumbupload.value = null;
      } else {
        const fr = new FileReader();
        fr.onloadend = function () {
          this.details.profile.avatar = fr.result;
        }.bind(this);

        fr.readAsDataURL(event.target.files[0]);
      }
    },
    async fetchGeofence() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
        try {
          const response = await AdminService.fetchGeofence();

          if (response.data){
            this.isGeofenceEnabled = response.data.is_enabled;
          }
      } catch (err) {
        this.isLoading = false
      }
    },
    async toggleGeofence() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");

        try {
          this.isLoading = true
          // Send the status of the checkbox
          const response = await AdminService.toggleGeofence(this.isGeofenceEnabled);
        if (response.status == 204 || response.status == 200 ) {
          this.isLoading = false;
          if (this.isGeofenceEnabled) {
            this.$toast.success('Geofencing Enabled.', {
              position: 'bottom-right'
            })
          } else {
            this.$toast.warning('Geofencing Disabled.', {
              position: 'bottom-right'
            })
          }


        } else {
          this.isLoading = false
        }
      } catch (err) {
        this.isLoading = false
      }
    },
  },
};
</script>
