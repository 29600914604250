<template>
  <!--<div class="">-->
  <div class="">
    <div class="plans col-lg-10 offset-md-1">
      <h4 class="text-center">Select a plan</h4>
      <div class="all-plans row mt-3 justify-content-center">
        <div v-for="(pl, index) in plans" :key="pl + index" class="rlink pl">
          <router-link
            v-if="pl.price > 0"
            :to="{ name: 'PlanShow', params: { id: pl.id } }"
            @click.native="selectPlan(pl)"
          >
            <div class="text-center planArea">
              <div class="planSection1">
                <h4>{{ pl.name }}</h4>
              </div>
              <div class="planSection2">
                <!-- <button class="trbtn" v-if="pl.trial_period_amount >0">{{pl.trial_period_amount}}-<span class="capi">{{pl.trial_period_unit}}</span> Free Trial</button> -->
                <p class="pldes text-center">{{ pl.description }}</p>
              </div>
              <div class="planSection3">
                <p class="aftrpr" v-if="pl.trial_period_amount > 0">
                  Then ${{ pl.price }} {{ pl.currency
                  }}<span
                    >/
                    <span v-if="pl.subscription_period_amount > 1"
                      >{{ pl.subscription_period_amount }}&nbsp; days</span
                    ><span v-else> day</span></span
                  >
                </p>
              </div>
              <div class="planSection4">
                <button class="text-center" v-if="pl.trial_period_amount > 0">
                  Get Free Trial
                </button>
                <button class="text-center" v-else-if="pl.price != 'free'">
                  ${{ pl.price }} {{ pl.currency
                  }}<span
                    >/
                    <span v-if="pl.subscription_period_amount > 1"
                      >{{ pl.subscription_period_amount }}&nbsp; days</span
                    >
                    <span v-else>day</span></span
                  >
                </button>
                <button class="text-center" v-else>{{ pl.price }}</button>
              </div>
            </div>
          </router-link>

          <div v-else v-on:click="selectFreePlan(pl.id)">
            <div class="text-center planArea">
              <div class="planSection1">
                <h4>{{ pl.name }}</h4>
              </div>
              <div class="planSection2">
                <!-- <button class="trbtn" v-if="pl.trial_period_amount >0">{{pl.trial_period_amount}}-<span class="capi">{{pl.trial_period_unit}}</span> Free Trial</button> -->
                <p class="pldes text-center">{{ pl.description }}</p>
              </div>
              <div class="planSection3">
                <p class="aftrpr" v-if="pl.trial_period_amount > 0">
                  Then ${{ pl.price }} {{ pl.currency
                  }}<span
                    >/
                    <span v-if="pl.subscription_period_amount > 1"
                      >{{ pl.subscription_period_amount }}&nbsp; days</span
                    ><span v-else> day</span></span
                  >
                </p>
              </div>
              <div class="planSection4">
                <button class="text-center" v-if="pl.trial_period_amount > 0">
                  Get Free Trial
                </button>
                <button class="text-center" v-else-if="pl.price != 'free'">
                  ${{ pl.price }} {{ pl.currency
                  }}<span
                    >/
                    <span v-if="pl.subscription_period_amount > 1"
                      >{{ pl.subscription_period_amount }}&nbsp; days</span
                    >
                    <span v-else>day</span></span
                  >
                </button>
                <button class="text-center" v-else>{{ pl.price }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <h5 class="text-center mt-3">
        Do you have a coupon or gift certificate?
      </h5>
      <p class="text-center pb-2">
        <button @click="navigate()" class="form-btn">Redeem It Now</button>
      </p>
    </div>
  </div>
</template>

<script>
// import custom from '../../javascript/custom'
import UserService from "../../../services/userService";
import axios from "axios";
import router from "@/router";

export default {
  name: "PlansPage",
  data() {
    return {
      plans: [],
      user: {
        auto_plan_id: "",
      },
    };
  },
  created() {
    this.getPlans();
  },
  computed: {},
  mounted() {},
  components: {},
  methods: {
    //  hasHistory () { return window.history.length > 2 },
    async getPlans() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getPlans();
        if (response.data.success == true) {
          this.plans = response.data.data.records;
          // this.$session.set("ptok", response.data.data.token);
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    selectPlan(pl) {
      this.$session.set("plan", pl);
      this.plan = this.$session.get("plan");
    },
    async selectFreePlan(pl) {
      // console.log("hi");
      this.user.auto_plan_id = pl;
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.updateProfile(this.user);
        if (response.data.success == true) {
          // this.$bus.$emit("cusup");
          // this.plan = response.data.data.profile.current_subscription;
          // this.$cookies.set("user", response.data.data.profile);
          var pp = response.data.data.profile.current_subscription;
          if (pp != null) {
            this.$cookies.set("subscription", true);
          } else {
            this.$cookies.set("subscription", false);
          }
          this.$bus.$emit("subUp");
          alert = {
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          setTimeout(
            function () {
              router.push({
                path: "/member/dashboard",
              });
            }.bind(this),
            900
          );
        } else {
          alert = {
            text: "Profile update failed.",
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    navigate() {
      sessionStorage.setItem("backgroundChange", "yes");
      router.push({
        path: "/member/redeem",
      });
    },
  },
};
</script>
