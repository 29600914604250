<template>
  <div class="mt-4 mb-4">
    <div class="pt-4">
      <div class="row">
        <div class="col-sm-3">
          <img
            class="proimg"
            v-if="details.program.thumbnail"
            :src="details.program.thumbnail"
          />
        </div>
        <div class="col-sm-6">
          <h3>
            {{ details.program.name }}
            <label class="switch ml-4 mb-0 tt">
              <input
                type="checkbox"
                v-model="details.program.published"
                disabled
              />
              <span class="slider round"></span>
            </label>
          </h3>
          <p class="proho mb-4">{{ details.program.host.name }}</p>
          <p class="prodes">{{ details.program.description }}</p>
          <div class="row">
            <div class="col-sm-4">
              <h5 class="d-inline mr-5 proh5">Type</h5>
            </div>
            <div class="col-sm-4">
              <span>{{ details.program.program_type }}</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-4">
              <h5 class="d-inline mr-5 proh5">Category</h5>
            </div>
            <div class="col-sm-4">
              <span v-if="details.program.category">{{
                details.program.category.name
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-sm-3 text-right">
          <router-link
            :to="{ name: 'EditProgramme', params: { id: details.program.id } }"
            ><button class="form-btn ml-4">Edit Programme</button></router-link
          >
        </div>
      </div>
    </div>
    <br />
    <div class="bkwh table-list mt-4">
      <div class="row">
        <div class="col-sm-6">
          <h4>Episodes</h4>
        </div>
        <div class="col-sm-6 text-right">
          <router-link
            v-if="can('episode:create') || can('admin')"
            :to="{ name: 'NSessions', params: { id: details.program.id } }"
            ><button class="form-btn mb-0 mt-0">
              Add Episode
            </button></router-link
          >
        </div>
      </div>
      <div v-if="episodes.length > 0" class="table-responsive-sm">
        <table class="table boxtable">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Date</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              class="link_row"
              :to="
                can('episode:edit') || can('admin')
                  ? { name: 'EditSession', params: { id: sess.id, pro: id  } }
                  : '#'
              "
              v-for="(sess, index) in episodes"
              :key="sess + index"
            >
              <tr class="table-det spacer">
                <td>
                  <span>{{ sess.title }}</span>
                </td>
                <td>
                  <span>{{ moment(sess.start_at).format("LL") }}</span>
                </td>
                <td>
                  <span v-if="sess.published"
                    >Published
                    <font-awesome-icon
                      v-if="can('episode:delete') || can('admin')"
                      v-on:click.prevent="remItem(sess.id)"
                      class="float-right"
                      :icon="['fal', 'times']"
                    /> </span
                  ><span v-else
                    >Unpublished
                    <font-awesome-icon
                      v-if="can('episode:delete') || can('admin')"
                      v-on:click.prevent="remItem(sess.id)"
                      class="float-right"
                      :icon="['fal', 'times']"
                    />
                  </span>
                </td>
              </tr>
            </router-link>
          </tbody>
        </table>
      </div>
      <h5 v-else class="mx-auto text-center p-3">No Episodes Available</h5>
      <ul class="pl-0 paginate-links" v-if="pages > 1">
        <li v-if="pages > 0" @click="getSession(1)" class="left-arrow disabled">
          <a>«</a>
        </li>

        <li
          @click="getSession(p)"
          :class="p == current ? 'number active pg' : 'number pg'"
          v-for="(p, index) in pages"
          :key="pag + index"
        >
          <span v-if="Math.abs(p - current) < 4 || p == pages || p == 1">
            <a
              :class="{
                current: current === p,
                last: p == pages && Math.abs(p - current) > 4,
                first: p == 1 && Math.abs(p - current) > 4,
              }"
              >{{ p }}</a
            >
          </span>
        </li>
        <li
          v-if="pages > 0"
          @click="getSession(pages)"
          class="right-arrow disabled"
        >
          <a>»</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";
// import router from '@/router'

export default {
  name: "ShowProgramPage",
  data() {
    return {
      details: {
        program: {
          code: null,
          days_valid: null,
          discount_value: null,
          discount_type: null,
          plan_ids: [],
          limit: 0,
          tag_ids: [],
          header_background: "",
          thumbnail: "",
        },
      },
      plans: [],
      categories: [],
      episodes: [],
      tags: [],
      id: "",
      paginate: ["items"],
      lim: 10,
      current: 1,
      pages: 0,
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  computed: {},
  mounted() {
    this.getProgram();
    this.getSession(this.current);
  },
  components: {},
  methods: {
    async getProgram() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");

      try {
        const response = await AdminService.showProgram(this.id);
        if (response.data.success == true) {
          this.details.program = response.data.data.program;

          this.details.program.thumbnail =
            response.data.data.program.thumbnail_urls.large;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getSession(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.showProgramSession(this.id, page);
        // console.log("session")
        if (response.data.success == true) {
          // alert("hi")
          this.episodes = response.data.data;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    remItem(litem) {
      if (confirm("Do you really want to delete?")) {
        this.remove(litem);
      }
    },
    async remove(it) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.deleteSession(it);
        if (response.data.success == true) {
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          this.getSession();
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
