<template>
  <div>
    <div class="table-list users-list mt-4 mb-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0">
            <b>ADs</b>
            <router-link
              to="/admin/ads/new"
              v-if="can('ad:create') || can('admin')"
              ><button class="form-btn ml-4">Add New</button></router-link
            >
          </h4>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-3 filselect col-md-3">
          <v-select
            :options="status"
            placeholder="Sort Member ADs"
            v-model="stat_sel.filter.key"
            :reduce="(label) => label"
            label="name"
          />
        </div>
        <div class="col-lg-2 col-md-2">
          <button @click="getFilAds()" class="form-btn mt-1">Sort</button>
        </div>
      </div>

      <div class="table-responsive-sm">
        <table v-if="ads.length > 0" class="table boxtable">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
              <th scope="col">AD Starts</th>
              <th scope="col">AD Ends</th>
              <th scope="col">AD Impressions</th>
              <th scope="col">Priority</th>
              <th scope="col">Order</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              class="link_row"
              :to="
                can('ad:edit') || can('admin')
                  ? { name: 'EditAd', params: { id: ad.id } }
                  : '#'
              "
              v-for="(ad, index) in ads"
              :key="ad + index"
            >
              <tr class="table-det spacer">
                <td>
                  <span>{{ ad.name }}</span>
                </td>
                <td>
                  <span>{{ ad.ad_type }}</span>
                </td>
                <td>
                  <span>{{ moment(ad.start_at).format("LL") }}</span>
                </td>
                <td>
                  <span v-if="ad.ad_limit == 'impression'"
                    >{{ ad.impression_limit }} Impressions</span
                  >
                  <span v-else-if="ad.ad_limit == 'end-date'"
                    >{{ moment(ad.end_at).format("LL") }}
                  </span>
                </td>
                <td>
                  <span>{{ ad.impressions.length }}</span>
                </td>
                <td>
                  <span>{{ ad.priority }}</span>
                </td>
                <td>
                  <span>{{ ad.ordering }}</span>
                </td>
                <td class="urltxt">
                  <span v-if="ad.published == true">Published </span
                  ><span v-else>Unpublished </span>
                </td>
                <td>
                  <span>
                    <font-awesome-icon
                      v-on:click.prevent="remItem(ad.id)"
                      :icon="['fal', 'times']"
                      v-if="can('ad:delete') || can('admin')"
                    />
                  </span>
                </td>
              </tr>
            </router-link>
          </tbody>
        </table>
        <h5 v-else class="mx-auto text-center p-3">No ADs Available</h5>
      </div>
      <ul class="pl-0 paginate-links" v-if="pages > 1">
        <li v-if="pages > 0" @click="getAds(1)" class="left-arrow disabled">
          <a>«</a>
        </li>

        <li
          @click="getAds(p)"
          :class="p == current ? 'number active pg' : 'number pg'"
          v-for="(p, index) in pages"
          :key="pag + index"
        >
          <span v-if="Math.abs(p - current) < 4 || p == pages || p == 1">
            <a
              :class="{
                current: current === p,
                last: p == pages && Math.abs(p - current) > 4,
                first: p == 1 && Math.abs(p - current) > 4,
              }"
              >{{ p }}</a
            >
          </span>
        </li>
        <li
          v-if="pages > 0"
          @click="getAds(pages)"
          class="right-arrow disabled"
        >
          <a>»</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";

export default {
  name: "AdsPage",
  data() {
    return {
      paginate: ["items"],
      lim: 10,
      ads: [],
      current: 1,
      pages: 0,
      status: ["Priority", "Order", "Random"],
      stat_sel: {
        filter: {
          key: null,
        },
      },
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getAds(this.current);
    this.getFil();
  },
  components: {},
  methods: {
    async getAds(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getFilterAD(page, this.stat_sel);
        if (response.data.success == true) {
          this.ads = response.data.data;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getFil() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.filterAd();
        if (response.data.success == true) {
          this.stat_sel.filter.key = response.data.data.ad_filter.key;
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getFilAds() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.filterAds(this.stat_sel);
        if (response.data.success == true) {
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          this.getAds();
          // //bt.innerText = response.data.flash.notice
        } else {
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    remItem(litem) {
      if (confirm("Do you really want to delete?")) {
        this.remove(litem);
      }
    },
    async remove(it) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.deleteAd(it);
        if (response.data.success == true) {
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          this.getAds();
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
