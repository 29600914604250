<template>
  <div class="d-flex justify-content-center backCover">
    <div class="current-background">
      <div class="backgroundDiv mt-3" v-if="custom == true">
        <h3 @click="selectTab(true, $event)" class="supportedPlan activeTab">
          USE ONE OF OURS
        </h3>
        <h3 @click="selectTab(false, $event)" class="supportedPlan">
          UPLOAD YOUR OWN
        </h3>
      </div>
      <div class="mt-3" v-else>
        <h3 class="notSupportedPlan text-center">CHOOSE A BACKGROUND</h3>
      </div>
      <div v-if="tab == true" class="col-lg-12 col-md-12 col-sm-12 pt-3">
        <div v-if="counter > 0">
          <carousel
            :perPageCustom="[
              [582, per_Page],
              [100, perPage],
            ]"
            :navigationEnabled="true"
            navigationNextLabel="<i class='fas fa-angle-right nav-arrow-icon'></i>"
            navigationPrevLabel="<i class='fas fa-angle-left nav-arrow-icon' ></i>"
          >
            <slide v-for="(p, index) in backgroundContent" :key="p.id">
              <div
                @click="chosenBackground(index, p.id, $event)"
                class="backGroundImg"
              >
                <img :src="p.thumbnail" class="img-thumb" />{{ p.index }}
              </div>
            </slide>
          </carousel>

          <button
            id="savebackground"
            @click="addBackground()"
            type="button"
            class="form-btn mb-3"
          >
            Add Background
          </button>
        </div>
        <h5 v-else class="mx-auto text-center p-3 notAvailable">
          No Backgrounds Available
        </h5>
      </div>

      <div v-else class="col-lg-12 col-md-12 col-sm-12 pt-3">
        <div class="uploadarea">
          <button type="button" class="form-btn-img imgbtn mb-2">
            CHOOSE IMAGE
          </button>
          <input
            id="primg"
            type="file"
            @change="getThumb"
            ref="thumbupload"
            accept="images*"
            required="details.background.thumbnail.includes('base64') ? true : false"
          />
          <img
            class="ml-5 av"
            v-if="image.background.thumbnail"
            :src="image.background.thumbnail"
          />
        </div>
        <div class="text-center">
          <button
            id="saveImg"
            @click="createBackground()"
            type="button"
            class="form-btn mb-3"
          >
            Add Background
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import UserService from "../../../services/userService";
import axios from "axios";
import router from "@/router";
//import $ from "jquery";

export default {
  name: "BackgroundPage",
  data() {
    return {
      components: {
        Carousel,
        Slide,
      },
      image: {
        background: {
          thumbnail: "",
          user_id: "",
        },
      },
      backgroundContent: [],
      imgId: "",
      tab: true,
      custom: "",
      arr: {
        background: {
          thumbnail: null,
        },
      },
      counter: 0,
      numOfcount: 1,
      perPage: 0,
      per_Page: 0,
    };
  },
  created() {
    this.getBackground(1);
    this.getProfile();
    this.getCurrentUserId();
  },
  computed: {},
  beforeMount() {
    /*if(sessionStorage.getItem("backgroundChange")=="yes"){
                                             sessionStorage.removeItem("backgroundChange");
                                             if ($(window).width() <= 991){
                                              router.go(0);
                                             }
                                            }*/
  },
  mounted() {},
  components: {},
  methods: {
    async getBackground(num) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getBackground(num);
        if (response.data.success == true) {
          //this.numOfcount=response.data.pagination.total_pages
          for (var j = 0; j < response.data.data.length; j++) {
            if (response.data.data[j].created_by == "admin") {
              this.backgroundContent.push(response.data.data[j]);
              this.counter++;
            }
          }
          if (this.counter < 3) {
            this.perPage = this.counter;
            this.per_Page = this.counter;
          } else {
            this.perPage = 2;
            this.per_Page = 3;
          }

          if (this.numOfcount != response.data.pagination.total_pages) {
            if (response.data.pagination.total_pages > 1) {
              this.numOfcount++;
              this.getBackground(this.numOfcount);
            }
          }
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    chosenBackground(index, id, event) {
      if (this.activeLink) {
        this.activeLink.classList.remove("highlight");
      }
      this.activeLink = event.target;
      this.activeLink.classList.add("highlight");
      this.getBase64FromUrl(this.backgroundContent[index].thumbnail);
      this.imgId = id;
    },
    selectTab(option, event) {
      var current = document.getElementsByClassName("activeTab");
      current[0].className = current[0].className.replace("activeTab", "");
      if (this.activeLink) {
        this.activeLink.classList.remove("activeTab");
      }
      this.activeLink = event.target;
      this.activeLink.classList.add("activeTab");
      this.tab = option;
    },

    async addBackground() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        var bt = document.getElementById("savebackground");
        const response = await UserService.setBackground(
          this.imgId,
          this.image
        );
        if (response.data.success == true) {
          ////bt.classList.add("success");
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          sessionStorage.setItem("backgroundChange", "yes");
          this.$bus.$emit("flash", alert);
          // //bt.innerText = response.data.flash.notice
          setTimeout(function () {
            router.push({
              path: "/member/dashboard",
            });
            //router.go(0);
          }, 500);
        } else {
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Save Background";
          }, 1500);
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },

    async createBackground() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        var bt = document.getElementById("saveImg");
        const response = await UserService.uploadCustomImage(this.image);
        if (response.data.success == true) {
          this.imgId = response.data.data.id;
          this.image.background.thumbnail = response.data.data.thumbnail;
          this.image.background.user_id = response.data.data.user_id;

          this.addBackground();
          //alert = {
          // text: "",
          // head: response.data.flash.notice,
          //type: "alert-success d-block",
          //};
          //sessionStorage.setItem("backgroundChange", "yes");
          //this.$bus.$emit("flash", alert);
          // //bt.innerText = response.data.flash.notice
          //setTimeout(function () {
          //router.push({ path: "/member/dashboard" });
          //router.go(0);
          //}, 500);
        } else {
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Save Background";
          }, 1500);
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    getThumb(event) {
      const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 4) {
        alert("File size exceeds 4 MB");
        this.$refs.thumbupload.value = null;
      } else {
        const fr = new FileReader();
        fr.onloadend = function () {
          this.image.background.thumbnail = fr.result;
        }.bind(this);

        fr.readAsDataURL(event.target.files[0]);
      }
    },
    async getProfile() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile(this.user);
        if (response.data.success == true) {
          this.plan = response.data.data.profile.current_subscription;
          this.custom =
            response.data.data.profile.current_subscription.custom_background;
          // this.$cookies.set("user", response.data.data.profile);
          var pp = response.data.data.profile.current_subscription;
          if (pp != null) {
            this.$cookies.set("subscription", true);
          } else {
            this.$cookies.set("subscription", false);
          }
          //this.custom = true
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getBase64FromUrl(url) {
      const data = await fetch(url);
      var blob = await data.blob();
      //blob.Properties.ContentType = "image/png"
      blob = blob.slice(0, blob.size, "image/png");
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.image.background.thumbnail = reader.result;
      };
    },
    async getCurrentUserId() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile();
        if (response.data.success == true) {
          this.image.background.user_id = response.data.data.profile.id;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
