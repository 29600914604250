<template>
  <div>
    <div class="log-form forgot-form">
      <form @submit.prevent="forgotPass">
        <div class="form-det">
          <h2>Forgot Password</h2>
          <p class="subtext text-center">
            To reset your password, enter the email address you use to sign in.
          </p>
          <input
            type="email"
            v-model="auth.user.login"
            class="input-field form-control"
            placeholder="Email"
            required
          />
          <button type="submit" id="forbtn" class="form-btn">
            Get reset link
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
    import AuthService from "../../services/authService";
    import router from "@/router";

    export default {
        name: "ForgotPage",
        data() {
            return {
                auth: {
                    user: {
                        login: "",
                    },
                },
            };
        },
        created() {},
        computed: {},
        components: {},
        methods: {
            async forgotPass() {
                var alert = {};
                try {
                    const response = await AuthService.forgotPassword(this.auth);
                    var bt = document.getElementById("forbtn");
                    if (response.data.success == true) {
                        //bt.classList.add("success");
                        alert = {
                            text: "",
                            head: response.data.flash.notice,
                            type: "alert-success d-block",
                        };
                        this.$bus.$emit("flash", alert);
                        //bt.innerText = response.data.flash.notice
                        setTimeout(
                            function() {
                                router.push({
                                    path: "/"
                                });
                            }.bind(this),
                            3000
                        );
                    } else {
                        bt.classList.add("fail");
                        bt.innerText = "Please Try Again";
                        setTimeout(function() {
                            bt.classList.remove("fail");
                            bt.innerText = "Get Reset link";
                        }, 1500);
                        alert = {
                            text: response.data.errors,
                            head: response.data.flash.error,
                            type: "alert-danger d-block",
                        };
                        this.$bus.$emit("flash", alert);
                    }
                } catch (err) {
                    //console.log(err)
                }
            },
        },
    };
</script>