<template>
  <div>
    <div class="table-list users-list mt-4 mb-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0">
            <b>Coupons</b>
            <router-link
              v-if="can('coupon:create') || can('admin')"
              to="/admin/coupons/new"
              ><button class="form-btn ml-4">Add New</button></router-link
            >
          </h4>
        </div>
      </div>

      <div class="table-responsive-sm">
        <table class="table boxtable" v-if="coupons.length > 0">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Value</th>
              <th scope="col">Plan</th>
              <th scope="col">Expiry Date</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              class="link_row"
              :to="
                can('coupon:edit') || can('admin')
                  ? { name: 'EditCoupon', params: { id: coupon.id } }
                  : '#'
              "
              v-for="(coupon, index) in coupons"
              :key="coupon + index"
            >
              <tr class="table-det spacer">
                <td>
                  <span>{{ coupon.code }}</span>
                </td>
                <td>
                  <span v-if="coupon.discount_type == 'fixed'"
                    >${{ coupon.discount_value }}</span
                  >

                  <span v-if="coupon.discount_type == 'percentage'"
                    >{{ coupon.discount_value }}%</span
                  >
                </td>
                <td>
                  <span
                    class="mr-2 plsp"
                    v-for="(pl, index) in coupon.plans"
                    :key="plan + index"
                    >{{ pl.name }}</span
                  >
                </td>
                <td>
                  <span>{{ moment(coupon.expire_on).format("LL") }}</span>
                </td>
                <td>
                  <span v-if="coupon.published == true">Active </span>
                  <span v-else>Inactive </span>
                </td>
                <td>
                  <span>
                    <font-awesome-icon
                      v-if="can('coupon:delete') || can('admin')"
                      v-on:click.prevent="remItem(coupon.id)"
                      :icon="['fal', 'times']"
                  /></span>
                </td>
              </tr>
            </router-link>
          </tbody>
        </table>
        <h5 v-else class="mx-auto text-center p-3">No Coupons Available</h5>
      </div>
      <ul class="pl-0 paginate-links" v-if="pages > 1">
        <li v-if="pages > 0" @click="getCoupons(1)" class="left-arrow disabled">
          <a>«</a>
        </li>

        <li
          @click="getCoupons(p)"
          :class="p == current ? 'number active pg' : 'number pg'"
          v-for="(p, index) in pages"
          :key="pag + index"
        >
          <span v-if="Math.abs(p - current) < 4 || p == pages || p == 1">
            <a
              :class="{
                current: current === p,
                last: p == pages && Math.abs(p - current) > 4,
                first: p == 1 && Math.abs(p - current) > 4,
              }"
              >{{ p }}</a
            >
          </span>
        </li>
        <li
          v-if="pages > 0"
          @click="getCoupons(pages)"
          class="right-arrow disabled"
        >
          <a>»</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../../services/adminService";
import axios from "axios";

export default {
  name: "CouponsPage",
  data() {
    return {
      paginate: ["items"],
      lim: 10,
      coupons: [],
      current: 1,
      pages: 0,
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getCoupons(this.current);
  },
  components: {},
  methods: {
    async getCoupons(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getCoupons(page);
        if (response.data.success == true) {
          this.coupons = response.data.data;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    remItem(litem) {
      if (confirm("Do you really want to delete?")) {
        this.remove(litem);
      }
    },
    async remove(it) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.deleteCoupon(it);
        if (response.data.success == true) {
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          this.getCoupons();
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
