<template>
  <div class="offset-md-3 parent-current-payment mb-5">
    <div class="current-payment">
      <div class="col-lg-12" v-if="newCard == false">
        <div v-if="cards != null || cards != undefined || cards.length != 0">
          <div class="row">
            <div v-if="cards.length > 0" class="col-lg-7 col-md-7 col-sm-7 p-0">
              <h3>Current Payment Method</h3>
              <div
                class="col-12 mt-3"
                v-for="(card, index) in cards"
                :key="'card' + index"
              >
                <div class="cucard" v-if="plan == 'fac'">
                  <!-- <div class="cucard"> -->
                  <h6 v-if="card.current_card == true">
                    <i class="fas fa-credit-card mr-3"></i> **** **** ****
                    {{ card.last4 }}&nbsp;
                  </h6>
                  <i
                    v-if="card.brand && card.brand.toLowerCase() == 'visa'"
                    class="fab fa-cc-visa ml-2"
                  ></i>
                  <i
                    v-else-if="
                      card.brand && card.brand.toLowerCase() == 'master'
                    "
                    class="fab fa-cc-mastercard ml-2"
                  ></i>
                  <i
                    v-else-if="
                      card.brand && card.brand.toLowerCase() == 'discover'
                    "
                    class="fab fa-cc-discover ml-2"
                  ></i>
                  <i
                    v-else-if="card.brand && card.brand.toLowerCase() == 'amex'"
                    class="fab fa-cc-amex ml-2"
                  ></i>
                </div>
              </div>
              <div class="payment-pal" v-if="plan == 'paypal'">
                <i class="fab fa-paypal mr-3"></i>Paypal
              </div>
            </div>
          </div>
          <div class="row" v-if="false">
            <button
              v-if="cards != null && cards != undefined"
              type="button"
              @click="showPay()"
              class="form-btn mb-3"
            >
              Change Method
            </button>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-7 col-md-7 col-sm-7 p-0">
            <h3>Registered Cards</h3>
            <div
              v-if="cards != null && cards != undefined"
              class="col-lg-5 col-md-5 col-sm-5 carddet p-0"
            >
              <div
                class="col-12"
                v-for="(card, index) in cards"
                :key="'card' + index"
              >
                <h6>
                  **** **** **** {{ card.last4 }}&nbsp;<a
                    v-if="card.current_card != true"
                    v-on:click.prevent="remItem(card.id)"
                    ><i id="removeBtn" class="fa fa-times pt-3"></i
                  ></a>
                </h6>
                <i
                  v-if="card.brand && card.brand.toLowerCase() == 'visa'"
                  class="fab fa-cc-visa ml-2"
                ></i>
                <i
                  v-else-if="card.brand && card.brand.toLowerCase() == 'master'"
                  class="fab fa-cc-mastercard ml-2"
                ></i>
                <i
                  v-else-if="
                    card.brand && card.brand.toLowerCase() == 'discover'
                  "
                  class="fab fa-cc-discover ml-2"
                ></i>
                <i
                  v-else-if="card.brand && card.brand.toLowerCase() == 'amex'"
                  class="fab fa-cc-amex ml-2"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div v-if="cards.length < 1" class="p-3">
          <h5 id="noPayment" class="text-center pt-3">No card registered.</h5>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <button type="button" @click="newCard = true" class="form-btn mb-2">
              Add Card
            </button>
          </div>
        </div>
      </div>
      <div v-if="newCard == true">
        <form @submit.prevent="newCa">
          <div class="col-lg-12 p-0 pt-3">
            <h3><i class="fal fa-credit-card mr-3"></i> Credit Card Details</h3>
            <p style="color: red">
              {{ error }}
            </p>
            <!-- <input type="text" class="input-field form-control" v-model="nCard.card.name" placeholder="Card Holder Name" required /> -->
            <input
              type="text"
              pattern="[0-9]+"
              id="card-number"
              class="input-field form-control"
              v-model="nCard.payment_source.number"
              maxlength="16"
              minlength="13"
              placeholder="Card Holder Number"
              title="Please enter card number must be no less than 13 digits"
              required
            />
            <i class="fal fa-credit-card cnum"></i>
            <div class="row cc m-0">
              <div class="col-lg-4 col-md-4 col-sm-4 pl-0 input-field1">
                <input
                  type="text"
                  pattern="[0-9]+"
                  id="card-ex"
                  class="input-field form-control"
                  v-model="nCard.payment_source.expiry_date"
                  maxlength="4"
                  minlength="4"
                  placeholder="MMYY"
                  title="Please enter expiry month and year in the format MMYY"
                  required
                />

                <!-- <input type="text" id="expiry-year" class="input-field form-control " placeholder="Year" required /> -->
                <i class="far fa-calendar"></i>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 pr-0">
                <input
                  type="text"
                  pattern="[0-9]+"
                  id="security-code"
                  v-model="nCard.payment_source.cvv"
                  class="input-field form-control"
                  minlength="3"
                  maxlength="3"
                  placeholder="CVV"
                  title="Please enter cvv"
                  required
                />
                <i class="fal fa-lock"></i>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <button
                  class="form-btn w-100"
                  type="button"
                  @click="newCard = false"
                >
                  Cancel
                </button>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6">
                <button id="payButton" type="submit" class="form-btn w-100">
                  Add Card
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import payment from '../../../javascript/custom'
// import axios from 'axios'
// import paym from '../../../../public/index.html'
import UserService from "../../../services/userService";
import axios from "axios";

export default {
  name: "PaymentPage",
  data() {
    return {
      error: "",
      payment: "none",
      newCard: false,
      removeId: "",
      cards: [],
      updatePay: false,
      plan: {
        name: "",
        price: "",
        details: "",
        expiry: "",
      },
      autorenew: true,
      url: "",
      tok: "",
      nCard: {
        payment_source: {
          number: "",
          expiry_date: "",
          cvv: "",
        },
      },
    };
  },
  created() {
    //this.id= this.$cookies.get("user").id
  },
  computed: {},
  mounted() {
    //this.plan = this.$cookies.get("user");
    // this.plan = this.$cookies.get("user").current_subscription;
    this.plan = this.$cookies.get("pay");
    this.getCard();
  },
  components: {},
  methods: {
    async showPay() {
      // this.updatePay=true; async getProfile(){
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile(this.id);
        if (response.data.success == true) {
          this.tok = response.data.data.profile.perishable_token;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    hidePay() {
      this.updatePay = false;
    },
    async newCa() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        var bt = document.getElementById("payButton");
        const response = await UserService.createCard(this.nCard);
        if (response.data.success == true) {
          this.getCard();
          this.newCard = false;
          this.nCard.payment_source.number = "";
          this.nCard.payment_source.expiry_date = "";
          this.nCard.payment_source.cvv = "";
          bt.classList.add("Added");
          alert = {
            text: [],
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          bt.innerText = response.data.flash.notice;
          setTimeout(function () {
            bt.classList.remove("Added");
            bt.innerText = "Card Added";
          }, 500);
          //bt.innerText = response.data.flash.notice
        } else {
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Save Card";
          }, 1500);
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    remItem(litem) {
      if (confirm("Do you really want to delete this card?")) {
        this.removeCa(litem);
      }
    },
    async removeCa(data) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.removeCard(data);
        if (response.data.success == true) {
          this.getCard();
          alert = {
            text: "Card successfully removed",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };

          this.$bus.$emit("flash", alert);
          //bt.innerText = response.data.flash.notice
        } else {
          //console.log("error")
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    pay() {
      // PaymentSession.updateSessionFromForm('card');
    },
    async getCard() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getCard();
        if (response.data.success == true) {
          this.cards = response.data.data.records;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
