<template>
  <div id="new_user" class="col-lg-12 mt-4 adacc">
    <h3><b>New Role</b></h3>
    <div class="">
      <form @submit.prevent="createRole">
        <div class="mt-4">
          <div class="row mb-3">
            <div class="col-lg-8 col-md-8">
              <!-- <label>Role Name *</label> -->
              <input
                type="text"
                v-model="details.role.label"
                class="input-field form-control"
                placeholder="Role Name"
                required
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-12 mt-4 pri cap mb-3">Permissions</label>
          </div>
          <div
            class="row mb-3 permis"
            v-for="(per, index) in details.role.permissions_attributes"
            :key="per.id"
          >
            <div class="col-sm-3">
              <v-select
                :options="
                  options.filter((o) => per.permissable_model.indexOf(o) < 0)
                "
                v-model="per.permissable_model"
                :reduce="(name) => name"
                label="name"
                required
              />
            </div>
            <div class="col-sm-2">
              <div class="chbx">
                <input
                  type="checkbox"
                  v-model="per.can_read"
                  name="progtype"
                  class="check"
                  placeholder=""
                  value="read"
                />
                Can Read
              </div>
            </div>
            <div class="col-sm-2">
              <div class="chbx">
                <input
                  type="checkbox"
                  v-model="per.can_create"
                  name="progtype"
                  class="check"
                  placeholder=""
                  value="create"
                />
                Can Create
              </div>
            </div>
            <div class="col-sm-2">
              <div class="chbx">
                <input
                  type="checkbox"
                  v-model="per.can_edit"
                  name="progtype"
                  class="check"
                  placeholder=""
                  value="edit"
                />
                Can Edit
              </div>
            </div>
            <div class="col-sm-2">
              <div class="chbx">
                <input
                  type="checkbox"
                  v-model="per.can_delete"
                  name="progtype"
                  class="check"
                  placeholder=""
                  value="delete"
                />
                Can Delete
              </div>
            </div>
            <div class="col-sm-1" v-if="per.id != 0">
              <div class="chbx" @click="remPer(index)">
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
          <br />
          <div class="mt-4">
            <p class="cap cur" @click="addInput">
              <strong><i class="fas fa-plus"></i> Add a permission</strong>
            </p>
          </div>
          <router-link to="/admin/roles"
            ><button class="form-btn mr-4 cancel">Cancel</button></router-link
          >

          <button type="submit" id="saveRole" class="form-btn">Add Role</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../../services/adminService";
import axios from "axios";
import router from "@/router";

export default {
  name: "NewRolePage",
  data() {
    return {
      details: {
        role: {
          label: "",
          permissions_attributes: [
            {
              permissable_model: "",
              can_read: false,
              can_edit: false,
              can_create: false,
              can_delete: false,
            },
          ],
        },
      },
      options: [
        "Background",
        "Category",
        "Coupon",
        "Episode",
        "Payment",
        "Plan",
        "Programme",
        "User",
        "AD",
        "Role",
        "Schedule",
        "Splash",
        "Host",
      ],
      counter: 0,
    };
  },
  created() {},
  computed: {},
  mounted() {},
  components: {},
  methods: {
    remPer(p) {
      if (confirm("Do you really want to delete?")) {
        this.details.role.permissions_attributes.splice(p, p);
      }
    },
    addInput() {
      this.details.role.permissions_attributes.push({
        permissable_model: "",
        can_read: false,
        can_edit: false,
        can_create: false,
        can_delete: false,
      });
    },

    async createRole() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        var bt = document.getElementById("saveRole");
        const response = await AdminService.createRole(this.details);
        if (response.data.success == true) {
          //bt.classList.add("success");
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          //bt.innerText = response.data.flash.notice
          setTimeout(function () {
            router.push({
              path: "/admin/roles",
            });
          }, 500);
        } else {
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Save Role";
          }, 1500);
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
