<template>
  <div>
    <div class="table-list users-list mt-4 mb-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0">
            <b>Splash</b>
            <span class="d-none">
              <router-link to="/admin/splashs/new"
                ><button class="form-btn ml-4">Add New</button></router-link
              >
            </span>
            <!-- <span v-bind:class="{ 'd-none': count != 0 }">
              <a> <button class="form-btn ml-4 d-none">Add New</button></a>
            </span> -->
          </h4>
        </div>
      </div>
      <!-- <paginate
        name="items"
        :list="splashs"
        :per="lim"
        class="pl-0"
      > -->
      <div class="table-responsive-sm">
        <table class="table box2table" v-if="count > 0">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Name</th>
              <!-- <th scope="col">Status</th> -->
              <th scope="col">Upload Date</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              class="link_row"
              :to="{ name: 'EditSplash', params: { id: splash.id } }"
              v-for="(splash, index) in splashs"
              :key="splash + index"
            >
              <tr class="table-det spacer">
                <td>
                  <span class="img"
                    ><img v-if="splash.thumbnail" :src="splash.thumbnail"
                  /></span>
                </td>
                <td>
                  <span>{{ splash.name }}</span>
                </td>
                <!-- <td>  <span v-if="splash.published == true"
                >Active</span><span v-else> Inactive</span></td> -->
                <td class="urltxt">
                  <span>
                    {{ moment(splash.updated_at).format("ll") }}
                  </span>
                </td>
                <td>
                  <!-- <span>
                  <font-awesome-icon
                    v-on:click.prevent="remItem(splash.id)"
                   
                    :icon="['fal', 'times']"
                    v-if="can('splash:delete') || can('admin')"
                  />
                </span> -->
                </td>
              </tr>
            </router-link>
          </tbody>
        </table>
        <h5 v-else class="mx-auto text-center p-3">No splash Available</h5>
      </div>
      <!-- <ul class="pl-0 paginate-links" v-if="pages > 1">
        <li
          v-if="pages > 0"
          @click="getSplashs(1)"
          class="left-arrow disabled"
        >
          <a>«</a>
        </li>

        <li
          @click="getSplashs(p)"
          :class="p == current ? 'number active pg' : 'number pg'"
          v-for="(p, index) in pages"
          :key="pag + index"
        >
          <span v-if="Math.abs(p - current) < 4 || p == pages || p == 1">
            <a
              :class="{
                current: current === p,
                last: p == pages && Math.abs(p - current) > 4,
                first: p == 1 && Math.abs(p - current) > 4,
              }"
              >{{ p }}</a
            >
          </span>
        </li>
        <li
          v-if="pages > 0"
          @click="getSplashs(pages)"
          class="right-arrow disabled"
        >
          <a>»</a>
        </li>
      </ul>-->
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";

export default {
  name: "SplashsPage",
  data() {
    return {
      paginate: ["items"],
      lim: 10,
      splashs: [],
      current: 1,
      pages: 0,
      count: 0,
    };
  },
  created() {
    this.getSplashs(this.current);
  },
  computed: {},

  mounted() {},
  components: {},
  methods: {
    async getSplashs(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getSplashs(page);
        if (response.data.success == true) {
          this.splashs = response.data.data;
          //this.pages = response.data.pagination.total_pages;
          //this.current = response.data.pagination.page;
          this.count = response.data.data.length;
        } else {
          this.count = 0;
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    remItem(litem) {
      if (confirm("Do you really want to delete?")) {
        this.remove(litem);
      }
    },
    async remove(it) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.deletesplashs(it);
        if (response.data.success == true) {
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          this.getSplashs();
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
