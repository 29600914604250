<template>
  <div id="new_user" class="col-lg-12 mt-4 adacc">
    <div class="col-lg-12">
      <h3><b>New Schedule</b></h3>
      <form class="mt-5" @submit.prevent="createSchedule">
        <div class="row mb-3">
          <div class="col-lg-8 col-md-8">
            <!-- <label></label> -->
            <v-select
              :options="days"
              placeholder="Day fo the Week"
              :multiple="false"
              v-model="details.schedule.day"
              :reduce="(name) => name"
              label="label"
              required
            />
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-lg-12 mt-4 pri cap mb-3">Programmes</label>
        </div>
        <div class="sch-prog">
          <div
            class="mb-4 permis"
            v-for="(pro, index) in details.schedule.programs_attributes"
            :key="pro.id"
          >
            <div class="row">
              <div class="col-sm-4">
                <div>
                  <v-select
                    class="prog"
                    :options="programs"
                    :multiple="false"
                    v-model="pro.program_id"
                    :reduce="(name) => name.id"
                    label="name"
                    placeholder="Program"
                    required
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div>
                  <v-select
                    class="prog"
                    :options="hosts"
                    :multiple="false"
                    v-model="pro.host"
                    :reduce="(name) => name.id"
                    label="name"
                    placeholder="Host"
                    required
                  />
                </div>
              </div>
              <div class="col-sm-2">
                <div>
                  <datetime
                    type="time"
                    :use12-hour="u12"
                    zone="local"
                    value-zone="local"
                    placeholder="Start"
                    input-class="input-field form-control"
                    v-model="pro.start_at"
                    format="HH:mm:ss"
                    required
                  ></datetime>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="">
                  <datetime
                    type="time"
                    :use12-hour="u12"
                    zone="local"
                    value-zone="local"
                    format="HH:mm:ss"
                    placeholder="End"
                    input-class="input-field form-control"
                    v-model="pro.end_at"
                    required
                  ></datetime>
                </div>
              </div>

              <div class="col-sm-1" v-if="pro.id != 0">
                <div @click="remPer(index)">
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <div class="mt-4">
          <p class="cap cur" @click="addInput">
            <strong><i class="fas fa-plus"></i> Add Program</strong>
          </p>
        </div>
        <router-link to="/admin/schedules"
          ><button class="form-btn mr-4 cancel">Cancel</button></router-link
        >

        <button id="saveUser" type="submit" class="form-btn">
          Add Schedule
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";
import router from "@/router";

export default {
  name: "NewSchedulePage",
  data() {
    return {
      details: {
        schedule: {
          day: null,
          programs_attributes: [
            {
              program_id: null,
              host: null,
              thumbnail: null,
              start_at: null,
              end_at: null,
              header_background: null,
            },
          ],
        },
      },
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      u12: true,
      programs: [],
      hosts: [],
      pr: 300,
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getPrograms();
    this.getHosts();
  },
  components: {},
  methods: {
    remPer(p) {
      if (confirm("Do you really want to delete?")) {
        this.details.schedule.programs_attributes.splice(p, p);
      }
    },
    addInput() {
      this.details.schedule.programs_attributes.push({
        name: null,
        host: null,
        thumbnail: null,
        start_at: null,
        end_at: null,
        header_background: null,
      });
    },
    async getHosts() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getScHosts();
        if (response.data.success == true) {
          this.hosts = response.data.data;
          //  console.log(this.disable)
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getPrograms() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getScPrograms(this.pr);
        if (response.data.success == true) {
          this.programs = response.data.data;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },

    async createSchedule() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      this.details.schedule.programs_attributes.forEach((item) => {
        item.start_at = item.start_at.split("T")[1];
        item.end_at = item.end_at.split("T")[1];
      });
      try {
        var bt = document.getElementById("saveUser");
        const response = await AdminService.createSchedule(this.details);
        if (response.data.success == true) {
          ////bt.classList.add("success");
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          // //bt.innerText = response.data.flash.notice
          setTimeout(function () {
            router.push({
              path: "/admin/schedules",
            });
          }, 500);
        } else {
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Save Schedule";
          }, 1500);
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    getThumb(event) {
      const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 4) {
        alert("File size exceeds 4 MB");
        this.$refs.thumbupload.value = null;
      } else {
        const fr = new FileReader();
        fr.onloadend = function () {
          this.details.schedule.thumbnail = fr.result;
        }.bind(this);

        fr.readAsDataURL(event.target.files[0]);
      }
    },
    getHead(event) {
      const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 4) {
        alert("File size exceeds 4 MB");
        this.$refs.headupload.value = null;
      } else {
        const fr = new FileReader();
        fr.onloadend = function () {
          this.details.schedule.header_background = fr.result;
        }.bind(this);

        fr.readAsDataURL(event.target.files[0]);
      }
    },
  },
};
</script>
