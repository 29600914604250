import mainInstance from '@/services/api'

export default {
    getCategories() {
        try {
            return mainInstance().get('program-categories');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    showCategory(data) {
        try {
            return mainInstance().get('program-categories/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getPrograms(p) {
        try {
            return mainInstance().get('programs?order_by=ordering&order_dir=asc&page=' + p);
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    getFullPrograms() {
        try {
            return mainInstance().get('programs?order_by=ordering&order_dir=asc&per_page=50');
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    getCouponPlans(data) {
        try {
            return mainInstance().get('coupons?code=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getSession(data, pg) {
        try {
            return mainInstance().get('programs/' + data + '/sessions?order_by=start_at&order_dir=desc&page=' + pg);
        } catch (err) {
            //console.log(err)
        }
    },
    searchSession(data, term, page) {
        try {
            return mainInstance().get('programs/' + data + '/sessions?term=' + term + '&order_by=start_at&order_dir=desc&page=' + page);
        } catch (err) {
            //console.log(err)
        }
    },
    searchProgram(term, page) {
        try {
            return mainInstance().get('programs?term=' + term + '&order_by=start_at&order_dir=desc&page=' + page);
        } catch (err) {
            //console.log(err)
        }
    },
    showSchedule() {
        try {
            return mainInstance().get('schedules');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateQuality(data) {
        try {
            return mainInstance().patch('quality', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getQuality() {
        try {
            return mainInstance().get('quality');
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    showProgram(data) {
        try {
            return mainInstance().get('programs/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    getProgram(data) {
        try {
            return mainInstance().get('programs/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getSeasons() {
        try {
            return mainInstance().get('seasons');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    showSeason(data) {
        try {
            return mainInstance().get('seasons/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    getSessions() {
        try {
            return mainInstance().get('sessions?order_by=start_at&order_dir=desc');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getRecentSessions() {
        try {
            return mainInstance().get('sessions?status=recent&order_by=start_at&order_dir=desc');
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    showSession(data) {
        try {
            return mainInstance().get('sessions/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getProfile() {
        try {
            return mainInstance().get('profile');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateProfile(data) {
        try {
            return mainInstance().put('profile', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    createCard(data) {
        try {
            return mainInstance().post('cards', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    removeCard(data) {
        try {
            return mainInstance().delete('cards/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getPlans() {
        try {
            return mainInstance().get('plans');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getPlan(data) {
        try {
            return mainInstance().get('plans/' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    weekSessions(from, to, tag) {
        try {
            return mainInstance().get('sessions?from=' + from + '&to=' + to + tag + '&platforms=all&order_dir=asc');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getSaved() {
        try {
            return mainInstance().get('bookmarks');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    searchSaved(data) {
        try {
            return mainInstance().get('bookmarks?term=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    saveSession(data) {
        try {
            return mainInstance().post('sessions/' + data + '/bookmark');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    removeSession(data) {
        try {
            return mainInstance().delete('sessions/' + data + '/bookmark');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getAds() {
        try {
            return mainInstance().get('ads');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    saveAdImp(data) {
        try {
            return mainInstance().post('ads/' + data + '/impressions');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    saveProgram(data) {
        try {
            return mainInstance().post('programs/' + data + '/bookmark');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    removeProgram(data) {
        try {
            return mainInstance().delete('programs/' + data + '/bookmark');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    removeSubscription() {
        try {
            return mainInstance().delete('fac/unsubscribe');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    newSubscription(data) {
        try {
            return mainInstance().post('fac/subscribe', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    newPaypalSubscription(data) {
        try {
            return mainInstance().post('paypal/subscribe', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    approvePaypalSubscription(data) {
        try {
            return mainInstance().get('paypal/approve?subscription_id=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    getSplash() {
        try {
            return mainInstance().get('splash');
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    getBackground(data) {
        try {
            return mainInstance().get('backgrounds?page=' + data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    getCurrentBackground() {
        try {
            return mainInstance().get('backgrounds/current_background');
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    setBackground(id, data) {
        try {
            return mainInstance().patch('backgrounds/' + id, data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    uploadCustomImage(data) {
        try {
            return mainInstance().post('backgrounds', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    getCard() {
        try {
            return mainInstance().get('cards');
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    getSubAuto() {
        try {
            return mainInstance().get('subscription');
        } catch (err) {
            //console.log(err)
            return err
        }
    },
    updateSubAuto(data) {
        try {
            return mainInstance().put('subscription', data);
        } catch (err) {
            //console.log(err)
            return err
        }
    },

    getSubPay(page) {
        try {
            return mainInstance().get('payments?per_page=6&page=' + page);
        } catch (err) {
            //console.log(err)
            return err
        }
    },
}