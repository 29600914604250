<template>
  <div class="pt-4">
    <div class="table-list users-list mt-4 mb-4">
      <div class="row">
        <div class="col-lg-4 col-md-4 d-flex">
          <h4 class="mb-0"><b>Payments</b></h4>
        </div>
        <div class="col-lg-5 col-md-5">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <datetime
                type="date"
                zone="local"
                title="From Date"
                placeholder="From Date"
                value-zone="EST"
                input-class="search"
                v-model="filfrom"
              ></datetime>
            </div>
            <div class="col-lg-6 col-md-6">
              <datetime
                type="date"
                zone="local"
                title="To Date"
                placeholder="To Date"
                value-zone="EST"
                :min-datetime="filfrom"
                input-class="search"
                v-model="filto"
              ></datetime>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 rbtn">
          <download-csv
            :data="payments_arr"
            class="form-btn csvbtn mt-1"
            name="Payments.csv"
          >
            Download CSV
          </download-csv>
        </div>
      </div>

      <div class="table-responsive-sm">
        <table class="table boxtable" v-if="plans.length > 0">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Amount</th>
              <th scope="col">Plan</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="table-det spacer"
              v-for="(plan, index) in plans"
              :key="plan + index"
            >
              <td>
                <span>{{ plan.customer_name }}</span>
              </td>
              <td>
                <span>{{ plan.customer_email }}</span>
              </td>
              <td>
                <span>${{ plan.amount | numFormat }} </span>
              </td>
              <td>
                <span>{{ plan.plan_name }}</span>
              </td>
              <td>
                <span>{{ moment(plan.paid_on).format("LL") }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 v-else class="mx-auto text-center p-3">No Payments Available</h5>
      </div>
      <ul class="pl-0 paginate-links" v-if="pages > 1">
        <li
          v-if="pages > 0"
          @click="getTransactions(1)"
          class="left-arrow disabled"
        >
          <a>«</a>
        </li>

        <li
          @click="getTransactions(p)"
          :class="p == current ? 'number active pg' : 'number pg'"
          v-for="(p, index) in pages"
          :key="pag + index"
        >
          <span v-if="Math.abs(p - current) < 4 || p == pages || p == 1">
            <a
              :class="{
                current: current === p,
                last: p == pages && Math.abs(p - current) > 4,
                first: p == 1 && Math.abs(p - current) > 4,
              }"
              >{{ p }}</a
            >
          </span>
        </li>
        <li
          v-if="pages > 0"
          @click="getTransactions(pages)"
          class="right-arrow disabled"
        >
          <a>»</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";

export default {
  name: "TransactionsPage",
  data() {
    return {
      paginate: ["plans"],
      lim: 10,
      plans: [],
      fildate: "",
      filto: "",
      filfrom: "",
      current: 1,
      pages: 0,
      payments_arr: [],
      counter: 1,
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getTransactions(this.current);
  },

  components: {},
  watch: {
    // filfrom:'getTransactions(current)',
    // filto:'getTransactions(current)'
    filfrom() {
      this.getTransactions(this.current);
    },
    filto() {
      this.getTransactions(this.current);
    },
  },
  methods: {
    async getTransactions(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getTransactions(
          this.filfrom,
          this.filto,
          page
        );
        if (response.data.success == true) {
          this.plans = response.data.data.records;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
          this.counter = 1;
          this.payments_arr = [];
          this.popu_payments(
            response.data.pagination.total_pages,
            1,
            response.data.data.records
          );
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },

    async getItems(date) {
      alert(date);
    },
    async popu_payments(max, num, data) {
      if (this.counter <= max) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + this.$session.get("token");
        try {
          const response = await AdminService.getTransactions(
            this.filfrom,
            this.filto,
            num
          );
          if (response.data.success == true) {
            for (var i = 0; i < response.data.data.records.length; i++) {
              this.payments_arr.push(response.data.data.records[i]);
            }
            this.counter++;
            this.popu_payments(max, this.counter, data);
          }
        } catch (err) {
          //console.log(err)
        }
      } else {
        if (max == 1) {
          this.payments_arr = data;
        }
      }
    },
  },
};
</script>
