<template>
  <div class="main-det-background mb-5">
    <div class="offset-md-3">
      <div class="current-payment">
        <div class="row">
          <div
            v-if="background != null && background != 'undefined'"
            class="mx-auto text-center backgroundDiv"
          >
            <h3 class="text-center pb-3">Current Background</h3>
            <div class="mb-4">
              <img
                class="ml-0 text-center av"
                v-if="background"
                :src="background"
              />
            </div>

            <button
              v-if="
                (sub == 'true' && can('regular_background:edit')) ||
                can('vip') ||
                can('custom_background:edit')
              "
              @click="changeBackground()"
              type="button"
              class="form-btn mb-3"
            >
              Change Background
            </button>

            <button
              v-else
              v-on:click="upgrade()"
              type="button"
              class="form-btn mb-3"
            >
              Change Background
            </button>
          </div>
          <div v-else>
            <h5 class="mx-auto text-center p-3">No Background Set.</h5>

            <button
              v-if="
                (sub == 'true' && can('regular_background:edit')) ||
                can('vip') ||
                can('custom_background:edit')
              "
              @click="changeBackground()"
              type="button"
              class="form-btn mb-3 ml-3 cs"
            >
              Set Background
            </button>

            <button
              v-else
              type="button"
              class="form-btn mb-3 ml-3"
              v-on:click="upgrade()"
            >
              Set Background
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../../../services/userService";
import axios from "axios";
import router from "@/router";

export default {
  name: "BackgroundPage",
  data() {
    return {
      background: null,
      sub: "",
    };
  },
  created() {
    this.getCurrentBackground();
  },
  computed: {},
  mounted() {
    this.sub = this.$cookies.get("subscription");
  },
  components: {},
  methods: {
    upgrade() {
      this.$bus.$emit("upgrade");
    },
    async getCurrentBackground() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getCurrentBackground();

        if (response.data.success == true) {
          // console.log(response.data)
          this.background = response.data.data.thumbnail;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    changeBackground() {
      sessionStorage.setItem("backgroundChange", "yes");
      setTimeout(function () {
        router.push({
          path: "/member/background",
        });
        //router.go(0);
      }, 500);
    },
  },
};
</script>
