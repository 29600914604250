<template>
  <div>
    <div class="log-form forgot-form">
      <form @submit.prevent="activateUser">
        <div class="form-det" v-if="this.auth.user.pin != ''">
          <br />
          <h2>Activating Account</h2>
          <br />

          <p>Please wait</p>
          <br />
          <div class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="form-det" v-else>
          <br />
          <h2>Activate Account</h2>
          <br />
          <br />
          <p>Please create an account to start activation process</p>
        </div>
        <div class="form-det d-none">
          <h2>Activate Account</h2>
          <input
            type="email"
            v-model="auth.user.login"
            class="input-field form-control"
            placeholder="Email"
            required
          />
          <input
            v-model="auth.user.pin"
            class="input-field form-control"
            placeholder="Pin"
            required
          />
          <button id="logBtn" disabled type="submit" class="form-btn">
            Activate
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AuthService from "../../services/authService";
import router from "@/router";
// import $ from "jquery";
import UserService from "../../services/userService";
import axios from "axios";

export default {
  name: "ActivationPage",
  data() {
    return {
      user_role: "",
      auth: {
        user: {
          pin: "",
          login: "",
        },
      },
    };
  },
  created() {},
  beforeMount() {
    // this.$bus.$on("acuser", (data) => {
    //     this.auth.user.login = data;
    // });
    // var urlParams = new URLSearchParams(window.location.search);
    // if (
    //     window.location.href.indexOf("pin") > -1 &&
    //     urlParams.get("pin") != ""
    // ) {
    //     this.auth.user.pin = urlParams.get("pin");
    //     this.auth.user.login = urlParams.get("email");
    //     this.activateUser();
    // }
  },
  mounted() {
    this.$bus.$on("acuser", (data) => {
      this.auth.user.login = data;
    });
    var urlParams = new URLSearchParams(window.location.search);
    if (
      window.location.href.indexOf("pin") > -1 &&
      urlParams.get("pin") != ""
    ) {
      this.auth.user.pin = urlParams.get("pin");
      this.auth.user.login = urlParams.get("email");
      // console.log("hi");
      setTimeout(
        function () {
          this.activateUser();
        }.bind(this),
        3000
      );
      // this.activateUser();
    }
  },
  computed: {},
  components: {},
  methods: {
    async activateUser() {
      var alert = {};
      this.auth.user.login = this.auth.user.login.replace("%20", "+");
      this.auth.user.login = this.auth.user.login.replace(/\s/g, "+");
      try {
        const response = await AuthService.getActivate(this.auth);
        var bt = document.getElementById("logBtn");

        if (response.data.success == true) {
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };

          this.$session.start();
          this.user_role = response.data.data.record.role_symbols;
          var aq = response.data.data.record.audio_quality;
          this.$session.set("token", response.data.data.record.authToken);
          sessionStorage.setItem("token", response.data.data.record.authToken);
          // this.$cookies.set("tk", response.data.data.record.authToken);
          this.$cookies.set("role", response.data.data.record.role_symbols[0]);

          sessionStorage.setItem("logStat", true);
          this.$session.set("aq", aq);

          this.$session.set("loggedIn", true);
          this.$session.set("prof", response.data.data.record.id);
          this.$cookies.set("getaq", 1);
          // var mem_coun = response.data.data.record.country;

          // sessionStorage.setItem(
          //     "user",
          //     JSON.stringify(response.data.data.record.role_symbols[0])
          // );

          if (response.data.data.record.role_symbols.length != 0) {
            this.getProfile();
            this.getPermiss(
              response.data.data.record.role[0].permissions,
              response.data.flash.notice
            );
          } else {
            // if (
            //     response.data.data.record.login_count !=
            //     response.data.data.record.active_sessions_count
            // ) {
            // {
            //     // alert(
            //     //     "Please log out of an exisiting device or browser session to continue"
            //     // );
            // } else
            if (response.data.data.record.permissions == null) {
              // console.log("hi");
              this.getProfile();
              router.push({
                path: "/member/plans",
              });
            } else {
              this.getProfile();
              this.getPermission(
                response.data.data.record.permissions,
                response.data.flash.notice
              );
              // }
            }
          }
        } else {
          bt.classList.add("fail");
          bt.innerText = "Please Try Again";
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Activate";
          }, 1500);
          alert = {
            text: response.data.errors,
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    getPermission(data, flash) {
      // console.log(flash.notice)
      var user_per = data;
      var per = [];
      var count = 1;
      // console.log(user_per);

      for (var i = 0; i < user_per.length; i++) {
        if (user_per[i].can_read == true) {
          per.push(user_per[i].permissable_model.toLowerCase() + ":read");
        }
        if (user_per[i].can_create == true) {
          per.push(user_per[i].permissable_model.toLowerCase() + ":create");
        }
        if (user_per[i].can_edit == true) {
          per.push(user_per[i].permissable_model.toLowerCase() + ":edit");
        }
        if (user_per[i].can_delete == true) {
          per.push(user_per[i].permissable_model.toLowerCase() + ":delete");
        }
        count += 1;
      }

      if (per != null && per != []) {
        // console.log(per, count, user_per);
        if (count > user_per.length) {
          // localStorage.setItem("uperm", JSON.stringify(per));
          // // console.log(per);
          // this.$bus.$emit("uperm");
          localStorage.setItem("permissions", JSON.stringify(per));
          this.$bus.$emit("permission");
        }
      }
      setTimeout(
        function () {
          var alert = {
            text: "",
            head: flash,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          router.push({
            path: "/member/dashboard",
          });
        }.bind(this),
        1000
      );
    },
    getPermiss(data, flash) {
      // console.log(flash.notice)
      var user_per = data;
      var per = [];
      var count = 1;
      var user = this.$cookies.get("role");
      // console.log(user[0] != 'admin')
      if (user != "admin") {
        per.push("dashboard");
        count += 1;
        per.push("profile");
        count += 1;
        for (var i = 0; i < user_per.length; i++) {
          if (user_per[i].can_read == true) {
            per.push(user_per[i].permissable_model.toLowerCase() + ":read");
          }
          if (user_per[i].can_create == true) {
            per.push(user_per[i].permissable_model.toLowerCase() + ":create");
          }
          if (user_per[i].can_edit == true) {
            per.push(user_per[i].permissable_model.toLowerCase() + ":edit");
          }
          if (user_per[i].can_delete == true) {
            per.push(user_per[i].permissable_model.toLowerCase() + ":delete");
          }
          count += 1;
        }
      } else {
        per.push("admin");
        count += 1;
      }

      if (per != null && per != []) {
        // console.log(per,count,flash)
        if (count > user_per.length + 2 || user == "admin") {
          localStorage.setItem("permissions", JSON.stringify(per));
          this.$bus.$emit("permission");
          setTimeout(
            function () {
              var alert = {
                text: "",
                head: flash,
                type: "alert-success d-block",
              };
              this.$bus.$emit("flash", alert);
              router.push({
                path: "/admin/dashboard",
              });
            }.bind(this),
            500
          );
        }
      }
    },
    async getProfile() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile(this.id);
        if (response.data.success == true) {
          var pl = response.data.data.profile.current_subscription;
          if (pl != null) {
            this.$cookies.set("subscription", true);
          } else {
            this.$cookies.set("subscription", false);
          }

          if (response.data.data.profile.current_subscription.price > 0) {
            this.$cookies.set("free", false);
          } else {
            this.$cookies.set("free", true);
          }
          // this.$cookies.set("user", response.data.data.profile);
        }
      } catch (err) {
        console.log("Error");
      }
    },
  },
};
</script>
