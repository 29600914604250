<template>
  <div>
    <div class="table-list users-list mt-4 mb-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0">
            <b>Backgrounds</b>
            <router-link to="/admin/backgrounds/new"
              ><button class="form-btn ml-4">Add New</button></router-link
            >
          </h4>
        </div>
      </div>
      <!-- <paginate
        name="items"
        :list="backgrounds"
        :per="lim"
        class="pl-0"
      > -->
      <div class="table-responsive-sm">
        <table class="table box2table" v-if="counter > 0">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Name</th>
              <th scope="col">Status</th>
              <th scope="col">Upload Date</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              class="link_row"
              :to="{ name: 'EditBackground', params: { id: background.id } }"
              v-for="(background, index) in backgrounds"
              :key="background + index"
            >
              <tr
                v-if="background.created_by == 'admin'"
                class="table-det spacer"
              >
                <td>
                  <span class="img"
                    ><img
                      v-if="background.thumbnail"
                      :src="background.thumbnail"
                  /></span>
                </td>
                <td>
                  <span>{{ background.name }}</span>
                </td>
                <td>
                  <span v-if="background.published == true">Active</span
                  ><span v-else> Inactive</span>
                </td>
                <td class="urltxt">
                  <span>
                    {{ moment(background.created_at).format("ll") }}
                  </span>
                </td>
                <td>
                  <span>
                    <font-awesome-icon
                      v-on:click.prevent="remItem(background.id)"
                      :icon="['fal', 'times']"
                      v-if="can('background:delete') || can('admin')"
                    />
                  </span>
                </td>
              </tr>
            </router-link>
          </tbody>
        </table>
        <h5 v-else class="mx-auto text-center p-3">No Backgrounds Available</h5>
      </div>
      <ul class="pl-0 paginate-links" v-if="pages > 1">
        <li
          v-if="pages > 0"
          @click="getBackgrounds(1)"
          class="left-arrow disabled"
        >
          <a>«</a>
        </li>

        <li
          @click="getBackgrounds(p)"
          :class="p == current ? 'number active pg' : 'number pg'"
          v-for="(p, index) in pages"
          :key="pag + index"
        >
          <span v-if="Math.abs(p - current) < 4 || p == pages || p == 1">
            <a
              :class="{
                current: current === p,
                last: p == pages && Math.abs(p - current) > 4,
                first: p == 1 && Math.abs(p - current) > 4,
              }"
              >{{ p }}</a
            >
          </span>
        </li>
        <li
          v-if="pages > 0"
          @click="getBackgrounds(pages)"
          class="right-arrow disabled"
        >
          <a>»</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";

export default {
  name: "BackgroundsPage",
  data() {
    return {
      paginate: ["items"],
      lim: 10,
      backgrounds: [],
      current: 1,
      pages: 0,
      counter: 0,
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getBackgrounds(this.current);
  },
  components: {},
  methods: {
    async getBackgrounds(page) {
      this.counter = 0;
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getBackgrounds(page);
        if (response.data.success == true) {
          for (var x = 0; x < response.data.data.length; x++) {
            if (response.data.data[x].created_by == "admin") {
              this.counter++;
            }
          }

          this.backgrounds = response.data.data;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    remItem(litem) {
      if (confirm("Do you really want to delete?")) {
        this.remove(litem);
      }
    },
    async remove(it) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.deleteBackgrounds(it);
        if (response.data.success == true) {
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          this.getBackgrounds();
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
