import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
// Client
import ClientRoutesM from '@/router/client-mainLayout'
import ClientRoutesS from '@/router/client-sessionLayout'
//Admin
import AdminRoutesM from '@/router/admin-mainLayout'
import NotFoundComponent from '@/views/404'

Vue.use(Router)

let router = new Router({
    mode: 'history', // to remove the hash
    duplicateNavigationPolicy: 'ignore',
    routes: [
        ClientRoutesM,
        ClientRoutesS,
        AdminRoutesM,
        { path: '*', component: NotFoundComponent }
    ]
})

router.beforeEach((to, from, next) => {
    var x = []

    if (localStorage.getItem('permissions') != "undefined" && localStorage.getItem('permissions') != null) {
        x = localStorage.getItem('permissions')
        x = JSON.parse(x)
    }

    const userPermissions = x

    axios.interceptors.response.use(function(response) {
        return response
    }, function(error) {
        // console.log("error")
        if (error.response.data.error.statusCode === 401) {
            next({
                name: 'Login'
            })
        }
        if (sessionStorage.getItem("vue-session-key") == "{}" && error.response.data.error.statusCode === 403) {
            next({
                name: 'Login'
            })
        }
        return Promise.reject(error)
    })

    // var user = JSON.parse(sessionStorage.getItem('user'))
    var vu = JSON.parse(localStorage.getItem('vue-session-key'))
    var rol = Vue.$cookies.get("role");

    if (vu && vu.user) {

        vu = vu.user.role_symbols
    }

    if (to.matched.some(record => record.meta.requiresAuth) || from.matched.some(record => record.meta.requiresAuth)) {

        // alert("hi") 
        if (localStorage.getItem("vue-session-key") == '{}') {
            window.location.href = '/';
        }
        // else if ((vu == undefined || vu == null) && (user == undefined || user == null)) {

        //     window.location.href = '/';
        // }
        else if (localStorage.getItem("vue-session-key")) {
            // console.log(to.meta.permissions)
            if (to.meta.permissions && to.meta.permissions.length > 0) {
                // if (userPermissions != "undefined" && userPermissions != null) {

                // }
                let isAllowed = userPermissions.some(p => to.meta.permissions.includes(p))

                if (isAllowed == false && (rol != "" && rol != null && rol != undefined)) {
                    var alert = { text: "You are not authorized to perform this action.", head: "Sorry.", type: "alert-warning d-block" }
                    sessionStorage.setItem('flash', JSON.stringify(alert))
                    return next('/admin/dashboard')
                        // console.log(isAllowed,userPermissions)
                } else if (to.matched.some(record => record.meta.is_admin) && (rol != "" && rol != null && rol != undefined)) {

                    next()

                }
            } else {
                next()
                    // if (user.length == 0) {}

            }
        } else {
            window.location.href = '/';
        }

    } else {
        next()
    }
})

export default router