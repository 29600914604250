<template>
  <div>
    <div class="table-list users-list mt-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 d-flex">
          <h4 class="mb-0">
            <b>Categories</b>
            <router-link
              v-if="can('category:create') || can('admin')"
              to="/admin/categories/new"
              ><button class="form-btn ml-4">Add New</button></router-link
            >
          </h4>
        </div>
      </div>

      <table class="table boxtable" v-if="categories.length > 0">
        <thead>
          <tr>
            <th scope="col">Name</th>
          </tr>
        </thead>
        <tbody>
          <router-link
            class="link_row"
            :to="
              can('category:edit') || can('admin')
                ? { name: 'EditCategory', params: { id: us.id } }
                : '#'
            "
            v-for="(us, index) in categories"
            :key="user + index"
          >
            <tr class="table-det spacer">
              <td>
                <span
                  >{{ us.name }}
                  <font-awesome-icon
                    v-if="can('category:delete') || can('admin')"
                    v-on:click.prevent="remItem(us.id)"
                    class="float-right"
                    :icon="['fal', 'times']"
                  />
                </span>
              </td>
            </tr>
          </router-link>
        </tbody>
      </table>
      <h5 v-else class="mx-auto text-center p-3">No Categories Available</h5>

      <ul class="pl-0 paginate-links" v-if="pages > 1">
        <li
          v-if="pages > 0"
          @click="getCategories(1)"
          class="left-arrow disabled"
        >
          <a>«</a>
        </li>

        <li
          @click="getCategories(p)"
          :class="p == current ? 'number active pg' : 'number pg'"
          v-for="(p, index) in pages"
          :key="pag + index"
        >
          <span v-if="Math.abs(p - current) < 4 || p == pages || p == 1">
            <a
              :class="{
                current: current === p,
                last: p == pages && Math.abs(p - current) > 4,
                first: p == 1 && Math.abs(p - current) > 4,
              }"
              >{{ p }}</a
            >
          </span>
        </li>
        <li
          v-if="pages > 0"
          @click="getCategories(pages)"
          class="right-arrow disabled"
        >
          <a>»</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../services/adminService";
import axios from "axios";

export default {
  name: "CategoriesPage",
  data() {
    return {
      paginate: ["orderedUsers"],
      lim: 10,
      categories: [],
      current: 1,
      pages: 0,
    };
  },
  created() {},
  computed: {
    orderedUsers: function () {
      return this._.orderBy(this.categories, "id");
    },
  },
  mounted() {
    this.getCategories(this.current);
  },
  components: {},
  methods: {
    async getCategories(page) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.getCategories(page);
        if (response.data.success == true) {
          this.categories = response.data.data;
          this.pages = response.data.pagination.total_pages;
          this.current = response.data.pagination.page;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    remItem(litem) {
      if (confirm("Do you really want to delete?")) {
        this.remove(litem);
      }
    },
    async remove(it) {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.deleteCategory(it);
        if (response.data.success == true) {
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          this.getCategories();
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
