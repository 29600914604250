<template>
  <div>
    <div class="log-form sig-form">
      <form @submit.prevent="createUser">
        <div class="form-det">
          <h2 class="mb-0">Sign Up</h2>
          <p
            class="pt-3"
            id="accountNotification"
            v-bind:style="{ color: textColor }"
          ></p>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 uname">
              <input
                type="text"
                class="input-field form-control right"
                v-model="auth.signup.firstname"
                placeholder="First Name"
                required
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0 uname">
              <input
                type="text"
                class="input-field form-control left"
                v-model="auth.signup.lastname"
                placeholder="Last Name"
                required
              />
            </div>
          </div>

          <input
            type="email"
            v-model="auth.signup.email"
            class="input-field mt-1 form-control"
            placeholder="Email"
            required
          />
          <p class="mt-2 passp">
            Your password must contain at least 8 characters, including a
            minimum of one lowercase letter, an uppercase letter, a unique
            character and a number.
          </p>
          <input
            :type="passwordFieldType"
            v-on:keyup="validate"
            v-on:keydown="validate"
            v-model="auth.signup.password"
            class="input-field form-control"
            placeholder="Password"
            required
            minlength="8"
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&()*+,-./:;<=>@[\]^_`{|}~\x22\x27])[A-Za-z\d!#$%&()*+,-./:;<=>@[\]^_`{|}~\x22\x27]{8,}$"
            title="Your password must contain at least 8 characters, including a minimum of one lowercase letter, an uppercase letter, a unique character and a number."
          />
          <span type="password" @click="switchVisibility"
            ><i class="far fa-eye"></i
          ></span>
          <input
            :type="confirmpasswordFieldType"
            v-on:keyup="validate"
            v-on:keydown="validate"
            v-model="auth.signup.password_confirmation"
            class="input-field form-control"
            placeholder="Confirm Password"
            required
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&()*+,-./:;<=>@[\]^_`{|}~\x22\x27])[A-Za-z\d!#$%&()*+,-./:;<=>@[\]^_`{|}~\x22\x27]{8,}$"
            title="Your password must contain at least 8 characters, including a minimum of one lowercase letter, an uppercase letter, a unique character and a number."
            minlength="8"
          />
          <span type="password" @click="switchVisibilityconfirm"
            ><i class="far fa-eye"></i
          ></span>
          <span class="form-helper nomatch">
            Password must match the one typed before.
          </span>
          <button id="signBtn" type="submit" class="form-btn">Register</button>
          <p class="text-center mb-0">
            Already have an account?
            <router-link to="/" class="link"
              ><u><strong>Log In</strong></u></router-link
            >
          </p>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
    import custom from "../../javascript/custom";
    import AuthService from "../../services/authService";
    //import router from "@/router";
    import $ from "jquery";

    export default {
        name: "SignupPage",
        data() {
            return {
                auth: {
                    signup: {
                        firstname: "",
                        lastname: "",
                        email: "",
                        password: "",
                        password_confirmation: "",
                        active: false,
                        country: null,
                        auto_plan_id: 48,
                        // auto_plan_id: 9,
                    },
                },
                passwordFieldType: "password",
                confirmpasswordFieldType: "password",
                textColor: "",
            };
        },
        created() {},
        computed: {},
        mounted() {
            $.get(
                "https://www.cloudflare.com/cdn-cgi/trace",
                function(data) {
                    // Convert key-value pairs to JSON
                    // https://stackoverflow.com/a/39284735/452587
                    data = data
                        .trim()
                        .split("\n")
                        .reduce(function(obj, pair) {
                            pair = pair.split("=");
                            return (obj[pair[0]] = pair[1]), obj;
                        }, {});
                    this.auth.signup.country = data.loc;
                }.bind(this)
            );

            var urlParams = new URLSearchParams(window.location.search);
            if (
                window.location.href.indexOf("email") > -1 &&
                urlParams.get("email") != ""
            ) {
                this.auth.signup.active = true;
                this.auth.signup.firstname = urlParams.get("firstname");
                this.auth.signup.lastname = urlParams.get("lastname");
                this.auth.signup.email = urlParams.get("email");
                this.auth.signup.auto_plan_id = urlParams.get("Plan");
            }
        },
        components: {},
        methods: {
            switchVisibility() {
                this.passwordFieldType =
                    this.passwordFieldType === "password" ? "text" : "password";
                if (this.passwordFieldType == "text") {
                    custom.showPassword(0);
                } else {
                    custom.hidePassword(0);
                }
            },
            switchVisibilityconfirm() {
                this.confirmpasswordFieldType =
                    this.confirmpasswordFieldType === "password" ? "text" : "password";
                if (this.confirmpasswordFieldType == "text") {
                    custom.showPassword(1);
                } else {
                    custom.hidePassword(1);
                }
            },
            validate() {
                if (this.auth.signup.password != "") {
                    if (
                        this.auth.signup.password != this.auth.signup.password_confirmation
                    ) {
                        custom.validatePassword(false);
                    } else {
                        custom.validatePassword(true);
                    }
                }
            },
            async createUser() {
                var alert = {};

                try {
                    const response = await AuthService.signupUser(this.auth);
                    // var urlParams = new URLSearchParams(window.location.search);
                    var bt = document.getElementById("signBtn");
                    var res = document.getElementById("accountNotification");
                    if (response.data.success == true) {
                        this.$bus.$emit("acuser", this.auth.signup.email);

                        //bt.classList.add("success");
                        alert = {
                            text: "",
                            head: response.data.flash.notice,
                            type: "alert-success d-block",
                        };
                        this.$bus.$emit("flash", alert);
                        res.innerHTML = response.data.flash.notice;
                        this.textColor = "#17a355";
                        //bt.innerText = response.data.flash.notice
                        setTimeout(
                            function() {
                                //       this.$session.start()
                                // this.$session.set("token",(response.data.data.record.authToken))
                                // sessionStorage.setItem('token',response.data.data.record.authToken)
                                // this.$session.set("loggedIn",true)
                                // this.$cookies.set("user",response.data.data.record)
                                // sessionStorage.setItem('user',JSON.stringify([]))
                                //router.push({ path: "/" });
                                // if(window.location.href.indexOf("plan_id")>-1 && urlParams.get('plan_id')!=""){
                                //   this.$session.start()
                                //   this.$session.set("token",(response.data.data.record.authToken))
                                //   sessionStorage.setItem('token',response.data.data.record.authToken)
                                //   this.$session.set("loggedIn",true)
                                //   this.$cookies.set("user",response.data.data.record)
                                //   sessionStorage.setItem('user',JSON.stringify([]))
                                //   router.push({ path: '/member/dashboard' });
                                // }
                                // else{
                                //   router.push({ path: '/activate' });
                                // }
                            }.bind(this),
                            5000
                        );
                        this.auth.signup.password = "";

                        this.auth.signup.email = "";
                        this.auth.signup.lastname = "";
                        this.auth.signup.firstname = "";
                        this.auth.signup.password_confirmation = "";
                    } else if (response.data.success == false) {
                        bt.classList.add("fail");
                        bt.innerText = response.data.flash.error;
                        res.innerHTML = response.data.flash.error;
                        this.textColor = "##c40000";
                        setTimeout(
                            function() {
                                bt.classList.remove("fail");
                                bt.innerText = "Register";
                            }.bind(this),
                            5000
                        );
                        alert = {
                            text: response.data.errors,
                            head: response.data.flash.error,
                            type: "alert-danger d-block",
                        };
                        this.$bus.$emit("flash", alert);
                    }
                } catch (err) {
                    if (err) {
                        //console.log(err)
                        localStorage.clear();
                        sessionStorage.clear();
                        this.$session.destroy();
                    } else {
                        //console.log(err)
                    }
                }
            },
        },
    };
</script>