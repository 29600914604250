<template>
  <div class="main-det col-lg-10 offset-lg-1" :class="activeBackground">
    <div class="coupon-form mt-5">
      <h3 class="text-center mb-3">Enter coupon code</h3>
      <input
        type="text"
        v-model="code"
        class="input-field form-control col-lg-4 col-md-5 col-sm-12"
        placeholder=""
        required
      />
    </div>
    <div v-if="showplan == true" class="plans mt-5">
      <h3 class="text-center">Available plans</h3>
      <div class="all-plans row mt-5 justify-content-center">
        <router-link
          class="rlink pl"
          :to="{ name: 'PlanShow', params: { id: pl.id } }"
          @click.native="selectPlan(pl, code)"
          v-for="(pl, index) in plans"
          :key="pl + index"
        >
          <div class="text-center">
            <h4>{{ pl.name }}</h4>
            <p class="mt-4 pldes">{{ pl.description }}</p>
            <br />
            <button class="ml-2" v-if="pl.trial_period_amount > 0">
              Get Free Trial
            </button>
            <button class="ml-2" v-else-if="pl.price != 'free'">
              ${{ pl.price }} {{ pl.currency
              }}<span
                >/
                <span v-if="pl.subscription_period_amount > 1">{{
                  pl.subscription_period_amount
                }}</span>
                <span>{{ pl.subscription_period_unit }}</span></span
              >
            </button>
            <button class="ml-2" v-else>{{ pl.price }}</button>

            <p class="aftrpr" v-if="pl.trial_period_amount > 0">
              Then ${{ pl.price }} {{ pl.currency
              }}<span
                >/
                <span v-if="pl.subscription_period_amount > 1">{{
                  pl.subscription_period_amount
                }}</span
                ><span> {{ pl.subscription_period_unit }}</span></span
              >
            </p>
          </div>
        </router-link>
      </div>
    </div>
    <div class="mt-5 pt-5" v-if="npl == true">
      <h3 class="text-center">No available plans</h3>
    </div>
  </div>
</template>

<script>
// import custom from '../../javascript/custom'
import UserService from "../../../services/userService";
import axios from "axios";
import router from "@/router";

export default {
  name: "RedeemPage",
  data() {
    return {
      activeBackground: "",
      plans: [],
      code: "",
      showplan: false,
      coupon: {},
      npl: "",
      details: {
        subscription: {
          coupon_code: "",
          auto_renew: true,
        },
      },
    };
  },
  created() {
    // this.getPlans()
  },
  computed: {},
  beforeMount() {
    //  if (sessionStorage.getItem("backgroundChange") == "yes") {
    //         sessionStorage.removeItem("backgroundChange");
    //router.go(0);
    //    }
  },
  mounted() {
    this.checkBackground();
  },
  components: {},
  watch: {
    code: "getPlans",
  },
  methods: {
    //  hasHistory () { return window.history.length > 2 },
    async getPlans() {
      if (this.code != "" && this.code != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + this.$session.get("token");
        try {
          const response = await UserService.getCouponPlans(this.code);
          if (response.data.success == true) {
            if (response.data.data.records.length == 0) {
              this.npl = true;
            } else {
              this.npl = false;
            }
            this.plans = response.data.data.records[0].plans;
            // this.$session.set("ptok", response.data.data.token);
            this.coupon = response.data.data.records[0];
            if (this.plans.length > 0) {
              this.showplan = true;
            } else {
              this.showplan = false;
            }
            // for(var i=0;i<this.plans.length();i++){
            //   this.plans[i].discount = this.plans[i].price - this.coupon.discount_value
            // }
          } else {
            //console.log("error")
          }
        } catch (err) {
          // //console.log(err)
        }
      }
    },
    selectPlan(pl, cd) {
      this.$session.set("plan", pl);
      this.$bus.$emit("code", cd);
    },
    async selectDiscountedPlan(pl) {
      var alert = {};
      this.details.subscription.coupon_code = this.code;
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.newSubscription(pl.id, this.details);
        if (response.data.success == true) {
          alert = {
            text: [],
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          //bt.innerText = response.data.flash.notice
          setTimeout(function () {
            router.push({
              path: "/member/dashboard",
            });
          }, 500);
        } else {
          //console.log("error")
          alert = {
            text: [response.data.flash.error],
            head: "Error: ",
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        // //console.log(err)
      }
    },
    async checkBackground() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getCurrentBackground();

        if (response.data.success == true) {
          //this.background = response.data.data.thumbnail
          this.activeBackground = "noBackground";
          if (
            response.data.data.thumbnail != null &&
            response.data.data.thumbnail != "undefined"
          ) {
            this.activeBackground = "redeemBakground";
          } else {
            this.activeBackground = "noBackground";
          }
        } else {
          this.activeBackground = "noBackground";

          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
