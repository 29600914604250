<template>
  <div id="new_user" class="col-lg-12 mt-4 adacc">
    <h3><b>Edit Role</b></h3>
    <div class="">
      <form @submit.prevent="updateRole">
        <div class="mt-4">
          <div class="row mb-3">
            <div class="col-lg-8 col-md-8">
              <!-- <label>Role Name *</label> -->
              <input
                type="text"
                v-model="details.role.label"
                class="input-field form-control"
                placeholder="Role Name"
                required
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-12 mt-4 pri cap mb-3">Permissions</label>
          </div>
          <div v-if="details.role.label != 'Admin'">
            <div
              class="row mb-3 permis"
              v-for="(per, index) in details.role.permissions"
              :key="per.id"
            >
              <div class="col-sm-3">
                <v-select
                  v-model="per.permissable_model"
                  :reduce="(name) => name"
                  label="name"
                  required
                  :options="
                    options.filter((o) => per.permissable_model.indexOf(o) < 0)
                  "
                />
              </div>
              <div class="col-sm-2">
                <div class="chbx">
                  <input
                    type="checkbox"
                    v-model="per.can_read"
                    name="progtype"
                    class="check"
                    placeholder=""
                    value="read"
                  />
                  Can Read
                </div>
              </div>
              <div class="col-sm-2">
                <div class="chbx">
                  <input
                    type="checkbox"
                    v-model="per.can_create"
                    name="progtype"
                    class="check"
                    placeholder=""
                    value="create"
                  />
                  Can Create
                </div>
              </div>
              <div class="col-sm-2">
                <div class="chbx">
                  <input
                    type="checkbox"
                    v-model="per.can_edit"
                    name="progtype"
                    class="check"
                    placeholder=""
                    value="edit"
                  />
                  Can Edit
                </div>
              </div>
              <div class="col-sm-2">
                <div class="chbx">
                  <input
                    type="checkbox"
                    v-model="per.can_delete"
                    name="progtype"
                    class="check"
                    placeholder=""
                    value="delete"
                  />
                  Can Delete
                </div>
              </div>
              <div class="col-sm-1">
                <div
                  class="chbx"
                  @click="remPer(index, details.role.id, per.permissable_model)"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div v-if="details.role.label == 'Admin'">
            <div
              class="row mb-3 permis"
              v-for="per in details.role.permissions"
              :key="per.id"
            >
              <div class="col-sm-3">
                <v-select
                  v-model="per.permissable_model"
                  :reduce="(name) => name"
                  label="name"
                  disabled
                />
              </div>
              <div class="col-sm-2">
                <div class="chbx">
                  <input
                    type="checkbox"
                    v-model="per.can_read"
                    name="progtype"
                    class="check"
                    placeholder=""
                    value="read"
                    disabled
                  />
                  Can Read
                </div>
              </div>
              <div class="col-sm-2">
                <div class="chbx">
                  <input
                    type="checkbox"
                    v-model="per.can_create"
                    name="progtype"
                    class="check"
                    placeholder=""
                    value="create"
                    disabled
                  />
                  Can Create
                </div>
              </div>
              <div class="col-sm-2">
                <div class="chbx">
                  <input
                    type="checkbox"
                    v-model="per.can_edit"
                    name="progtype"
                    class="check"
                    placeholder=""
                    value="edit"
                    disabled
                  />
                  Can Edit
                </div>
              </div>
              <div class="col-sm-2">
                <div class="chbx">
                  <input
                    type="checkbox"
                    v-model="per.can_delete"
                    name="progtype"
                    class="check"
                    placeholder=""
                    value="delete"
                    disabled
                  />
                  Can Delete
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="mt-4" v-if="details.role.label != 'Admin'">
            <p class="cap cur" @click="addInput">
              <strong><i class="fas fa-plus"></i> Add a permission</strong>
            </p>
          </div>
          <router-link to="/admin/roles"
            ><button class="form-btn mr-4 cancel">Cancel</button></router-link
          >

          <button type="submit" id="saveRole" class="form-btn">
            Update Role
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AdminService from "../../../../services/adminService";
import axios from "axios";
import router from "@/router";

export default {
  name: "EditRolePage",
  data() {
    return {
      details: {
        role: {
          label: "",
          permissions: [
            {
              permissable_model: "",
              can_read: false,
              can_edit: false,
              can_create: false,
              can_delete: false,
            },
          ],
        },
      },
      options: [
        "Background",
        "Category",
        "Coupon",
        "Episode",
        "Payment",
        "Plan",
        "Programme",
        "User",
        "AD",
        "Role",
        "Schedule",
        "Splash",
        "Host",
      ],
      counter: 0,
      id: "",
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  computed: {},
  mounted() {
    this.getRole();
  },
  components: {},
  methods: {
    addInput() {
      this.details.role.permissions.push({
        permissable_model: "",
        can_read: false,
        can_edit: false,
        can_create: false,
        can_delete: false,
      });
    },
    remPer(p, r, per) {
      if (confirm("Do you really want to delete?")) {
        this.details.role.permissions.splice(p, p);
        this.remove(r, per);
      }
    },

    async updateRole() {
      var alert = {};
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      this.details.role.permissions_attributes = this.details.role.permissions; // on object create new key name. Assign old value to this
      try {
        delete this.details.role.permissions;
        var bt = document.getElementById("saveRole");
        const response = await AdminService.updateRole(this.id, this.details);
        if (response.data.success == true) {
          //bt.classList.add("success");
          alert = {
            text: "",
            head: response.data.flash.notice,
            type: "alert-success d-block",
          };
          this.$bus.$emit("flash", alert);
          //bt.innerText = response.data.flash.notice
          setTimeout(function () {
            router.push({
              path: "/admin/roles",
            });
          }, 500);
        } else {
          //console.log("error")
          bt.classList.add("fail");
          bt.innerText = response.data.flash.error;
          setTimeout(function () {
            bt.classList.remove("fail");
            bt.innerText = "Update Role";
          }, 1500);
          alert = {
            text: response.data.errors[0],
            head: response.data.flash.error,
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async remove(rol, per) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.deleteRolePer(rol, per);
        if (response.data.success == true) {
          //console.log("error")
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
    async getRole() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await AdminService.editRole(this.id);
        if (response.data.success == true) {
          this.details.role = response.data.data.role;
        } else {
          //console.log("error")
        }
      } catch (err) {
        //console.log(err)
      }
    },
  },
};
</script>
