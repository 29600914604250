<template>
  <div class="main">
    <nav class="navbar navbar-expand-lg main-nav wh mb-0">
      <router-link class="navbar-brand ml-0" to="/"
        ><img class="dash-logo" src="../assets/irie-logo@2x.png"
      /></router-link>
    </nav>
    <center>
      <h1 class="error">404</h1>
      <h1 class="mb-4">This page does not exist</h1>
      <p class="mb-0">
        <a class="bk" @click="back()">Back</a>
      </p>
    </center>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    back: function () {
      window.history.back();
    },
  },
};
</script>

<style>
html,
.main {
  /* background:#101010; */
  /* color:white; */
  height: 100vh;
}
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}

.error {
  font-size: 64px;
}

a.bk {
  /* color:black !important; */
  cursor: pointer;

  background: #101010;
  width: max-content;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px 20px;
  height: 35px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}
</style>
